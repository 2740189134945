import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const isLatLng = (input: string): Array<number> => {
  if (input.match(/[^0-9\s\.,-]/)) return;
  const [ a, b ] = input.replaceAll(' ', '').split(',');
  const lat = Number(a);
  const lng = Number(b);
  if (isNaN(lat) || isNaN(lng)) return;
  if (lat > 90 || lat < -90) return;
  if (lng > 180 || lng < -180) return;
  return [ lat, lng ]
}

export const AddressAutoComplete = (props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);


  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPredictions(input, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    const latLng = isLatLng(inputValue)

    if (latLng) {

      setOptions([{
        description: inputValue,
        lat: latLng[0],
        lng: latLng[1],
      }]);

      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) setOptions(results || []);
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const { value, onChange, fullWidth, variant, label, ...autocompleteProps } = props;

  return (
    <Autocomplete
      {...autocompleteProps}
      getOptionLabel={(option: any) => option.description ?? ''}
      filterOptions={x => x}
      options={value ? [ ...options, { description: value?.LongName } ] : options}
      autoComplete
      includeInputInList
      freeSolo
      value={value && { description: value.LongName }}
      onChange={async (e, x: any) => {
        if (!x) return onChange(undefined);

        if (x.lat) {
          return onChange({
            LongName: x.description,
            Lat: x.lat,
            Lon: x.lng
          });
        }

        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({
          placeId: x.place_id,
          fields: [ 'geometry' ]
        }, (place) => {
          onChange({
            placeID: x.place_id,
            LongName: x.description,
            Lat: place.geometry.location.lat(),
            Lon: place.geometry.location.lng()
          });
        });
      }} //onChange({ Local: x.description, Lat: x.lat, Lon: x.long })}
      renderInput={params => {
        return (
          <TextField
          error= {autocompleteProps.error === true}
          helperText={autocompleteProps.error === true ? 'Empty field!' : ' '}
            {...params}
            color={props.color}
            label={label}
            variant={variant}
            fullWidth={fullWidth}
            onChange={handleChange}
            
          />
        )
      }}
      renderOption={(option: any) => {
        try {

          if (option.lat) {
            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  Coordinates
                  <Typography variant="body2" color="textSecondary">
                    Lat: {option.lat}, Lng: {option.lng}
                  </Typography>
                </Grid>
              </Grid>
            )
          }


          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map(match => [match.offset, match.offset + match.length]),
          );
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        } catch (e) {
          return (
            <div/>
          )
        }
      }}
    />
  );
};