import {Divider, IconButton, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {compose} from 'recompose';
import {connect, useStore} from "react-redux";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Tooltip from "@material-ui/core/Tooltip";
import Flag from "@material-ui/icons/Flag";
import Edit from "@material-ui/icons/Edit";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {formatAddress} from "./index";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { DatePicker } from '@material-ui/pickers';
import { TimePicker } from '@material-ui/pickers';

import moment from 'moment'
import InputAdornment from "@material-ui/core/InputAdornment";
import {useDateFilter} from "../../../providers/date_filter";


const AssignmentListTiles = compose(
  connect(
    (state) => ({
      jobs: state.jobs,
      mixtures: state.mixtures,
      customers: state.customers,
      trucks: state.trucks,
      firebase: state.firebase,
      company: state.company,
      focusTarget: state.mapFocus,
      range: state.dispatchDateRange,
    }),
    (dispatch) => ({
      customer: (callback) => dispatch({ type: "CUSTOMER_SELECTION_SET", payload: callback }),
      address: (callback) => dispatch({ type: "ADDRESS_SELECTION_SET", payload: callback }),
      truck: (callback) => dispatch({ type: "TRUCK_SELECTION_SET", payload: callback }),
      mixture: (callback) => dispatch({ type: "MIX_SELECTION_SET", payload: callback }),
      clearMixture: () => dispatch({ type: "MIX_SELECTION_CLEAR" }),
      clearCustomer: () => dispatch({ type: "CUSTOMER_SELECTION_CLEAR" }),
      clearAddress: () => dispatch({ type: "ADDRESS_SELECTION_CLEAR" }),
      clearTruck: () => dispatch({ type: "TRUCK_SELECTION_CLEAR" }),
      focus: (obj) => dispatch({ type: "MAP_FOCUS_SET", payload: obj }),
    })
  )
)((props) => {
  let [ { day, start, end } ] = useDateFilter();

  start = moment(start || moment(day)).startOf('day');
  end = moment(end  || moment(day)).endOf('day');

  return Object.entries(props.jobs).filter(
    ([ , value ]) => {
      const time = moment(value.Start);
      if (!time.isValid()) return true;
      return time.isSameOrAfter(start) && time.isSameOrBefore(end)
    }
  ).map(
    ([ key, value ], i) => {
      const [ open, setOpen ] = props.controller;
      const expanded = open === i;
      const customer = props.customers[value.Customer];
      const truck = props.trucks[value.Truck];

      const handleTime = async (e) => {
        await props.firebase
          .database()
          .ref(`${props.company.val().url}/Jobs/${key}/Start`)
          .set(e.toISOString());
      };

      const replaceNaNasNull = (x) => isNaN(x) ? null : x;

      const handleVolume = async (e) => {
        await props.firebase
          .database()
          .ref(`${props.company.val().url}/Jobs/${key}/Volume`)
          .set(replaceNaNasNull(Number(e.target.value)));
      };


      const handleDuration = async (e) => {
        await props.firebase
          .database()
          .ref(`${props.company.val().url}/Jobs/${key}/Duration`)
          .set(replaceNaNasNull(Number(e.target.value)));
      };

      const handleNotes = async (e) => {
        await props.firebase
          .database()
          .ref(`${props.company.val().url}/Jobs/${key}/Notes`)
          .set(e.target.value);
      };

      return (
        <ExpansionPanel
          key={`order-expansion-${i}`}
          expanded={expanded}
        >
          <ExpansionPanelSummary href='' className='tool-window-assignment-assign'>
            <div className='flex-grow'>
              <Typography
                className='hover-icon'
                variant='h6'
                onClick={() => props.customer(async (customer) => {
                  props.clearCustomer();
                  await window.firebase.database().ref(`${props.company.val().url}/Jobs/${key}`).update({
                    Customer: customer[0]
                  })
                })}
                noWrap
                style={{width: 'min-content'}}
              >{
                value.Customer
                  ? `${customer.Name.First} ${customer.Name.Last}`
                  : "No Customer Provided"
              }<Edit style={{ paddingTop: '0.1em', paddingLeft: '1em', fontSize: '1rem' }}/></Typography>
              <Typography
                variant='subtitle2'
                className='hover-icon'
                onClick={() => props.address(async (address) => {
                  props.clearAddress();
                  await window.firebase.database().ref(`${props.company.val().url}/Jobs/${key}`).update({
                    Address: address
                  })
                })}
                noWrap
                style={{width: 'min-content'}}
              >
                <strong>Delivery location:</strong> { value.Address
                ? formatAddress(value.Address)
                : <span className='error'>No Address provided</span>
              }
                <Edit style={{ paddingTop: '0', paddingLeft: '1em', fontSize: '1rem' }}/>
              </Typography>
              <Typography
                variant='subtitle2'
                className='hover-icon'
                onClick={() => props.truck(async (truck) => {
                  props.clearTruck();
                  await window.firebase.database().ref(`${props.company.val().url}/Jobs/${key}`).update({
                    Truck: truck[0]
                  })
                })}
                noWrap
                style={{width: 'min-content'}}
              >
                <strong>Assigned to: </strong>
                { value.Truck
                  ? truck ? `${truck.Name}` : <span className='error'>Truck common name was never provided</span>
                  : <span className='error'>No truck assigned</span>}
                <Edit style={{ paddingTop: '0', paddingLeft: '1em', fontSize: '1rem' }}/>
              </Typography>
              { !expanded && <div>
                <Tooltip className='tool-window-assignment-open-button' title='Show all details'>
                  <IconButton onClick={() => {
                    setOpen(i);
                    if (value.Address && value.Address.Lat) {
                      props.focus([ key, {
                        Lat: value.Address.Lat, Lon: value.Address.Lon,
                      }]);
                    }
                  }} href=''>
                    <KeyboardArrowDown/>
                  </IconButton>
                </Tooltip>
              </div>}
            </div>
            <Typography>{
              value.Duration
                ? `${value.Duration} H`
                : <span className='error'>Time Not Allocated</span>
            }</Typography>
            { value.Stats < 0 && <Tooltip className='tool-window-assignment-flag' title='Assignment is not complete'>
              <IconButton href=''>
                <Flag/>
              </IconButton>
            </Tooltip>}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Typography
                      variant='overline'
                      align='center'
                    >Start</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant='overline'
                      align='center'
                    >Duration</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant='overline'
                      align='center'
                    >Mixture</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: '0.1em 0.5em', backgroundColor: 'lightgray'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <DatePicker
                        value={moment(value.Start).local()}
                        onChange={handleTime}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TimePicker
                        value={moment(value.Start).local()}
                        onChange={handleTime}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={value.Duration || ''}
                        onChange={handleDuration}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: <InputAdornment position='end'>H</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        className='hover-icon'
                        style={{ paddingTop: '0.25em'}}
                        onClick={() => props.mixture(async (mix) => {
                          props.clearMixture();
                          await props.firebase
                            .database()
                            .ref(`${props.company.val().url}/Jobs/${key}/Mixture`)
                            .set(mix[0])
                        })}
                      >
                        {(props.mixtures[value.Mixture || ''] || {})["Name"] ||
                        <span className='error'>No Mix Selected</span>}
                        <Tooltip title='Change Mixture'>
                          <Edit style={{ paddingTop: '0.1em', paddingLeft: '1em', fontSize: '1em', color: 'gray'}}/>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={value.Volume || ''}
                        onChange={handleVolume}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: <InputAdornment position='end'>M<sup>3</sup></InputAdornment>
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}><div style={{ height: '1em' }}/></Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>
                    Contact Information
                    <Divider component='hr'/>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body2'>
                    <strong>Email:</strong> { (props.customers[value.Customer] || {}).Email }
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body2'>
                    <strong>Phone:</strong> { (props.customers[value.Customer] || {}).Phone }
                  </Typography>
                </Grid>
                <Grid item xs={12}/>
                { value.Requested && <Grid item xs={12}>
                  <Typography variant='subtitle1'>
                    Requested Product
                    <Divider component='hr'/>
                  </Typography>
                </Grid>}
                { value.Requested && <Grid item xs={6}>
                  <Typography variant='body2'>
                    <strong>Name:</strong> { value.Requested }
                  </Typography>
                </Grid>}
                { value.Requested && <Grid item xs={6}>
                  <Typography variant='body2'>
                    <strong>Amount:</strong> { value.Amount || 0 } M<sup>3</sup>
                  </Typography>
                </Grid>}
                { value.Requested && <Grid item xs={12}>
                  <Typography variant='body2'>
                    <strong>Application:</strong> { value.Application || "No Application was provided." }
                  </Typography>
                </Grid>}
                <Grid item xs={12}/>
                <Grid item xs={12}/>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    multiline={true}
                    rows={4}
                    label="Additional Notes"
                    value={ value.Notes || "No additional delivery instructions were provided."}
                    onChange={handleNotes}
                  />
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Button
                  style={{
                    position: "relative",
                    top: '1em'
                  }}
                  onClick={async () => {
                    setOpen(null);
                    await props.firebase.database().ref(`${props.company.val().url}/Jobs/${key}`).remove();
                  }}
                ><span className='error'>Delete</span></Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{
                    position: "relative",
                    top: '1em'
                  }}
                  onClick={() => {
                    setOpen(null);
                  }}
                ><span style={{color: 'dodgerblue'}}>Submit</span></Button>
              </Grid>
            </Grid>

          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
  )
});


const Assignments = (props) => {
  const control = useState(null);
  const { company } = useStore().getState();
  const [ { day, start } ] = useDateFilter();
  return (
    <div>
      <ListItem className='tool-window-assignment-header' component='div'>
        <Typography style={{ display: 'flex '}} variant='h6'>
          <span style={{ flexBasis: 1 }}>Assignments</span>
          <Button
            href=''
            className='add-assignment-fab'
            onClick={() => {
              window.firebase.database().ref(`${company.val().url}/Jobs`).push().update({
                Status: 0,
                Start: moment(day || start).startOf('hour').utc().toISOString()
              }).finally();
            }}
          ><span style={{ color: 'dodgerblue'}}>+ Create</span></Button>
        </Typography>
      </ListItem>
      <List component='div' className='tool-window-assignment-list'>
        <AssignmentListTiles controller={control} {...props}/>
      </List>
    </div>
  )
};

export default Assignments;
