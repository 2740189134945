import React, {useState} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import '../styles/dispatch.css';
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";


const renderSuggestions = (getSuggestionItemProps, suggestions) => suggestions.map(
  (suggestion, i) => (
    <ListItem
      key={`maps-autofill-${i}`}
      {...getSuggestionItemProps(suggestion)}
    >
      <ListItemText
        primary={suggestion.description}
      />
    </ListItem>
  )
);


const handleRender = (props) => ({ getInputProps, getSuggestionItemProps, suggestions }) => (
  <div className="autocomplete-root">
    <TextField
      {...getInputProps()}
      {...props}
    />
    <Paper className='address-suggestions-paper'>
      <List component='div'>{renderSuggestions(getSuggestionItemProps, suggestions)}</List>
    </Paper>
  </div>
);

const AddressAutoComplete = (props) => {
  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.onChange}
    >{handleRender(props["TextFieldProps"])}</PlacesAutocomplete>
  )
};



export default AddressAutoComplete
