import makeStyles from "@material-ui/core/styles/makeStyles";

const drawerWidth = 350;

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  m1: {
    paddingRight: 10,
  },
  e500: {
    elevation: 500,
  },
  body: {
    position: 'fixed',
    top: 64,
    left: 74,
    bottom: 16,
    right: 16
  },
  x100: {
    width: "100%"
  },
  y100: {
    height: "100%"
  },
  content: {
    position: 'fixed',
    top: 16 + 64,
    left: 16 + 74,
    bottom: 16,
    right: 16,
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
  addOrder: {
    position: 'absolute',
    bottom: 0,
    margin: 20,
    width: drawerWidth - 40,
  },
  grid: {
    paddingLeft: 16,
    paddingTop: 80,
    width: '100%',
  },
  prompt: {
    position: 'fixed',
    opacity: 0.5,
    backgroundColor: 'black',
    width: "100%",
    height: "100%",
    alignItems: 'center',
    justifyItems: 'center',
  },
  promptPaper: {
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    margin: 'auto',
    position: 'fixed',
    width: 800,
    height: 400,
  },
  center: {
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center",
    width: '100%',
    height: '100%',
  },
  drawer: {
    position: 'fixed',
    width: drawerWidth,
    marginLeft: drawerWidth,
    flexShrink: 0,

  },
  paper: {
    top: 0,
    position: 'fixed',
    width: drawerWidth + 73,
    height: "100%",
    borderRadius: "0 25px 25px 0 !important"
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#EEEEEE',
    margin: 10,
    padding: 6
  },
  inputRoot: {
    marginLeft: 10,
    overflow: 'hidden'

  },
  root: {
    display: 'flex',
    width: '100%',
  },
  card: {
    borderRadius: "15px 15px 15px 15px",
  },
  left: {
    paddingLeft: 15
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    position: 'fixed',
    left: 0,
    width: `100%`,
  },
  toolBar: {
    paddingLeft: 90,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  lines: {
    backgroundColor: "#EEE"
  },
  b0: {
    bottom: 0,
  },
  w: {
    backgroundColor: "#fff"
  },
  rounded: {
    borderRadius: "16pt 16pt 16pt 16pt"
  },
  rbr: {
    borderRadius: "16pt 16pt 16pt 16pt"
  },
  rbl: {
    borderRadius: "16pt 16pt 16pt 16pt"
  },
  header: {
    borderRadius: "16pt 16pt 0 0",
    position: "absolute",
    left: 0,
    right: 0
  },
  headline: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  columnPaper: {
    overflow: 'hidden',
    display: 'flex',
    position: 'absolute',
    top: '5em',
    left: '5.5em',
    right: '1em',
    bottom: '1em',
    borderRadius: '1em'
  },
  columnHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    maxHeight: 'min-content',
    borderRadius: '1em 1em 0 0'
  },
  columnBody: {
    display: 'flex',
    position: 'absolute',
    top: '3em',
    bottom: '0',
    right: 0,
    left: 0,
    overflow: 'hidden',
  },
  columnTitle: {
    padding: '0.5em',
    flexBasis: '20%',
  },
  column: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexBasis: '20%',
    flexGrow: 1,
  },
  columnLine: {
    position: 'relative',
    left: 1,
    backgroundColor: 'lightgrey',
    marginLeft: "calc(20% - 1px)",
    height: "100%",
    width: 1,
  }
}));

export default useStyles;
