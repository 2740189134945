import React, {useState} from 'react';
import {compose} from "recompose";
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useDispatch, useStore} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { colors } from '@material-ui/core';

const getStateFromMaterial = (material, type) => {
  if (!material || material.length < 2) return { Type: type };
  if (type) return { ...material[1], Type: type };
  return material[1];
};

const MaterialDesignerView = (props) => {
  const [ state, setState ] = useState(getStateFromMaterial(props.material, props.type));
  const [ showError, setShowError ] = useState();
  const { company } = useStore().getState();
  const dispatch = useDispatch();
  const handle = (e) => setState({ ...state, [e.target.name]: e.target.value });
  const close = () => dispatch({ type: "MATERIAL_POPUP_CLOSE" });

  const  getMaterialCalibrationData = async () =>{
  
    var calibrationsDataMap = new Map();
    var alreadyAdded = []
    var CSVLine = "";

    for( const truckID of Object.keys(props.material[1]["Calibrations"])){
      var calibrationsID = props.material[1]["Calibrations"][truckID][0];

      if(!alreadyAdded.includes(calibrationsID)){

        var truckData = await window.firebase.database().ref(`${company.val().url}/Trucks/${truckID}`).get();
        var database_calibrationsData = await  window.firebase.database().ref(`${company.val().url}/Calibrations/${calibrationsID}`).get();
        if(database_calibrationsData.val() != null){
          calibrationsDataMap.set(calibrationsID, new Map());
          calibrationsDataMap.get(calibrationsID).set(truckID, new Map());
          calibrationsDataMap.get(calibrationsID).get(truckID).set("TruckName",truckData.child("Name").val());
          calibrationsDataMap.get(calibrationsID).get(truckID).set("Steps",new Map());

          var CSVSteps ="Steps,";
          var CSVCounts ="Counts,";
          var CSVGate ="Gate,";
          var CSVWeight ="Weight,";
          var i=1;
          for( const index of database_calibrationsData.val()["Steps"]){
            calibrationsDataMap.get(calibrationsID).get(truckID).get("Steps").set(index);
            CSVSteps += i+",";
            CSVCounts += (index["Counts"] != undefined)? index["Counts"]+",":",";
            CSVGate += (index["Gate"] != undefined)? index["Gate"]+",":",";
            CSVWeight += (index["Weight"] != undefined)? index["Weight"]+",":",";
            i++;
          }
          
          alreadyAdded.push(calibrationsID);

          var truckName = (calibrationsDataMap.get(calibrationsID).get(truckID).get("TruckName") == null)?"N//A": calibrationsDataMap.get(calibrationsID).get(truckID).get("TruckName");

          CSVLine+=`Calibration ID: ${calibrationsID}, Truck Name: ${truckName}\n`
          CSVLine+= CSVSteps +"\n";
          CSVLine+= CSVCounts +"\n";
          CSVLine+= CSVGate +"\n";
          CSVLine+= CSVWeight +"\n\n";

        }
      }
    }
    if(CSVLine != "" && database_calibrationsData.val() != null){
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(CSVLine));
      element.setAttribute('download',   database_calibrationsData.val()["material_name"]+"_CalibrationData.csv");
      element.style.display = 'none';

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

  }

  const exists = !!(props.material||[])[0];
  return (
    <Paper style={{ minWidth: '30em', padding: '1em', paddingBottom: '0.5em' }}>
      <Grid container spacing={2} justify='flex-start' alignContent='flex-start'>
        <Grid item xs={12}>
          <TextField
            name="Name"
            label="Name"
            value={state["Name"] || ''}
            error={showError && `${state.Name || ''}`.length < 1}
            onChange={(e) => e.target.value.length <= 20 ? handle(e) : null}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{(state["Name"] || '').length}/20</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Type"
            label="Type"
            select
            value={state["Type"] || ''}
            onChange={handle}
            fullWidth
          >
            <MenuItem value='Cement'>Cement</MenuItem>
            <MenuItem value='Stone'>Stone</MenuItem>
            <MenuItem value='Sand'>Sand</MenuItem>
            <MenuItem value='Admix'>Admixture</MenuItem>
          </TextField>
        </Grid>
        { state.Type === 'Admix' && <Grid item xs={12}>
          <TextField
            name="Variant"
            label="Variant"
            select
            value={state["Variant"] || 1}
            onChange={handle}
            fullWidth
          >
            <MenuItem value={1}>Water Reducer</MenuItem>
            <MenuItem value={2}>Air Entrainment</MenuItem>
            <MenuItem value={3}>Accelerator</MenuItem>
            <MenuItem value={4}>Plasticiser</MenuItem>
            <MenuItem value={5}>Shrink Reducer</MenuItem>
            <MenuItem value={6}>Corrosion Inhibitor</MenuItem>
            <MenuItem value={7}>Retarder</MenuItem>
          </TextField>
        </Grid>}


        <Grid item xs={(state.Type !== 'Admix')?6:10} style={{ display: 'flex' }}>
          <Button href='' onClick={close}>
            Cancel
          </Button>
          <Button href='' onClick={() => {
            if (!state.Name) return setShowError(true);
            let ref = window.firebase.database().ref(`${company.val().url}/Materials/`);
            if (props.material.length < 2) ref = ref.push();
            else if (!props.material[0] || props.material[0] === '') ref = ref.push();
            else ref = ref.child(props.material[0]);
            ref.update({
              ...state,
              Variant: state.Type === 'Admix' ? (state.Variant ?? 1) : null

            }).finally(close);
          }}><span style={{ color: 'dodgerblue'}}>Save</span></Button>

        </Grid>

        { exists && <Grid item xs={(state.Type !== 'Admix')?6:2} style={{display: 'flex', alignItems:'end'}}>
        {state.Type !== 'Admix' && <Button  href='' onClick={getMaterialCalibrationData}>
            <span style={{fontSize: 12, color: "black"}}>Calibration Data </span>
          </Button>}

          <Button href='' onClick={() => {
            window.firebase.database()
            .ref(`${company.val().url}/Materials/${props.material[0]}/Deprecated`)
            .set(!state.Deprecated)
            .finally(close);
          }}>
            <span className='error'>
              { state.Deprecated || "Archive" }
              { state.Deprecated && "Unarchive" }
            </span>
          </Button>
        </Grid>}
      </Grid>
    </Paper>
  )
};

const MaterialDesigner = (props) => {
  const visible = props.material !== null;
  if (!visible) return <div/>;
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow in={visible} mountOnEnter unmountOnExit>
        <div className='dialog center'>
          <MaterialDesignerView material={props.material}/>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect((state) => ({ material: state.material }))
)(MaterialDesigner);

