import React, {useEffect, useState} from "react";
import {useStore} from "react-redux";
import {compose} from 'recompose';
import watermark from "../../images/watermark.jpeg"
import useStyles from './styles';
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import SwipeableViews from "react-swipeable-views";
import Delivery from "./delivery";
import Customer from "./customer";
import CheckCircle from "@material-ui/icons/CheckCircle";
import {withSnapshotList} from "../../database/firebase_hoc";
import moment from "moment";

const OrderView = (props) => {
  const [ options, setOptions ] = useState(null);
  const [ tab, setTab ] = useState(0);
  const forward = () => setTab(tab + 1);
  const backwards = () => setTab(tab - 1);
  const store = useStore();
  const submit = async () => {
    let { order } = store.getState();

    order = Object.fromEntries(
      Object.entries(order).map(
        ([ key, value ]) => [ key, isNaN(value) ? value : Number(value) ]
      )
    );

    const coder = new window.google.maps.Geocoder();
    coder.geocode({ 'address': order.Customer.Address }, (results) => {
      const result = results[0];
      if (result === null) return;


      const billing = {
        LongName: order.Customer.Address,
        Lat: result.geometry.location.lat(),
        Lon: result.geometry.location.lng(),
        Street: order.Customer.Address,
        Region: "   ",
        City: "  ",
        Country: " ",
        PostCode: "  ",
      };

      let delivery = order.Address || order.Customer.Address;
      if (delivery === '') delivery = order.Customer.Address;

      coder.geocode({ 'address': delivery }, (results) => {
        const result = results[0];
        if (result === null) return;
        delivery = {
          LongName: delivery,
          Lat: result.geometry.location.lat(),
          Lon: result.geometry.location.lng(),
          Street: delivery,
          Region: "   ",
          City: "  ",
          Country: " ",
          PostCode: "  ",
        };

        const email = order.Customer.Email.toLowerCase();

        window.firebase.database()
          .ref(`${props.url}/Customers`)
          .orderByChild("Email")
          .equalTo(email)
          .once('value').then((snap) => {
            if (!snap.val()) {
              window.firebase.database().ref(`${props.url}/Customers`).push().update({
                ...order.Customer,
                Email: email
              })
            }
          });

        window.firebase.database().ref(`${props.url}/Orders`).push().update({
          ...order,
          Start: moment(order.Start).toISOString(),
          Product: {
            ...order.Product,
            Amount: order.Product.Amount * 0.764555
          },
          Customer: {
            ...order.Customer,
            Email: order.Customer.Email.toLowerCase(),
            Name: `${order.Customer.Name.First} ${order.Customer.Name.Last}`,
            Address: billing
          },
          Address: delivery,
        });

        forward();
      });

    });
  };

  const [ ordering, setOrdering ] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    window.firebase
      .database()
      .ref(`${props.url}/Page`)
      .once('value')
      .then((snap) => setOptions(snap.val() || {}));
  }, [ props.url ]);

  return (
    <div>
      <div
        style={{
          color: "#ffffff",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          position: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: options && `url(${options.BackgroundURL || watermark})`
        }}
      />
      { options && options["LogoURL"]
        ? <img style={{padding: 16}} src={options["LogoURL"]} alt=''/>
        : <p className={classes.logo}>Your Company Logo</p> }
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3' align='center'>
            <span className={classes.title} style={{ color: (options || {})["TitleColor"] }}>
              Order Your Concrete Now!
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Fade in={!ordering}>
        <Button
          variant={"contained"}
          className={classes.order}
          onClick={() => setOrdering(true)}
          href=''
        >Create Your order</Button>
      </Fade>
      <Grow
        in={ordering}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.content}>
          <SwipeableViews index={tab} containerStyle={{ height: '600px',}}>
            <Customer onSubmit={forward} />
            <Delivery company={props.url} onSubmit={submit} onBack={backwards} />
            <div className={classes.padding}>
              <Grid container alignItems="center" style={{width: 400, margin: 'auto'}}>
                <Grid item xs={2}>
                  <CheckCircle fontSize={"large"} style={{paddingTop: 6, color: "#43a047"}}/>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" style={{color: "#fff"}}>
                    Your order has been requested
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </SwipeableViews>
        </div>
      </Grow>
    </div>
  );
};



export const OrderPageRouting = (props) => {
  const url = window.location.href
    .replace("http://", "")
    .replace("https://", "")
    .split('/')[0].split('.');

  if (url.length < 3) return props.children

  const company = url[0].toLowerCase()

  const Order = compose(
    withSnapshotList(`/${company}Mixtures`),
  )(OrderView);
  return <Order url={company}/>
};