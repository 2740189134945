import TextField from "@material-ui/core/TextField";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


export const CheckFormField = (props) => {
  const checked = props.controller.value(props.name);
  return (
    <FormControlLabel
      style={{ margin: 0 }}
      control={
        <Checkbox
          checked={!!checked}
          onChange={() => props.controller.updateState(props.name, !checked)}
        />
      }
      label={props.label}
    />
  )
};

const FormField = (props) => {
  let error = props.controller.id > props.id && !props.controller.valid(props.name, props.max);
  const value = () => {
    if (props.select) return props.controller.value(props.name) || '';
    if (props.max || props.regex) {
      let value = props.controller.value(props.name) || '';
      if (props.max) value = value.substring(0, props.max);
      if (props.regex) value = value.replace(props.regex, '');
      return value;
    }
  };
  const _default = () => {
    const val = value() || props.controller.value(props.name);
    if (val === null || val === undefined) return undefined;
    if (props.select) return undefined;
    let out = typeof(props.conversion) === 'number' ? val / props.conversion : val;
    if (typeof(out) === 'number') out = Number(out.toFixed(2));
    if (`${out}` === "NaN") return undefined;
    return `${out}`;
  };
  const change = (e) => {
    if (props.max) e.target.value = e.target.value.substring(0, props.max);
    props.controller.change(props.handle || ((x) => x), props.conversion, props.controlled)(e);
  };
  return (
    <TextField
      name={props.name}
      select={props.select}
      label={props.label}
      type={props.type ?? 'any'}
      step='any'
      fullWidth={props.fullWidth}
      value={value()}
      onChange={change}
      error={error}
      inputProps={{
        step: 'any'
      }}
      InputProps={{
        step: 'any',
        defaultValue: value() ? undefined : _default(),
        endAdornment: props.end,
        startAdornment: props.start,
        ...props.InputProps
      }}
      onFocus={() => props.controller.select(props.id)}
    >{props.children}</TextField>
  )
};

export default FormField;
