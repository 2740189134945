import React, {useState} from "react";
import {MenuItem} from "@material-ui/core";
import {useCardView} from "./EditableCardView";
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import Paper from "@material-ui/core/Paper";

const Menu = (props) => {
  return (
    <Paper>
      <MenuItem onClick={props.onCreate}>Create</MenuItem>
    </Paper>
  )
};

const Selection = (props) => {
  const [ start, setStart ] = useState();
  const [ selection, setSelection ] = useState();

  const options = useCardView();

  let width = options.width + options.spacing * 2;

  const onStart = (e) => {
    if (e.button === 2) return;
    setSelection(undefined);
    setStart([
      Math.floor((e.clientX + (window.scrollX - options.left)) / width) * width,
      e.clientY + (window.scrollY - options.top),
    ]);
  };

  const create = () => {
    setStart(null);
    if (selection) props.onCreate({
      ...selection,
      x: selection.x + options.spacing,
    });
    setSelection(null);
  };

  const onRelease = () => start ? create() : setSelection(null);

  const onDrag = (e) => {
    e.preventDefault();
    const [ x, y ] = [ e.clientX, e.clientY ];
    const height = y - start[1] + (window.scrollY - options.top);
    if (height > 0) setSelection({
      x: start[0],
      y: start[1],
      width: width || x - start[0],
      height: height
    });
  };

  document.onmousemove = start && onDrag;
  document.onmouseup = start && onRelease;

  return (
    <>
      <div
        id="area"
        onMouseDown={onStart}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      {selection && <>
        <ContextMenuTrigger id='selection'>
          <div
            style={{
              background: 'black',
              opacity: 0.1,
              position: 'absolute',
              left: selection.x,
              top: selection.y,
              height: selection.height,
              width: selection.width
            }}
          />
        </ContextMenuTrigger>
        <ContextMenu id='selection'>
          <Menu selection={selection} onCreate={() => {
            if (selection) props.onCreate({
              ...selection,
              x: selection.x + options.spacing,
            });
            setSelection(null);
          }}/>
        </ContextMenu>
      </>}
    </>
  );
};

export const SelectableArea = (props) => {

  return (
    <div
      style={{
        position: 'relative',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <Selection onCreate={props.onCreate}/>
      {props.children}
    </div>
  )
};