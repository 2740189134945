const mapFocusReducer = (state = 'ALL', action) => {
  switch (action.type) {
    case 'MAP_FOCUS_SET': return action.payload;
    case 'MAP_FOCUS_ALL': return 'ALL';
    case 'MAP_FOCUS_RESET': return null;
    default: return state;
  }
};

export default mapFocusReducer;
