import React from 'react';
import { useSchedule } from "./index";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import {useTruckInfoPopUp} from "../../popups/TruckInfoPopUp";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: 'black',
      textDecoration: "none",
      width: 250,
    },
    root: {
      top: 0,
      zIndex: 1100,
      position: 'sticky',
      height: 0,
    },
    content: {
      display: 'flex',
      paddingLeft: 185,
      paddingRight: 10,
      top: 0,
      bottom: 0,
      width: 'fit-content',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    header: {
      borderRadius: 0,
      left: 0,
      top: 0,
      display: 'flex',
      background: theme.palette.primary.main,
      height: theme.spacing(8),
      minWidth: "100%",
      width: 'fit-content',
      position: 'relative',
    }
  }
});


const Title = (props) => {
  const [ key, value ] = props.truck;
  const classes = useStyles();
  const dialog = useTruckInfoPopUp();
  const url = 'https://us-central1-fd-remote.cloudfunctions.net/generateBatchSheet';
  //console.log( key ? url + '?' + ['truck=' + key,'company=' + window.application.company_key,].join('&'): undefined);

  return (
    <a 
      download= {[value?.Name]+"_BatchSheet.xlsx"}
      onClick={()=>{
        dialog.show([key,value,window.application.company_key]);
      }}
      
      className={classes.title}
    >
      <Typography noWrap variant="h6" align="center">
        { value?.Name ?? "No Name Provided" }
      </Typography>
    </a>
  )
};


export const TruckLabelBar = () => {
  const classes = useStyles();
  const { trucks } = useSchedule();

  const labels = [
    [ undefined, { Name: "UNASSIGNED" } ],
    ...Object.entries(trucks),
  ];


  return (
    <div className={classes.root}>
      <Paper elevation={4} className={classes.header}>
        <div className={classes.content}>{labels.map((x, i) => <Title key={i} truck={x} />)}</div>
      </Paper>
    </div>
  )
};