const companyReducer = (state = null, action) => {
  if (action.type === 'UPDATE_COMPANY') {
    window.application = window.application ?? { };
    window.application.company_key = action.payload.key;
    window.application.company_url = action?.payload?.val()?.url;
    return action.payload;
  } else return state;
};

export default companyReducer;
