import React, {
  ReactElement,
  PropsWithChildren,
  createContext, useState, useContext
} from "react";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { withWidth, Drawer, Avatar, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useStore } from 'react-redux'
import {useUserPreferences} from "../providers/Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    fontSize: '1em',
    position: 'relative',
    left: -theme.spacing(0.5),
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  divider: {
    position: 'relative',
    width: '120%',
    right: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    flexGrow: 1,
    paddingTop: '0 !important',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(10),
  },
  bottomList: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    left: theme.spacing(1),
    minWidth: theme.spacing(10),
  },
  listItem: {
    borderRadius: theme.spacing(2)
  },
  drawer: {
    borderRadius: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    width: theme.spacing(9) + 1,
    "&:hover": {
      width: theme.spacing(36),
      transition: theme.transitions.create('width', {
        delay: 300,
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
    }
  }
}));

export const PageRoute = ({ icon, title, route }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  
  return (
    <ListItem 
      button
      className={classes.listItem}
      selected={location.pathname === route}
      onClick={() => history.push(route)}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title}/>
    </ListItem>
  )
};

export const NavigationContext = createContext([ null, null ]);

export const useNavigation = () => useContext(NavigationContext);

export const NavigationState = (props) => {
  return (
    <NavigationContext.Provider value={useState(false)}>
      {props.children}
    </NavigationContext.Provider>
  )
};

export const Navigation = (props) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={(theme) => createMuiTheme({ 
      palette: { 
        secondary: theme.palette.secondary,
        type: 'dark' 
      }
    })}>
      <Drawer
        anchor='left'
        variant='permanent'
        className={classes.drawer}
        classes={{
          paper: classes.drawer
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      ><Body>{props.children}</Body></Drawer>
    </ThemeProvider>
  );
};


const Body = (props) => {
  const { user } = useStore().getState();
  const classes = useStyles();

  const name: string = user.val().Name || "User";

  const dispatch = useDispatch();
  const showUserSettings = () =>  dispatch({ type: "SHOW_USER_SETTINGS" });

  return (
    <div className={classes.root}>
      <List className={classes.list}>{props.children}</List>
      <List className={classes.bottomList}>
      <ListItem
        className={classes.listItem}
        selected={true}
        onClick={showUserSettings}
      >
          <ListItemIcon>
            <Avatar alt="ML" className={classes.avatar}>{name.substring(0, 1).toUpperCase()}</Avatar>
          </ListItemIcon>
          <ListItemText primary={name}/>
        </ListItem>
     </List>
    </div>
  );
};

export default Navigation;