import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {Theme} from "@material-ui/core";
import {db} from "../../database/Firebase";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  }
}));

export const JobTooltip = ({ job, metric }) => {
  const classes = useStyles();
  const company = window.application.company_url;
  const [ state, setState ] = useState({});

  useEffect(() => {
    (async () => {
      const newState = {};

      if (job.Customer) {
        const snap = await db().ref(`${company}/Customers/${job.Customer}`).once('value');
        newState.customer = snap.val();
      }

      if (job.Mixture) {
        const snap = await db().ref(`${company}/Mixtures/${job.Mixture}/Name`).once('value');
        newState.mixture = snap.val();
      }

      setState({ ...state, ...newState });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { customer, mixture } = state;

  const hasCustomer = customer?.Name || customer?.Company;

  const num = (x) => Number(Number(x).toFixed(2));

  const meters = (x) => {
    if (metric) return <span>{num(x)} m<sup>3</sup></span>;
    else return <span>{num(x * 1.30795)} yd<sup>3</sup></span>;
  };

  const status = [
    "New",
    "Accepted",
    "On Route",
    "Ticket in Progress",
    "Completed",
    "Delayed",
  ][job.Status || 0];


  return (
    <Paper elevation={4} className={classes.root}>
      { hasCustomer && <Typography variant="h6">
        { customer ? (customer.Company || customer) + " - " : "No Customer Provided" }
        { customer?.Name ? (customer?.Name?.First ?? customer?.Name) + " " : "No Customer Provided" }
        { customer?.Name && typeof(customer?.Name) !== 'string' && customer?.Name?.Last }
      </Typography> }
      { hasCustomer && !!customer?.Phone && <Typography variant="body1">Phone: {customer.Phone}</Typography> }
      { !hasCustomer && <Typography variant="body1">No Customer Provided</Typography> }
      <Typography variant="body1">
        Status: {status} <br/>
        {mixture && meters(job.Amount)}
        {mixture && " of "}
        {(mixture || "No Mixture Specified")}
      </Typography>
    </Paper>
  )
};