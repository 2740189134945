import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {Theme} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    //background: 'rgba(100,100,100,0.6)',
    //color: 'white'
  }
}));

export const TruckTooltip = ({ truck }) => {
  const classes = useStyles();
  return (
    <Paper elevation={4} className={classes.root}>
      <Typography color='inherit' variant='h6'>{
        truck.Name
      }</Typography>
      <Typography color='inherit' variant='body1'>
       Location updated: { moment(truck.Location.Updated).format("LLL") }
      </Typography>
    </Paper>
  )
}