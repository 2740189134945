
const Theme = {
  palette: {
    primary: {
      main: '#FFD600',
      contrastText: '#000'
    },
    secondary: {
      main: '#FA9B1E',
      contrastText: '#000'
    },
  },
};

export const Dark = {
  ...Theme,
  palette: {
    ...Theme.palette,
    type: 'dark'
  },
};


export default Theme;
