import React from 'react';

export const num = (number, decimals) => Number(Number(number).toFixed(decimals ?? 2));

export const mapNameToString = (name) => {
  if (typeof(name) === 'string') return name;
  return `${name.First} ${name.Last}`;
};

export const mapCustomerToLabel = (customer) => {
  if (!customer) return '';
  const name = mapNameToString(customer.Name);
  const company = customer.Company;
  return (company ? (company + " - ") : "") + name;
};

export const mapTruckToLabel = (truck) => `${truck?.Name ?? 'No Name Provided'}`;

export const mapMixtureToMixNameString = (mixture) => `${mixture?.Name ?? 'No Name Provided'}`;

export const mapVolumeToSpan = (cubic_meters, metric) => {
  if (!metric) return (<span>{num(cubic_meters)} M<sup>3</sup></span>);
  return (<span>{num(cubic_meters * 1.30795)} YD<sup>3</sup></span>);
};