export const openDateRange = () => ({
  type: "TOOLBAR_OPEN_TIME",
});

export const openLayers = () => ({
  type: "TOOLBAR_OPEN_LAYERS",
});

export const openAssignments = () => ({
  type: "TOOLBAR_OPEN_ASSIGNMENTS",
});

export const closeAll = () => ({
  type: "TOOLBAR_CLOSE",
});



