import React, {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'sticky',
      height: 0,
      left: theme.spacing(3),
      width: 0,
      overflow: 'visible',
      zIndex: 10
    },
    lines: {
      position: 'sticky',
      width: 0,
      height: 0,
      left: 0,
      zIndex: 0
    },
    time: {
      width: theme.spacing(6),
      paddingRight: theme.spacing(1),
      display: 'block',
      zIndex: 1,
    },
    paper: {
      paddingTop: 65,
      paddingLeft: 115,
      paddingBottom: 10,
      position: 'sticky',
      width: 'min-content',
      zIndex: 300,
    },
    item: {
      position: 'relative',
      height: 50,
    },
    line: {
      position: 'absolute',
      minWidth: '100vw',
      left: 0,
      right: 0,
      zIndex: 0,
    }
  }
});

const useTimes =  () => {
  let position = moment().startOf('day');
  const end = moment().endOf('day');
  const times = [];
  do {
    times.push(moment(position));
  } while (((position = position.add(30, 'minutes')).isBefore(end)));
  return times;
};

const CurrentTimeLine = () => {
  const [ time, setTime ] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment())
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <div
    style={{
      top: time.diff(moment().startOf('day'), 'minutes') / 60 * 100 + 65,
      width: '100vw',
      height: 1,
      background: 'dodgerblue',
      position: 'absolute',
    }}
  />
}

const CurrentTime = () => {

  const [ time, setTime ] = useState(moment());
  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment())
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return (
    <Paper style={{
      paddingTop: 2,
      zIndex: 40,
      background: 'dodgerblue',
      position: 'absolute',
      top: time.diff(moment().startOf('day'), 'minutes') / 60 * 100 + 55,
    }}>
      <Typography
        className={classes.time}
        align='right'
        variant='caption'
        noWrap
        style={{ color: 'white' }}
      >
        {time.format("h:mma").replace('m', '')}
      </Typography>
    </Paper>
  )
};

const mapTimes = (classes) => (time: Moment, index: number) => {
  return (
   <div key={index} className={classes.item}>
     <Typography
       className={classes.time}
       align='right'
       variant='caption'
       noWrap
     >
       {time.format("h:mma").replace('m', '')}
     </Typography>
   </div>
  )
};

const mapLines = (classes) => (_, index: number) => {
  return (<hr key={index}  className={classes.line} style={{
    top: 100 * index + 75,
  }}/>)
};


export const TimeList = () => {
  const times = useTimes();
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <CurrentTime/>
          {times.map(mapTimes(classes))}
        </Paper>
      </div>
     <div className={classes.lines}>
       <CurrentTimeLine/>
       {times.filter((x) => x.minutes() === 0).map(mapLines(classes))}
     </div>
    </>
  )
};