import {Dialog, DialogActions, DialogContent, TextField} from "@material-ui/core";
import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const Content = ({ onClose, initialEmail }) => {
  const [ email, setEmail ] = useState(initialEmail ?? '');

  return (
    <>
      <DialogTitle>
        Forgot Your Password?
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Enter your email or username.
              We'll email instructions on how to reset your password.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              color='secondary'
              variant='filled'
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          onClick={() => {
            window.firebase.auth().sendPasswordResetEmail(email).finally();
            if (onClose) onClose();
          }}
        >Submit</Button>
        <Button
          onClick={onClose}
        >Cancel</Button>
      </DialogActions>
    </>
  );
};

export const ForgotPasswordDialog = ({ open, initialPassword, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={!!open}
      fullWidth
      maxWidth='xs'
      fullScreen={fullScreen}
    >
      <Content initialEmail={initialPassword} onClose={onClose}/>
    </Dialog>
  );
};