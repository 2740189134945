const Routes = {
  LANDING: '/',
  LOADING: '/loading',
  MIXTURES: '/mixtures/',
  CUSTOMERS: '/customers/',
  DISPATCH: '/dispatch/',
  JOBS: '/jobs/',
  TRUCKS: '/trucks/',
  MANAGEMENT: '/management/',
  TEST: '/onetestyboi/',
  MAPS: '/maps/',
  ARCHIVE: '/archive/',
  COMPANY: '/company/',
};

export default Routes;
