import React, {useState} from 'react';
import {compose} from "recompose";
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import {db} from "../../database/Firebase";

const getDefaultState = (x) => {
  if (!x || x.length < 2) return { };
  return { autofill: false, ...x[1]};
};

const View = (props) => {

  const [ state, setState ] = useState(getDefaultState(props.company, props.type));
  const dispatch = useDispatch();

  const filterUrl = (url) => url.toLowerCase().replace(/([^A-Za-z0-9-])+/g, '');

  const handle = (e) => setState({
    ...state,
    [e.target.name]: e.target.value,
    ...((e.target.name === 'Name' && state.autofill === undefined) ? {
      url: filterUrl(e.target.value)
    }:{})
  });


  const handleUrl = (e) => setState({
    ...state,
    [e.target.name]: filterUrl(e.target.value),
    autofill: false,
  });


  const close = () => dispatch({ type: "COMPANY_POPUP_CLOSE" });
  return (
    <Paper style={{ minWidth: '40em', padding: '1em', paddingBottom: '0.5em' }}>
      <Grid container spacing={2} justify='flex-start' alignContent='flex-start'>
        <Grid item xs={12}>
          <TextField
            autoComplete='false'
            name="Name"
            label="Company Name"
            value={state["Name"] || ''}
            onChange={handle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Phone"
            label="Phone"
            value={state["Phone"] || ''}
            onChange={handle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Address"
            label="Address"
            value={state["Address"] || ''}
            onChange={handle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="url"
            label="Website address"
            value={state["url"] || ''}
            onChange={handleUrl}
            fullWidth
            disabled={props.company.length > 1 && !!props.company[1].url}
            InputProps={{
              startAdornment: <InputAdornment position='start'>
                <Typography noWrap style={{ color: 'gray'}}>http://</Typography>
              </InputAdornment>,
              endAdornment: <InputAdornment position='end'>
                <Typography noWrap style={{ color: 'gray', paddingRight: "1em" }}>.batchpro.app</Typography>
                <Tooltip title='
                  Website Address for the customer order page
                  (https://example.batchpro.app) example can be replaced
                '>
                  <Info/>
                </Tooltip>
              </InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={10} style={{ display: 'flex' }}>
          <Button href='' onClick={close}>
            Cancel
          </Button>
          <Button href='' onClick={() => {
            let ref = db().ref(`Companies/`);
            if (props.company.length < 2 || props.company[0] === null) ref = ref.push();
            else ref = ref.child(props.account[0]);
            ref.update(state).finally(close);
          }}><span style={{ color: 'dodgerblue'}}>Save</span></Button>
        </Grid>
        { props.company.length === 2 && <Grid item xs={2}>
          <Button href='' onClick={() => {
            db()
            .ref(`Companies/${props.account[0]}/Deprecated`)
            .set(true)
            .finally(close);
          }}>
            <span className='error'>Archive</span>
          </Button>
        </Grid>}
      </Grid>
    </Paper>
  )
};

const CompanyEditor = (props) => {
  const visible = props.company !== null;
  if (!visible) return <div/>;
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow in={visible} mountOnEnter unmountOnExit>
        <div className='dialog center'>
          <View company={props.company}/>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect((state) => ({ company: state.popupCompany }))
)(CompanyEditor);

