import React, {useState} from 'react';
import {compose} from 'recompose';
import Appbar from '../app_bar';
import {withSnapshotList} from "../../database/firebase_hoc";
import {connect, useDispatch, useStore} from "react-redux";
import '../../styles/archive.css';
import IconButton from "@material-ui/core/IconButton";
import FilterList from '@material-ui/icons/FilterList';
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import AccountEditor from "./account_editor";
import CompanyEditor from "./company_editor";
import Paper from "@material-ui/core/Paper";
import useStyles from './styles';
import User from "../../models/user";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const CustomerListItem = (props) => {
  const { customer } = props;
  const Span = (props) => (<span style={{ flexBasis: "30%"}}>{props.children}</span>);
  return (
    <Typography style={{ display: 'flex' }}>
      <Span>{customer["Name"]}</Span>
      <Span>{customer["Email"]}</Span>
      <Span>{customer["Phone"]}</Span>
    </Typography>
  )
};

const CompanyList = compose(
  connect(
    (state) => ({ companies: state.companies }),
    (dispatch) => ({ edit: (payload) => dispatch({ type: "COMPANY_POPUP_SET", payload }) })
  )
)((props) => Object.entries(props.companies).map(
  ([ key, value ], i) => {
    const selected = key === props.company;
    return (
      <ListItem
        key={`user-item-${i}`}
        component='div'
        button
        selected={selected}
        onClick={selected ? () => props.edit([key, value]) : () => props.onChange(key)}
      >
        <ListItemText primary={value.Name} secondary={value.Subtitle}/>
      </ListItem>
    )
  }
));

const AddNewListItem = (props) => {
  return (
    <ListItem component='div' button {...props}>
      <ListItemText
        primary={<span style={{ color: 'dodgerblue'}}>+ Create new</span>}
      />
    </ListItem>
  )
};

const AccountList = compose(
  connect(
    (state) => ({ users: state.users }),
    (dispatch) => ({ edit: (payload) => dispatch({ type: "ACCOUNT_POPUP_SET", payload }) })
  )
)((props) => Object.entries(props.users).filter(
  ([ , value ]) => value["Company"] === props.company && value["Name"] !== "Deleted User"
).map(
  ([ key, value ], i) => {
    return (
      <ListItem
        key={`user-item-${i}`}
        component='div'
        button
        onClick={() => props.edit([ key, value ])}
      >
        <ListItemText
          primary={<CustomerListItem customer={value} />}
        />
      </ListItem>
    );
  }
));

export const ColumnTitle = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.columnTitle}>
      <Typography variant='h6'><strong>{props.children}</strong></Typography>
    </div>
  )
};

const ManagementPage = () => {
  const state = useStore().getState();
  const { user } = state;
  const classes = useStyles();
  const [ company, setCompany ] = useState(state.company.key);
  const dispatch = useDispatch();

  const { Permissions } = user.val();

  if (!Permissions?.Accounts && !Permissions?.Admin) return (<></>)

  console.log();

  return (
    <main>
      <Paper className={classes.columnPaper}>
        <Paper className={classes.columnHeader}>
          { User(user).Permissions.Admin && <ColumnTitle>Companies</ColumnTitle> }
          <ColumnTitle>User Accounts</ColumnTitle>
        </Paper>
        <div className={classes.columnBody}>
          { User(user).Permissions.Admin && <div className={classes.columnLeft}>
            <AddNewListItem onClick={() => dispatch({ type: "COMPANY_POPUP_SET", payload: [] })}/>
            <CompanyList company={company} onChange={setCompany}/>
          </div>}
          <List component='div' id='scrollable' className={classes.column}>
            <AddNewListItem onClick={() => dispatch({ type: "ACCOUNT_POPUP_SET", payload: [ null, {
              Company: company
            } ] })}/>
            <AccountList company={company}/>
          </List>
        </div>
      </Paper>
      <Appbar title="Account Management"/>
      <IconButton href=''>
        <FilterList/>
      </IconButton>
      <AccountEditor/>
      <CompanyEditor/>
    </main>
  )
};

export default compose(
  withSnapshotList("Customers"),
  withSnapshotList("/Users"),
  withSnapshotList("/Companies"),
)(ManagementPage);
