import {withStyles} from "@material-ui/styles";
import {Theme, Tooltip} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";

export const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    borderRadius: theme.spacing(1),
    backgroundColor: blueGrey[900],
    boxShadow: theme.shadows[1],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    borderWidth: '0 0 0 8px',
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    fontSize: 16,
  },
}))(Tooltip);
