import React from 'react';
import { Toolbar, ListItem, ListItemIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import logo from '../images/bay_lynx.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(4),
  },
  icon: {
    width: theme.spacing(4),
    transform: `translate(${theme.spacing(-1.5)}px, 0) scale(0.8)`,
    margin: 'auto',
  },
  title: {
    paddingTop: theme.spacing(1), 
  },
}));

export const AppBranding = () => {
  const classes = useStyles();

  return (
    <Toolbar disableGutters>
      <ListItem className={classes.root}>
        <ListItemIcon>
          <img src={logo} alt='' className={classes.icon}/>
        </ListItemIcon>
        <Typography variant='h6' className={classes.title}>
          BatchPro Connect
        </Typography>
      </ListItem>
    </Toolbar>
  )
}