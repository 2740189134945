import React, { useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {useCardView} from "./EditableCardView";
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import {MenuItem} from "@material-ui/core";

export const DraggableCard = ({ ...props }) => {

  const Body = props.body;
  const { spacing, width, onChange, left, top, max } = useCardView();
  const [ key, value ] = props.value;
  const column = width + 2 * spacing;

  const [ state, setState ] = useState({
    position: value ? [ value.x, value.y ] : [ spacing, spacing ],
    height: value.height || 200
  });

  const { offset, position, resize } = state;
  const [ x, y ] = position;

  const setPosition = (x, y) => setState({
    ...state,
    position: [ x, y ]
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { value && !resize && setState({ ...state, height: value.height }) }, [ value.height ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { value && !offset && setPosition(value.x, value.y) }, [ value.x, value.y ]);

  const onGrab = (e) => {
    e.preventDefault();
    if (e.button === 2) return;
    setState({
      ...state,
      offset: [
        (x - e.clientX),
        y - e.clientY
      ]
    })
  };


  const onRelease = () => {
    document.onmousemove = undefined;
    setState({
      ...state, offset: undefined,
      position: (x === value.x && Math.abs(y - value.y) < 25)
        ? [ value.x, value.y ]:
        position,
      height: Math.abs(state.height - value.height) < 25 ? value.height : state.height,
      resize: undefined
    });
    onChange([ key, { x: position[0], y: position[1], height: state.height, width: width }])
  };

  const onDrag = (e) => {
    e.preventDefault();
    let x = e.clientX + (window.scrollX - left);
    if (max && x >= max) x = max - 1;
    x = Math.floor(x / column) * column + spacing;
    const y = e.clientY + offset[1];
    setState({
      ...state,
      position: [
        x > 0 ? x : 5,
        y > 0 ? y : 0
      ]
    });
  };

  const onResize = (e) => {
    e.preventDefault();
    const height = (e.clientY - y) + (window.scrollY - top);
    setState({
      ...state,
      height: height > 25 ? height : 25,
    })
  };

  if (resize) document.onmousemove = onResize;
  else if (offset) document.onmousemove = onDrag;
  if (offset || resize) document.onmouseup = onRelease;

  return (
    <>
      <ContextMenuTrigger id={key}>
        <div
          style={{
            position: 'absolute',
            zIndex: offset && 8,
            top: isNaN(y) ? 0 : y,
            left: isNaN(x) ? 0 : x,
            height: state.height,
            width: width,
            transition:
              'left 300ms ease'
              + ((offset) ? '' : ', top 300ms ease')
              + ((resize) ? '' : ', height 300ms ease')
          }}
        >
          <Paper
            style={{
              position: 'relative',
              bottom: 0,
              top: 0,
              left: 0,
              right: 0,
              height: '100%',
              overflow: 'hidden',
            }}
            onMouseDown={onGrab}
            elevation={offset && 4}
          >
            { Body && <Body id={key}/> }
          </Paper>
          <div
            onMouseDown={(e) => setState({ ...state, resize: y - e.clientY })}
            style={{
              position: 'absolute',
              bottom: 0,
              height: 3,
              left: 0,
              right: 0,
              cursor: 'row-resize'
            }}
          />
        </div>
      </ContextMenuTrigger>
      <ContextMenu id={key}>
        <Paper>
          <MenuItem onClick={() => props.onEdit(key)}>Edit</MenuItem>
          <MenuItem onClick={()=>props.onDuplicate(key)}>Duplicate Job</MenuItem>
          <MenuItem onClick={()=>props.onAddTruck(key)}>Add another Truck</MenuItem>
          <MenuItem onClick={() => props.onDelete(key)}>Delete</MenuItem>
        </Paper>
      </ContextMenu>
    </>
  )
};
