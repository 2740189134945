import React from 'react';
import {compose} from 'recompose';
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {updateDispatchEnd, updateDispatchStart} from "../../actions/dispatch";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {useLayersContext} from "./visible_layers";
import {Radio} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {LocalShipping, Room} from "@material-ui/icons";
import {openLayers} from "../../actions/toolbar";

const LayersView = (props) => {
  const visible = props.tool === 'LAYERS';
  const [ layers, setLayers ] = useLayersContext();
  return (
    <>
      { visible && <div
        onClick={props.layers}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9000 }}
      /> }
      <Grow in={visible} mountOnEnter unmountOnExit>
        <Paper className='tool-window-layers-body'>
          <List component='div'>
            <ListItem component='div'>
              <ListItemIcon>
                <Checkbox
                  checked={layers.trucks}
                  onChange={() => setLayers({ ...layers, trucks: !layers.trucks })}
                />
              </ListItemIcon>
              <ListItemText
                primary="Trucks"
              />
              <ListItemSecondaryAction>
                <LocalShipping/>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem component='div'>
              <ListItemIcon>
                <Checkbox
                  checked={layers.jobs}
                  onChange={() => setLayers({ ...layers, jobs: !layers.jobs })}
                />
              </ListItemIcon>
              <ListItemText
                primary="Jobs"
              />
              <ListItemSecondaryAction>
                <Room/>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider/>
            <ListItem component='div'>
              <ListItemIcon>
                <Radio
                  checked={layers.streets}
                  onChange={() => setLayers({
                    ...layers,
                    streets: !layers.streets,
                    satellite: layers.streets
                  })}
                />
              </ListItemIcon>
              <ListItemText
                primary="Street"
              />
            </ListItem>
            <ListItem component='div'>
              <ListItemIcon>
                <Radio
                  checked={layers.satellite}
                  onChange={() => setLayers({
                    ...layers,
                    satellite: !layers.satellite,
                    streets: layers.satellite
                  })}
                />
              </ListItemIcon>
              <ListItemText
                primary="Satellite"
              />
            </ListItem>
          </List>
          <div className='tool-window-layers-footer'>
            <Typography align='center'>
              Select which to be visible
            </Typography>
          </div>
        </Paper>
      </Grow>
    </>
  );
};

export default compose(
  connect(
    (state) => ({
      tool: state.toolbarWindow,
      range: state.dispatchDateRange
    }),
    (dispatch) => ({
      layers: () => dispatch(openLayers()),
      updateStart: (date) => dispatch(updateDispatchStart(date)),
      updateEnd: (date) => dispatch(updateDispatchEnd(date))
    })
  )
)(LayersView);
