import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import {useDialogs} from "../providers/dialogs";
import {DialogActions, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {red} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {DatePicker} from "@material-ui/pickers";
import {TimePicker} from "@material-ui/pickers";
import moment from 'moment'
import {
  getCustomerByEmail,
  getMixtureByName,
  useCompanyUrl,
  useCustomerList,
  useMixtureList,
  useTrucksList
} from "../providers/Firebase";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {AddressAutoComplete} from "../components/AddressAutoComplete";
import powered from '../images/powered_by_google_on_white.png';
import {AmountField} from "../components/AmountField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {mapCustomerToLabel, mapMixtureToMixNameString, mapTruckToLabel} from "../components/labels";


import {Ticket,useTicketDialog} from "../views/archive_page/ticket";
import {company_db as db, db as root, runTransactionDeleteJob} from "../database/Firebase";


var isChildJob;
var parentJobKey;

const useStyles = makeStyles((theme) => {
  return {
    google: {
      position: 'fixed',
      bottom: theme.spacing(1),
      right: theme.spacing(2)
    },
    gap: {
      flexGrow: 1
    }
  }
});

export const useJobDialog = () => {

  const [state, setState] = useDialogs();
  return {
    show: async (job, parentJob) => {

      if (job && job[2]) {
        const [ job_key, job_data, [ order_key, order ] ] = job;
        console.log("Are we here? A")
        setState({
          ...state,
          orders: undefined,
          job: [
            job_key,
            {
              ...job_data,
              Amount: job_data.Amount ?? order.Product.Amount,
              Address: job_data.Address ?? order.Address,
              Notes: job_data.Notes ?? order.Notes,
              Order: order_key,
              Application: job_data.Application ?? order.Application,
              Mixture: job_data.Mixture ?? (order?.Product?.Name && (await getMixtureByName(order?.Product?.Name))[0]),
              Customer: job_data.Customer ?? (await getCustomerByEmail(order.Customer.Email))[0],
            }
          ]
        })
      } else {
          //console.log(job[1].Status);
        
        if(parentJob != undefined && parentJob[0]){
          isChildJob = parentJob[0];
          parentJobKey = parentJob[1];
        }
        setState({
          ...state,
          job,
          orders: undefined,
        })
      }
    } ,
    close: () => setState({...state, job: undefined}),
    job: state.job,
  }
};

export const Actions = (props) => {
  const [confirm, setConfirm] = useState(false);
  const classes = useStyles();
  //updated by nick (added archive button when state == 5)
  return (
    <DialogActions>
      
      {confirm || <Button
        onClick={props.onCancel}
      >Cancel</Button>}

      {confirm || <Button
        style={{ color: 'dodgerblue',}}
        onClick={props.onSave}
      >Save</Button>}
      
      
      <div className={classes.gap}/>
      { props.showDelete && <Button
        style={confirm ? {} : { color: red[500] }}
        onClick={() => setConfirm(!confirm)}
      >{confirm ? "No" : "delete"}</Button>}

      {confirm && <Button
        style={{ color: 'white', background: red[500] }}
        onClick={() => props.onDelete && props.onDelete()}
      >Yes</Button>}

      {confirm && <Typography variant='caption'>
        <strong>Are you sure?</strong> this cannot be undone.
      </Typography>}

       {(props.status === 5 && props.hasTicketID ) && <Button
        style={{ color: 'orange',}}
        onClick={props.onViewTicket}
      >View Archive</Button>}

    </DialogActions>
  )
};



const sendNotication = async (jobData)  => {
  var name = await (await db(`Trucks/${jobData["Truck"]}/Name`).get()).val();
  var messages = [];
  await root().ref("Users").orderByChild('Truck').equalTo(jobData["Truck"]).on('value',(snapshot)=>{
    var data =  snapshot.val();
    
    if(data!= null){
      Object.keys(data).forEach((key)=>{
      if(data[key]["FcmTokens"]!= null && data[key]["FcmTokens"]!= undefined){
      Object.keys(data[key]["FcmTokens"]).forEach((token)=>{
        messages.push({"data":{
            "FCMToken":data[key]["FcmTokens"][token],
            "message":{
              "title":"Updated Truck Assignemt",
              "body":`${name} has an updated assignemt.`
              }
            }});
          });
        }
      });
    }
  });

  messages.forEach((msg)=>{
    fetch('https://us-central1-fd-remote.cloudfunctions.net/pushNotifactions',{
      method:"POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
      },          
      body:JSON.stringify(msg)
    });
  });

}

const Content = (props) => {

  
  const dialog = useTicketDialog();
  const { job, close, show } = useJobDialog();
  const trucks = useTrucksList();
  const mixtures = useMixtureList();

  let [ key, value ] = job || [ undefined, { start: moment() } ];


  const customers = useCustomerList();
  const company = useCompanyUrl();
  //Check error if it is the second time opening a Job
  const errorCheck= (key == undefined)

  key = key ?? db('Jobs').push().key;

  const sorting = (a: string, b: string) => {
    a = mapCustomerToLabel(customers[a]).toLowerCase();
    b = mapCustomerToLabel(customers[b]).toLowerCase();
    return a.localeCompare(b);
  };
  const finished = (value.Status === 5)?"disabled":"";


  const [dialogsState,setDialogsState] = useDialogs();

  // if (value.Mixture && !mixtures[value.Mixture]) return (<></>);
  // if (value.Truck && !trucks[value.Truck]) return (<></>);
  //if (value.Customer && !customers[value.Customer]) return (<></>);
  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}/>
          <Grid item xs={2}>
            <DatePicker
              value={value.Start}
              disabled={finished}
              color='secondary'
              onChange={(e) => show([ key, { ...value, Start: e.toISOString() } ])}
              autoOk
              fullWidth
              label="Date *"
            />
          </Grid>
          <Grid item xs={2}>
            <TimePicker
              value={value.Start}
              disabled={finished}
              color='secondary'
              onChange={(e) => show([ key, { ...value, Start: e.toISOString() } ])}
              minutesStep={15}
              fullWidth
              label="Time *"
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              disabled={finished}
              color='secondary'
              value={value.Duration ?? ''}
              type='number'
              onChange={(e) => show([ key, { ...value, Duration: Number(e.target.value) || e.target.value } ])}
              label="Duration"
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              value={(customers[value.Customer] && value.Customer) ?? ''}
              disabled={finished}
              color='secondary'
              options={Object.keys(customers).sort(sorting)}
              getOptionLabel={(x) => (x !== '' &&  mapCustomerToLabel(customers[x])) ?? ''}
              onChange={(e, x) => show([ key, { ...value, Customer: x } ])}
              filterOptions={(options,state) => {
                return options.filter((x) => {
                  const option = mapCustomerToLabel(customers[x]).toLowerCase();
                  return option.startsWith(state.inputValue.toLowerCase());
                });
              }}
              // fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  color='secondary'
                  label="Customer *"
                  fullWidth
                  error= {!errorCheck && value.Customer == undefined}
                  helperText={!errorCheck && value.Customer == undefined ? 'Empty field!' : " "}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={(trucks[value.Truck] && value.Truck) ?? ''}
              disabled={finished}
              color='secondary'
              options={Object.keys(trucks)}
              getOptionLabel={x => (x !== '' && mapTruckToLabel(trucks[x])) ?? ''}
              onChange={(e, x) => show([ key, { ...value, Truck: x } ])}
              filterOptions={(options,state) => {
                return options.filter((x) => {
                  const option = mapTruckToLabel(trucks[x]).toLowerCase();
                  return option.startsWith(state.inputValue.toLowerCase());
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Truck"
                  color='secondary'
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <AddressAutoComplete
              error ={!errorCheck && value?.Address == undefined}
              value={value?.Address ?? ''}
              disabled={finished}
              color='secondary'
              onChange={(x) => show([ key, { ...value, Address: x } ])}
              label="Address *"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disabled={finished}
              value={(mixtures[value.Mixture] && value.Mixture) ?? ''}
              color='secondary'
              options={Object.entries(mixtures).filter(e => !e[1].Deprecated).map(e => e[0])}
              getOptionLabel={x => (x !== '' && mapMixtureToMixNameString(mixtures[x])) ?? '' }
              onChange={(e, x) => show([ key, { ...value, Mixture: x } ])}
              fullWidth
              filterOptions={(options,state) => {
                return options.filter((x) => {
                  const option = mapMixtureToMixNameString(mixtures[x]).toLowerCase();
                  return option.startsWith(state.inputValue.toLowerCase());
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  color='secondary'
                  label="Mixture *"
                  fullWidth
                  error= {!errorCheck && value.Mixture == undefined}
                  helperText={!errorCheck && value.Mixture == undefined ? 'Empty field!' : ' '}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <AmountField
              fullWidth
              color='secondary'
              value={value.Amount ?? ''}
              disabled={finished}
              end={[
                <span>M<sup>3</sup></span>,
                <span>YD<sup>3</sup></span>
              ]}
              conversion={1.30795}
              type='number'
              onChange={(e) => show([ key, { ...value, Amount: e } ])}
              label="Amount"
            />
          </Grid>
          <Grid item xs={12}/>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={finished}
              color='secondary'
              value={value.Application ?? ''}
              variant="outlined"
              placeholder={'No application provided'}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => show([ key, { ...value, Application: e.target.value } ])}
              label="Application"
            />
          </Grid>
          <Grid item xs={12}/>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={finished}
              color='secondary'
              value={value.Notes || ''}
              variant="outlined"
              placeholder={'No Additional information provided'}
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rows={6}
              onChange={(e) => show([ key, { ...value, Notes: e.target.value } ])}
              label="Notes"
            />
          </Grid>
          <Grid item xs={11}>
            <img src={powered} alt='powered by google'/>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="caption">
              *required
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Actions
        hasTicketID = {(value.TicketID != null)}
        status={value.Status}
        onCancel={()=>{
          close();
          isChildJob = undefined;
          parentJobKey = undefined;
        }}
        showDelete={!!key} g
        onViewTicket={async() =>{
            let query = await db('Tickets/'+value.TicketID).once("value");
            setDialogsState((x)=>({...x, ticket:query}));
        }}
        onSave={async () => {
          close();



          const update = Object.entries(value).filter(
            ([ key, value ]) => {
              if (key === 'Duration') return typeof(value) === 'number';
              else return !!value;
            }
          ).map(
            ([ key, value ]) => {
              if (key === 'Duration') value = Math.round(value * 4) / 4;
              if (key === 'Amount') value = Math.round(value * 100) / 100;
              return [ key, value ]
            }
          );
          

          if(isChildJob){
            if(value["Parent"] == null || value["Parent"] == undefined){
              db(`Jobs/${parentJobKey}/children/${key}`).set(value["Truck"])
              update.push(["Parent", parentJobKey])
            }else{
              db(`Jobs/${value["Parent"]}/children/${key}`).set(value["Truck"])
            }
          }

          await db(`Jobs/${key}`).update(Object.fromEntries(update));

          ///Left off
          //Not way of noteing they are linked

          if(value["Parent"]== undefined && value["children"]!= undefined && Object.keys(value["children"]).length >= 1){
            var childrenJobs = Object.keys(value["children"]);
            updateChildern(value,childrenJobs,key);
          }

          if(value["Parent"]!= undefined && value["Parent"]!=""){
            var testData = [ ];
            (value["Address"] && testData.push( ["Address",value["Address"]]));
            (value["Amount"] && testData.push( ["Amount",value["Amount"]]));
            (value["Customer"] && testData.push( ["Customer",value["Customer"]]));
            (value["Duration"] && testData.push( ["Duration",value["Duration"]]));
            (value["Mixture"] && testData.push( ["Mixture",value["Mixture"]]));
            (value["Start"] && testData.push( ["Start",value["Start"]]));
            (value["Application"] && testData.push( ["Application",value["Application"]]));
            (value["Notes"] && testData.push( ["Notes",value["Notes"]]));
            
            db(`Jobs/${value["Parent"]}`).update(Object.fromEntries(testData));

            updateChildern(value,Object.keys((await db(`Jobs/${value["Parent"]}/children`).get()).val()),key);
            var otherJobData = (await db(`Jobs/${value["Parent"]}`).get()).val();
            sendNotication(otherJobData);
           
          }

          sendNotication(value);

          if (value.Order) await db(`Orders/${value.Order}/assigned_to/${key}`).set(true);
          if (value.Order) await db(`Orders/${value.Order}/assigned`).set(true);
          
          isChildJob = undefined;
          parentJobKey = undefined;
        }}
        onDelete={() => {
          runTransactionDeleteJob(key).finally();
          close();
          isChildJob = undefined;
          parentJobKey = undefined;
        }}
      />
    </>
  )
}


const updateChildern = (value,childrenJobs, skipKey) =>{
  //console.log(childrenJobs);
  childrenJobs.forEach(async (key)=>{
    if(key != skipKey){
      var testData = [ ];
      console.log(value);
      if(value["children"] != undefined ||value["children"] != null ){
        console.log("TEST");
        testData.push(["Truck", value["children"][key]]);
      }

      (value["Address"] && testData.push( ["Address",value["Address"]]));
      (value["Amount"] && testData.push( ["Amount",value["Amount"]]));
      (value["Customer"] && testData.push( ["Customer",value["Customer"]]));
      (value["Duration"] && testData.push( ["Duration",value["Duration"]]));
      (value["Mixture"] && testData.push( ["Mixture",value["Mixture"]]));
      (value["Start"] && testData.push( ["Start",value["Start"]]));
      (value["Application"] && testData.push( ["Application",value["Application"]]));
      (value["Notes"] && testData.push( ["Notes",value["Notes"]]));
      db(`Jobs/${key}`).update(Object.fromEntries(testData));
      var otherJobData = (await db(`Jobs/${key}`).get()).val();
      sendNotication(otherJobData);
    }
  });
}



export const JobEditor = () => {
  const { job } = useJobDialog();

        //<Ticket in={value} onClose={()=>{}}/>
        //console.log(job)
  return (
    <Dialog open={!!job} fullWidth maxWidth='md' keepMounted={false}>
      <Content/> 
    </Dialog>
  )
};