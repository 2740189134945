import React from 'react';
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {useCustomerList, useJobsList, useMixtureList, useTrucksList, useUserPreferences} from "../providers/Firebase";
import {useDateFilter} from "../providers/date_filter";
import moment from "moment";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {mapCustomerToLabel, mapMixtureToMixNameString, mapTruckToLabel, mapVolumeToSpan} from "../components/labels";
import {useJobDialog} from "./JobEditor";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: '#EEE',
      position: 'fixed',
      left: theme.spacing(18),
      bottom: theme.spacing(2),
      zIndex: 2000,
      width: theme.spacing(100),
      height: theme.spacing(80),
      overflow: 'hidden',
    },
    scrollable: {
      position: 'absolute',
      overflow: 'auto',
      padding: theme.spacing(2),
      paddingTop: 0,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    list: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1999
    },
    no_jobs: {
      margin: 'auto',
      position: 'absolute',
      top: theme.spacing(10),
      bottom: 0,
      right: 0,
      left: 0,
    },
    no_job: {
      margin: 'auto',
      position: 'absolute',
      top: theme.spacing(10),
      bottom: 0,
      right: 0,
      left: 0,
    },
    gap: {
      height: theme.spacing(10),
    },
    top_right: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
    }
  }
});


const NoJobs = () => {
  const classes = useStyles();
  return (
    <div className={classes.no_job}>
      <Typography variant='h4' align='center'>
        <strong>There is nothing here!</strong>
      </Typography>
      <Typography variant='body1' align='center'>
        Once an job is created it will be visible here
      </Typography>
    </div>
  )
};

const JobsList = ({ onClose }) => {
  const all_jobs = useJobsList();
  const trucks = useTrucksList();
  const customers = useCustomerList();
  const mixtures = useMixtureList();
  const classes = useStyles();
  const metric = useUserPreferences().Metric;

  const [ { start, end } ] = useDateFilter();

  const jobs = Object.fromEntries(
    Object.entries(all_jobs).filter(
      ([ , x ]) => moment(x.Start).isBetween(
        moment(start).startOf('day'),
        moment(end).endOf('day'),
        null,
        '[]'
      )
    )
  );

  const dialogs = useJobDialog();

  if (Object.keys(jobs).length === 0) return (<NoJobs/>);

  const mapToJobCard = ([ key, job ], i) => {

    const customer = customers[job.Customer];
    const truck = trucks[job.Truck];
    const mixture = mixtures[job.Mixture];

    return (
      <ExpansionPanel key={i} expanded={false}>
        <ExpansionPanelSummary onClick={() => {
          onClose();
          dialogs.show([ key, job ])
        }}>
          <div>
            { job?.Duration && <div className={classes.top_right}>
              <Typography variant='h6'>
                { Number(Number(job.Duration).toFixed(1)) } h
              </Typography>
            </div>}
            { job?.Start &&  <Typography variant='caption'>
              { moment(job.Start).format('LLL') }
              { job.Duration && <span> to </span> }
              { job.Duration && moment(job.Start).add(job.Duration * 60, 'minutes').format('h:mm A') }
            </Typography>}
            { job?.Customer && customer && <Typography variant='subtitle1'>
              <strong>{mapCustomerToLabel(customer)}</strong>
            </Typography>}
            { job?.Mixture && mixture && job.Amount && <Typography variant='body1'>
              {mapVolumeToSpan(job.Amount, metric)} of {mapMixtureToMixNameString(mixture)}
            </Typography>}
            { job?.Truck && truck && <Typography variant='body1'>
              <strong>Assigned To: </strong>{mapTruckToLabel(truck)}
            </Typography>}
          </div>
        </ExpansionPanelSummary>
      </ExpansionPanel>
    )
  };

  return Object.entries(jobs).map(mapToJobCard)
};

export const JobsDialog = ({ visible, onClose }) => {
  const classes = useStyles();
  return (
    <>
      { visible && <div
        className={classes.backdrop}
        onClick={() => onClose()}
      />}
      <Grow
        in={visible}
        mountOnEnter
      >
        <Paper
          elevation={4}
          className={classes.root}
        >
          <Toolbar>
            <Typography variant='h6'>
              Job Assignments
            </Typography>
          </Toolbar>
          <div className={classes.list}>
            <div className={classes.scrollable}>
              <JobsList onClose={onClose}/>
              <div className={classes.gap}/>
            </div>
          </div>
        </Paper>
      </Grow>
    </>
  )
};