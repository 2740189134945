import React from 'react';
import {compose} from "recompose";
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch, useStore} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import useForm from "../form/form_controller";
import FormField, {CheckFormField} from "../form/form_field";

const materialsSelection = (variant, materials) => {
  const filter = (x) => x[1]["Type"] === variant && !x[1]["Deprecated"];
  return Object.entries(materials || {}).filter(filter).map(
    ([ key, material ], i) => {
      return (
        <MenuItem key={i} value={key}>
          {material["Name"]}
        </MenuItem>
      )
    }
  )
};

const truckSelection = () => {
  return Object.entries({"MDM_2":"MDM 2.0", "HDM_2":"HDM 2.0", "HDM_3":"HDM 3.0", "HARDOX":"TITAN", "PREMIX":"PREMIX", "HDM_G":"GRAVIMETRIC", "MDM_TITAN":"TITAN MDM", "SCREED_TITAN":"TITAN SCREED"}).map(
    ([ key, value], i) => {
      return (
        <MenuItem key={i} value={key}>
          {value}
        </MenuItem>
      )
    }

  );
};

const defined = (t) => t !== null && t !== undefined;

const MixDesignerView = (props) => {
  const appState = useStore().getState();
  const { company, materials } = appState;
  const user = appState.user.val();

  const imperial = (user.Preferences||{}).Metric === false;

  const dispatch = useDispatch();
  const close = () => dispatch({ type: "MIXTURE_POPUP_CLOSE" });

  const mix = ((props.mixture||[])[1]||{});
  const hasWaterRatio = !!mix?.Water && !!mix?.Cement?.Amount;

  const controller = useForm({
    ...mix,
    WaterRatio: hasWaterRatio ? mix.Water / mix.Cement.Amount : undefined,
    truckVariant: mix.truckVariant?mix.truckVariant :"HARDOX"
  });

  const state = controller.state;

  const admixes = state["Admixes"] ? state["Admixes"].length : 0;

  const addAdmixture = () => {
    if (admixes >= 4) return;
    if (!state["Admixes"]) state["Admixes"] = [];
    controller.updateState(`Admixes/${admixes}`, { });
  };

  const removeAdmixture = () => {
    if (admixes < 1) return;
    const admix = [ ...controller.state.Admixes ];
    admix.pop();
    controller.setState({
      ...controller.state,
      Admixes: admix,
    });
  };


  state["Water"] = ((state["Cement"]||{})["Amount"] || 0) * state.WaterRatio;

  const submit = () => {
    if (!defined(state.Name)) return;
    if (!defined(state.Strength)) return;
    if (!defined(state.Cement)) return;
    if (!defined(state.Cement.Reference)) return;
    if (!defined(state.Cement.Amount)) return;
    if (!defined(state.Water) || state.Water <= 0) return;
    if (!isScreed && !defined(state.Stone)) return;
    if (!isScreed && !defined(state.Stone.Reference)) return;
    if (!isScreed && !defined(state.Stone.Amount)) return;
    if (!defined(state.Sand)) return;
    if (!defined(state.Sand.Reference)) return;
    if (!defined(state.Sand.Amount)) return;

    if (state.Admixes) {
      for (let i = 0; i < state.Admixes.length; i++) {
        const admix = state.Admixes[i];
        if (!admix.Type) return;
        if (!admix.Amount) return;
        if (admix.Dilution instanceof Number) return;
      }
    }

    // Create a mutated copy of the state without mutating either object
    // We want to remove any trailing whitespace from the name
    // Remove the WaterRatio
    // Increment the revision number or set it to 0 if it doesn't exist
    const { WaterRatio, ...clean } = {
      ...state,
      Name: state.Name.trim(),
      Revision: (state.Revision + 1) || 0,
    };

    let ref = window.firebase.database().ref(`${company.val().url}/Mixtures/`);
    if (props.mixture.length < 2) ref = ref.push();
    else ref = ref.child(props.mixture[0]);

    ref.update(clean).finally(close);
  };

  if(state.truckVariant === "SCREED_TITAN" && !isScreed && defined(state.Stone)){
    state.Stone = '';
  }
  var isScreed = state.truckVariant === "SCREED_TITAN" || false;
  return (
    <Paper style={{ minWidth: '60em', padding: '1em', paddingBottom: '0.5em' }}>
      <form onSubmit={controller.submit(26, submit)}>
        <Grid container spacing={2} justify='flex-start' alignContent='flex-start'>
          <Grid item xs={6}>
            <FormField
              id={0}
              controller={controller}
              name="Name"
              label="Name"
              fullWidth
              max={28}
              controlled
              handle={x => x.replace(/([^A-Za-z0-:- &-*,-/])/g, '')}
              end={<InputAdornment position='end'>
                <span style={{color: 'gray'}}>{(controller.state['Name'] || '').length}/28</span>
              </InputAdornment>}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              id={30}
              controller={controller}
              name="truckVariant"
              label="Truck Type"
              default={((props.mixture || [])[1] || {}).truckVariant || 'HARDOX'}
              fullWidth
              select
            >{truckSelection()}</FormField>
          </Grid>
          <Grid item xs={3}>
            <FormField
              id={1}
              controller={controller}
              name="Strength"
              label="Strength"
              fullWidth
              handle={(x) => Number(x)}
              conversion={imperial ? 0.00689476 : undefined}
              end={
                <InputAdornment position="end">
                  { imperial ? "PSI" : "MPa" }
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={6} >
            <FormField
              id={2}
              controller={controller}
              name="Cement/Reference"
              label="Cement"
              default={(((props.mixture || [])[1] || {}).Cement || {}).Material}
              fullWidth
              select
            >{materialsSelection('Cement', materials)}</FormField>
          </Grid>
          <Grid item xs={3}>
            <FormField
              id={3}
              controller={controller}
              name="Cement/Amount"
              label="Cement"
              fullWidth
              handle={(x) => Number(x)}
              conversion={imperial ? 0.59326056 : undefined}
              end={<InputAdornment position="end">
                { imperial ? "LB" : "KG" }
              </InputAdornment>}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              id={4}
              controller={controller}
              name="WaterRatio"
              label="Water Ratio"
              handle={(x) => Number(x)}
              fullWidth
              end={
                <InputAdornment position="end">
                  <Typography noWrap>
                    {Number(`${
                      imperial
                        ? (state["Water"] || 0) * 0.202
                        : (state["Water"] || 0)
                    }`).toFixed(1)} { imperial ? "GAL" : "L" }
                  </Typography>
                </InputAdornment>
              }
            />
          </Grid>
          {!isScreed && <Grid item xs={3} name="Grid/Stone/Reference">
            <FormField 
              id={5}
              controller={controller}
              name="Stone/Reference"
              label="Stone"
              fullWidth
              select
            >{materialsSelection('Stone', materials)}</FormField>
          </Grid>}
          {!isScreed &&
          <Grid item xs={3}>
            <FormField
              id={6}
              controller={controller}
              name="Stone/Amount"
              label="Amount"
              type='number'
              handle={(x) => Number(x)}
              fullWidth
              conversion={imperial ? 0.59326056 : undefined}
              end={<InputAdornment position="end">
                { imperial ? "LB" : "KG" }
              </InputAdornment>}/>
          </Grid>}
          <Grid item xs={(!isScreed)?3:6}>
            <FormField
              id={7}
              controller={controller}
              name="Sand/Reference"
              label="Sand"
              fullWidth
              select
            >{materialsSelection('Sand', materials)}</FormField>
          </Grid>
          <Grid item xs={(!isScreed)?3:6}>
            <FormField
              id={8}
              controller={controller}
              name="Sand/Amount"
              label={(!isScreed)?"Amount":"Sand Amount"}
              type='number'
              fullWidth
              handle={(x) => Number(x)}
              conversion={imperial ? 0.59326056 : undefined}
              end={<InputAdornment position="end">
                { imperial ? "LB" : "KG" }
              </InputAdornment>}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ paddingLeft: '1em', display: 'flex'}}>
                <Typography variant='overline'>
                  Admixtures { admixes }/4
                </Typography>
                <Button href='' style={{ padding: 0, margin: 0 }} onClick={addAdmixture}>
                  <Typography variant='overline' style={{ color: "dodgerblue"}}>+ Add</Typography>
                </Button>
                <div/>
                <Button href='' style={{ padding: 0, margin: 0 }} onClick={removeAdmixture}>
                  <Typography variant='overline' style={{ color: "dodgerblue"}}>- Remove</Typography>
                </Button>
              </Grid>{
                Object.values(state["Admixes"] || []).map(
                (admix, i) => {
                  return [
                    <Grid key={`a-${i}`} item xs={6}>
                      <FormField
                        id={9 + (3 * i)}
                        controller={controller}
                        name={`Admixes/${i}/Type`}
                        label="Admixture"
                        fullWidth
                        select
                      >{materialsSelection('Admix', materials)}</FormField>
                    </Grid>,
                    <Grid key={`b-${i}`} item xs={3}>
                      <FormField
                        id={10 + (3 * i)}
                        controller={controller}
                        name={`Admixes/${i}/Amount`}
                        label="Amount"
                        type='number'
                        conversion={imperial ? 65.198399 : undefined}
                        handle={(x) => Number(x)}
                        fullWidth
                        end={
                          <InputAdornment position="end">
                            <Typography noWrap>
                              { imperial ? "oz/CWT" : "ml/100KG" }
                            </Typography>
                          </InputAdornment>
                        }
                      />
                    </Grid>,
                    <Grid key={`c-${i}`} item xs={3}>
                      <FormField
                        id={11 + (3 * i)}
                        controller={controller}
                        name={`Admixes/${i}/Dilution`}
                        label="Dilution"
                        type='number'
                        fullWidth
                        handle={(x) => Number(x)}
                        InputProps={{ id: "right-align" }}
                        end={<InputAdornment position="end">:1</InputAdornment>}
                      />
                    </Grid>
                  ];
                }
              )
            }</Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='overline'>
                  Options
                </Typography>
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={21}
                  controller={controller}
                  name="Color"
                  label="Colour"
                />
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={22}
                  controller={controller}
                  label="Modified"
                  name="Modified"
                />
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={23}
                  controller={controller}
                  label="Latex"
                  name="Latex"
                />
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={24}
                  controller={controller}
                  label="Fiber"
                  name="Fiber"
                />
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={25}
                  controller={controller}
                  label="Swap Hoppers"
                  name="SwapAggregate"
                />
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={26}
                  controller={controller}
                  label="Preblend"
                  name="Preblend"
                />
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <CheckFormField
                  id={27}
                  controller={controller}
                  label="Orderable"
                  name="Orderable"
                />
              </Grid>
              <Grid item xs={12}><div style={{height: '3em'}}/></Grid>
            </Grid>
          </Grid>

          <Grid item xs={10} style={{ display: 'flex'}}>
          <Button href='' onClick={close}>Cancel</Button>
            <Button href='' type='submit'><span style={{ color: 'dodgerblue'}}>Save</span></Button>
          </Grid>

          { props.mixture.length === 2 && <Grid item xs={2}>
            <Button href='' onClick={() => {
              window.firebase.database()
              .ref(`${company.val().url}/Mixtures/${props.mixture[0]}/Deprecated`)
              .set(!state.Deprecated)
              .finally(close);
            }}>
              <span className='error'>
                { state.Deprecated || "Archive" }
                { state.Deprecated && "Unarchive" }
              </span>
            </Button>
          </Grid>}

         

         
        </Grid>
      </form>
    </Paper>
  )
};

const MixDesigner = (props) => {
  const visible = props.mixture !== null;
  if (!visible) return <div/>;
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow in={visible} mountOnEnter unmountOnExit>
        <div className='dialog center'>
          <MixDesignerView mixture={props.mixture}/>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect((state) => ({ mixture: state.mixture }))
)(MixDesigner);

