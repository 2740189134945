import React from 'react';
import {LinearProgress} from "@material-ui/core";
import {connect } from "react-redux";
import {LoginPage} from "../views/LoginPage";

export const Loading = connect((x) => ({ auth: x.authentication }))((props) => {
  switch (props.auth) {
    case 'AUTHENTICATED': return props.children;
    case 'NO_USER_SPECIFIED': return <LoginPage />;
    default:  return <LinearProgress color='secondary'/>;
  }
});
