import {useUserPreferences} from "../providers/Firebase";
import {InputAdornment, TextField} from "@material-ui/core";
import React from "react";

export const AmountField = (props) => {
  const metric = useUserPreferences().metric ?? true;

  const { end, conversion, value, onChange, ...textFieldProps } = props;

  const format = (x) => x && Number(Number(x).toFixed(2));
  const imperial = (x) => x && (x * conversion);

  return (
    <TextField
      {...textFieldProps}
      value={format(  metric ? value : imperial(value)) ?? ''}
      type='number'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {end[metric ? 0 : 1]}
          </InputAdornment>
        )
      }}
      onChange={(e) => {
        const value = e.target.value;
        onChange && onChange(metric ? value : value / conversion)
      }}
    />
  )
};