import {useDispatch} from "react-redux";
import {updateUser} from "../actions/authentication";
import {updateCompany} from "../actions/company";
import {auth, db} from "./Firebase";

const handleAuthState = (dispatch) => async (userAuth) => {

  if (userAuth === null) {
    dispatch(updateUser(null));
    return;
  }

  const userData = await db().ref(`Users/${userAuth.uid}`).once('value');

  if (!userData.exists() || !userData.val().Company) {
    dispatch(updateUser(null));
    return;
  }

  const companyData = await db().ref(`Companies/${userData.val().Company}`).once('value');

  if (!companyData.exists()) {
    dispatch(updateUser(null));
    return;
  }

  dispatch(updateCompany(companyData));
  dispatch(updateUser(userData));
};

const UserProvider = (props) => {

  const dispatch = useDispatch();

  auth().onAuthStateChanged(handleAuthState(dispatch));

  return props.children;
};

export default UserProvider;
