import {FunctionComponent, useEffect, useRef, useState} from "react";
import firebase from "firebase/app";
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useObject} from "react-firebase-hooks/database";
import { company_db as db, db as root } from "../../database/Firebase";
import {Admix, Volume, Water, Weight} from "../../components/DisplayUnits";
import moment from "moment";
import {ArrowRight} from "@material-ui/icons";
import { useUserPreferences } from "../../providers/Firebase";
import {useDialogs} from "../../providers/dialogs";

interface  TicketProps {
  in: firebase.database.DataSnapshot;
  onClose: () => void
}

const GenerateTicketPDF = 'https://us-central1-fd-remote.cloudfunctions.net/GenerateTicketPDF'

const DownloadSelectorPopper = ({ selected, onClickAway, onSelect, open, anchorEl }) => {

  const render = ({ TransitionProps }) => {
    return (
      <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }} >
        <Paper>
          <ClickAwayListener onClickAway={onClickAway}>
            <MenuList className='focus:outline-none'>
              { selected === 1 && <MenuItem onClick={() => onSelect(0)}>.PDF</MenuItem> }
              { selected === 0 && <MenuItem onClick={() => onSelect(1)}>.JSON</MenuItem> }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )
  }

  return <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>{render}</Popper>
}

const EmailToast = ({ status, onClose }) => {

  const ref = useRef()
  const visible = !!status;

  const success = (status ?? ref.current) === 'ok'

  ref.current = status;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={visible}
      autoHideDuration={6000}
      onClose={onClose}
      message={success ? "Email has been sent" : "Email couldn't be sent"}
    />
  )
};

export const TicketDownloadButton = ({ archive }): FunctionComponent => {

  const [ open, setOpen ] = useState(false);
  const [ select, setSelect ] = useState(0);
  const anchor = useRef(null);

  const toggleOpen = () => setOpen((open) => !open);

  const [ showEmailDialog, setShowEmailDialog ] = useState();
  const [ showSnackBar, setShowSnackBar ] = useState();
  const [ email, setEmail ] = useState('');

  const units = (useUserPreferences().metric ?? true) ? 'metric' : 'imperial';
  const time_offset = moment().utcOffset() / 60;

  const onSendEmail = () => {
    setShowEmailDialog(false);


    console.log({
      to: email,
      subject: "A file has been shared with you",
      company: window.application.company_key,
      text: `Your ticket is ready!\n` +
        `https://us-central1-fd-remote.cloudfunctions.net/${GenerateTicketPDF}` +
        `/${window.application.company_url}/${archive}`
    });

    fetch('https://us-central1-fd-remote.cloudfunctions.net/SendEmail', {
      method: "POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        subject: "A file has been shared with you",
        company: window.application.company_key,
        html: `Your ticket is ready!\n` +
        `${GenerateTicketPDF}/${window.application.company_url}/${archive}?units=${units}&time_offset=${time_offset}`
      })
    }).then(e => e.json())
      .then(e => (console.log(e),e.status))
      .then(e => (console.log(e),setShowSnackBar(e)))
      .catch(e => (console.log(e),setShowSnackBar('bad')))
  }

  return (
    <>
      <Dialog open={showEmailDialog} maxWidth='md'>
        <Box padding='1em' width='30em'>
          <Typography variant='h6' component='div' className='flex'>
            Share via Email
          </Typography>
          <Box height='1em'/>
          <TextField
            fullWidth
            color='secondary'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
          />
          <Box display='flex'>
            <Box flexGrow={1} />
            <Button onClick={() => setShowEmailDialog(false)}>Cancel</Button>
            <Button onClick={onSendEmail}>Send</Button>
          </Box>
        </Box>
      </Dialog>
      <EmailToast status={showSnackBar} onClose={() => setShowSnackBar()} />
      <ButtonGroup variant='contained' color='secondary' disableElevation>
        <Button onClick={() => setShowEmailDialog(true)}>
          Email
        </Button>
        <Button href={`${GenerateTicketPDF}/${window.application.company_url}/${archive}?units=${units}&time_offset=${time_offset}`}>
          Download
        </Button>
        <Button ref={anchor} onClick={toggleOpen}>
          { [ ".PDF", ".JSON" ][select]  }
        </Button>
      </ButtonGroup>
      <DownloadSelectorPopper
        selected={select}
        anchorEl={anchor.current}
        open={open}
        onSelect={(e) => setOpen(false) & setSelect(e)}
        onClickAway={toggleOpen}
      />
    </>
  )
}

const ArchiveBody = ({ snapshot }): FunctionComponent => {

  if (!snapshot) return <></>

  const ticket = snapshot.val()

  const units = ticket.ARCHIVE_UNITS

  const started = new Date(
    ticket.ARCHIVE_START_YEAR,
    ticket.ARCHIVE_START_MONTH - 1, //FOR SOME FUCKING REASON JS MONTHS START AT 0 WTF
    ticket.ARCHIVE_START_DAY,
    ticket.ARCHIVE_START_HOUR,
    ticket.ARCHIVE_START_MIN,
  );

  const completed = new Date(
    ticket.ARCHIVE_STOP_YEAR,
    ticket.ARCHIVE_STOP_MONTH - 1, //FOR SOME FUCKING REASON JS MONTHS START AT 0 WTF
    ticket.ARCHIVE_STOP_DAY,
    ticket.ARCHIVE_STOP_HOUR,
    ticket.ARCHIVE_STOP_MIN,
  );

  let hasCustomerInfo = (ticket.Customer_Address !=null);
  return (
    <DialogContent style={{ height: 550 }}>
      <Typography variant='h6' component='div' className='flex'>
        Ticket #{ticket.ARCHIVE_TICKET_NUMBER ?? 999999}
        <div className='flex-grow' />
        <TicketDownloadButton archive={snapshot.key}/>
      </Typography>
      <div className='h-4'/>
      <Grid container spacing={2} justify='space-between'>
        <Grid item>
        {hasCustomerInfo && <Typography variant='overline' visible='hidden'>
            Customer details
          </Typography>}
           {hasCustomerInfo && <Typography>
            <strong>Customer Name: </strong>
            {ticket.Customer_First_Name} {ticket.Customer_Last_Name}<br/>
            <strong>Customer Email: </strong>{ticket.Customer_Email}<br/>
            <strong>Customer Address: </strong>{ticket.Customer_Address}<br/>
          </Typography>
        }

          <Typography variant='overline'>
            ticket details
          </Typography>
          <Typography>
            <strong>Date: </strong>
            {moment(started).format('LLL')}<ArrowRight/>{moment(completed).format('LLL')}<br/>
            <strong>Uploaded by: </strong>{ticket.OPERATOR_NAME}<br/>
            <strong>Dispensed By: </strong>{ticket.TRUCK_NAME ?? "No Name Provided"}<br/>
            <strong>Mixture: </strong>{ticket.ARCHIVE_MIX_NAME ?? "No Name Provided"}<br/>
            <Typography variant='overline'>Additional Notes</Typography><br/>{ticket.ARCHIVE_ADDITIONAL_NOTES?? ""}
            { ticket.ARCHIVE_SIGNATURE_PATH && <img className='h-48' src={ticket.ARCHIVE_SIGNATURE_PATH} alt=""/> }
          </Typography>
        </Grid>
        <Grid item className='w-64'>
          <Typography variant='overline'>
            material dispensed
          </Typography>
          <Typography>
            <strong>Volume: </strong>{ticket.ARCHIVE_VOLUME / 100} { units ? ' YD³' : 'M³'  }<br/>
            <strong>Cement: </strong>{ticket.ARCHIVE_CEMENT_AMOUNT / 10} { units ? 'LB' : 'KG' }<br/>
            <strong>Sand: </strong>{ticket.ARCHIVE_SAND_AMOUNT / 10} { units ? 'LB' : 'KG' }<br/>
            <strong>Stone: </strong>{ticket.ARCHIVE_STONE_AMOUNT / 10} { units ? 'LB' : 'KG' }<br/>
            <strong>Water: </strong>{ticket.ARCHIVE_WATER_AMOUNT / 10} { units ? 'GAL' : 'L' }<br/>
            <strong>Cement Water Ratio : </strong> {ticket.ARCHIVE_CEM_WATER_RATIO ? ticket.ARCHIVE_CEM_WATER_RATIO / 100 : 'N/A'} <br/>
            { ticket.ARCHIVE_ADMIX_AMT1 > 0 && <><strong>Admix 1: </strong>{ticket.ARCHIVE_ADMIX_AMT1} { units ? 'OZ' : 'ml' } <br/></>}
            { ticket.ARCHIVE_ADMIX_AMT2 > 0 && <><strong>Admix 2: </strong>{ticket.ARCHIVE_ADMIX_AMT2} { units ? 'OZ' : 'ml'  } <br/></>}
            { ticket.ARCHIVE_ADMIX_AMT3 > 0 && <><strong>Admix 3: </strong>{ticket.ARCHIVE_ADMIX_AMT3} { units ? 'OZ' : 'ml'  } <br/></>}
            { ticket.ARCHIVE_ADMIX_AMT4 > 0 && <><strong>Admix 4: </strong>{ticket.ARCHIVE_ADMIX_AMT4} { units ? 'OZ' : 'ml'  } <br/></>}
          </Typography>
        </Grid> 
        {/*<Grid item className='w-56'>*/}
        {/*  { Customer && <>*/}
        {/*    <Typography variant='overline'>*/}
        {/*      customer details*/}
        {/*    </Typography>*/}
        {/*    <Typography>*/}
        {/*      <strong>Company: </strong>{Customer.Company ?? "N/a"}<br/>*/}
        {/*      <strong>Contact: </strong>{Customer.Name.First} {Customer.Name.Last}<br/>*/}
        {/*      <strong>Phone: </strong>{Customer.Phone}<br/>*/}
        {/*    </Typography>*/}
        {/*  </> }*/}
        {/*</Grid>*/}
      </Grid>

      <div className='h-4'/>
      { ticket.ARCHIVE_PHOTOS && <Grid container spacing={2}>{
        ticket.ARCHIVE_PHOTOS.map((url, i) => {
          return (
            <Grid key={i} item xs={2}>
              <img src={url} alt='' />
            </Grid>
          )
        })
      }</Grid>}
    </DialogContent>
  )
}

export const useTicketDialog = () => {
 const [state, setState] = useDialogs();

return {
    show: async (job) => {
      return ArchiveBody(job);
    },
    close: () =>{}
  }
}

export const Ticket = (props: TicketProps): FunctionComponent => {
  return (
    <Dialog open={!!props.in} onClose={props.onClose} fullWidth maxWidth='md'>
      <ArchiveBody snapshot={props.in}/>
    </Dialog>
  );
}