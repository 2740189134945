import {
  red,
  yellow,
  orange,
  purple,
  green,
  lime,
  teal,
} from "@material-ui/core/colors";

export const getColor = (index: number) => {
  if (index < 0) return '#FFFFFF';

  const options = [
    red[500],
    yellow[500],
    orange[500],
    purple[500],
    green[500],
    lime[500],
    teal[500]
  ];

  return options[index % options.length ];
};