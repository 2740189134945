import React from 'react'
import { useDragLayer } from 'react-dnd'
import {OrderPreview} from "../components/OrderPreview";
import Grow from "@material-ui/core/Grow";

export const CustomDragLayer = () => {
  const { item, currentOffset, isOverTarget } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    isOverTarget: monitor.getTargetIds().map((x) => !!x ? monitor.isOverTarget(x) : false).includes(true)
  }));

  const { x, y } = currentOffset ?? { };

  return (
    <div style={{
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 1000000,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    }}>
      <div>
        <Grow
          in={!!item}
        >
          <div>
            <OrderPreview
              order={item?.order ?? [ '', { } ] }
              style={{
                top: y || -10000,
                left: x || -100000,
                position: 'fixed',
                zIndex: 99999,
                transform: `translate(-50%, -50%) scale(${isOverTarget ? 0.5 : 1})`,
                transition: 'transform .1s ease-in-out'
              }}
            />
          </div>
        </Grow>
      </div>
    </div>
  )
};