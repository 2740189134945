import {useHistory} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import Appbar from '../app_bar';
import {useStore} from "react-redux";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import {useCompanyUrl} from "../../providers/Firebase";

const useStyles = makeStyles((theme) => ({
  body: {
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(9),
  },
  grid: {
    padding: theme.spacing(1),
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const Cement = (props) => {
  const [ key, { Calibration } ] = props.cement;
  const [ material, setMaterial ] = useState();
  const [ calibration, setCalibration ] = useState();
  const company = useCompanyUrl();
  const classes = useStyles();
  useEffect(() => {
    const ref = window.firebase.database().ref(company);
    ref.child(`/Calibrations/${Calibration}`).once('value').then((x) => setCalibration(x.val()));
    ref.child(`/Materials/${key}/Name`).once('value').then((x) => setMaterial(x.val()));
  }, [  key, Calibration, company ]);

  const gpc = Object.entries((calibration||{}).Steps||[]).map(([ , { Weight, Counts } ]) => {
    return (Weight * 1000) / Counts;
  });

  const series = {
    labels: ['1', '2', '3', '4'],
    datasets: [
      gpc.length > 0 && {
        backgroundColor: 'dodgerblue',
        lineTension: 0,
        borderColor: 'dodgerblue',
        type: 'line',
        fill: false,
        data: [
          Number(Number(gpc[0].toFixed(2))),
          Number(Number(gpc[1].toFixed(2))),
          Number(Number(gpc[2].toFixed(2))),
          Number(Number(gpc[3].toFixed(2))),
        ],
      },
      gpc.length > 4 && {
        backgroundColor: 'dodgerblue',
        lineTension: 0,
        borderColor: 'dodgerblue',
        type: 'line',
        fill: false,
        data: [
          Number(Number(gpc[4].toFixed(2))),
          Number(Number(gpc[5].toFixed(2))),
          Number(Number(gpc[6].toFixed(2))),
          Number(Number(gpc[7].toFixed(2))),
        ]
      }
    ].filter((x) => typeof(x) === 'object')
  };

  return (
    <Grid item xs={6}>
      <Paper className={classes.paper}>
        <Typography variant='h6'>
          Cement: { material }
        </Typography>
        <Typography variant='subtitle1'>
          Calibrated on: {moment((calibration||{}).CreatedOn).format("LLL")}
      </Typography>
        <Line
          height={300}
          options={{
            legend: {
              display: false,
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
            },
            maintainAspectRatio: false,
          }}
          data={series}
        />
      </Paper>
    </Grid>
  )
};

const Aggregate = (props) => {
  const [ key, { Calibration } ] = props.aggregate;
  const [ material, setMaterial ] = useState();
  const [ calibration, setCalibration ] = useState();
  const company = useCompanyUrl();
  const classes = useStyles();
  useEffect(() => {
    const ref = window.firebase.database().ref(company);
    ref.child(`/Calibrations/${Calibration}`).once('value').then((x) => setCalibration(x.val()));
    ref.child(`/Materials/${key}/Name`).once('value').then((x) => setMaterial(x.val()));
  }, [ key, Calibration, company ]);

  const gpc = Object.entries((calibration||{}).Steps||[]).map(([ , { Weight, Counts } ]) => {
    return Weight / Counts;
  });

  const series = {
    labels: props.titan ? ['2', '4', '6', '8', '10'] : ['3', '5', '7', '9', '11'],
    datasets: [
      {
        backgroundColor: 'dodgerblue',
        lineTension: 0,
        borderColor: 'dodgerblue',
        type: 'line',
        fill: false,
        data: [
          Number(Number((gpc.splice(0, 3).reduce((a, b) => a + b, 0) / 4) * 1000).toFixed(2)),
          Number(Number((gpc.splice(0, 3).reduce((a, b) => a + b, 0) / 4) * 1000).toFixed(2)),
          Number(Number((gpc.splice(0, 3).reduce((a, b) => a + b, 0) / 4) * 1000).toFixed(2)),
          Number(Number((gpc.splice(0, 3).reduce((a, b) => a + b, 0) / 4) * 1000).toFixed(2)),
          Number(Number((gpc.splice(0, 3).reduce((a, b) => a + b, 0) / 4) * 1000).toFixed(2)),
        ]
      },
]
  };

  return (
    <Grid item xs={6}>
      <Paper className={classes.paper}>
        <Typography variant='h6'>
          { props.name }: { material }
        </Typography>
        <Typography variant='subtitle1'>
          Calibrated on: {moment((calibration||{}).CreatedOn).format("LLL")}
        </Typography>
        <Line
          height={200}
          data={series}
          options={{
            legend: {
              display: false,
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
            },
            chart: {
              toolbar: {
                show: false
              }
            },
            xaxis: {
              categories: [ '3', '5', '7', '9', '11' ],
              title: {
                text: 'Gate'
              }
            },
          }}
        />
      </Paper>
    </Grid>
  )
};

const Cements = (props) => {
  return (
    <>{
      Object.entries(props.cements || {}).map(
        (cement, i) => {
          return (
            <Cement key={i} cement={cement}/>
          )
        }
      )
    }</>
  );
};

const Aggregates = (props) => {
  return (
    <>{
      Object.entries(props.aggregates || {}).map(
        (aggregate, i) => {
          return (
            <Aggregate name={props.name} key={i} aggregate={aggregate} titan={props.titan}/>
          )
        }
      )
    }</>
  );
};

const User = (props) => {
  const [ name, setName ] = useState();
  const { firebase } = useStore().getState();
  useEffect(() => {
    window.firebase.database().ref(`Users/${props.user}/Name`).once('value').then((snap) => setName(snap.val()));
  }, [ firebase, props.user ]);
  return (
    <span>{name}</span>
  )
};

const Body = (props) => {
  const { Name, Operator, Type } = props.state;
  const classes = useStyles();
  const [ clutch, setClutch ] = useState(50);
  const [ displayType, setDisplayType ] = useState(0);
  return (
    <div className={classes.body}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant='h6'>
              Truck: { Name }
            </Typography>
            <Typography variant='subtitle1'>
              Last Connected Operator: {<User user={Operator}/>}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid container justify='space-between' spacing={2}>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Display Type"
                  value={displayType}
                  fullWidth
                  onChange={(e) => setDisplayType(e.target.value)}
                >
                  <MenuItem value={0}>Raw Sensor Counts</MenuItem>
                  <MenuItem value={1}>Feed Conveyor Counts</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  xs={6}
                  select
                  label="Clutch Setting"
                  fullWidth
                  value={clutch}
                  onChange={(e) => setClutch(e.target.value)}
                >
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={25}>25%</MenuItem>
                  <MenuItem value={50}>50%</MenuItem>
                  <MenuItem value={75}>75%</MenuItem>
                  <MenuItem value={90}>90%</MenuItem>
                  <MenuItem value={100}>100%</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Cements cements={props.state.Cements} titan={Type === 'HARDOX'}/>
        <Aggregates name="Stone" aggregates={props.state.Stones} titan={Type === 'HARDOX'}/>
        <Aggregates name="Sand" aggregates={props.state.Sands} titan={Type === 'HARDOX'}/>
      </Grid>
    </div>
  )
};

const State = (props) => {
  const { firebase, company } = useStore().getState();
  const [ state, setState ] = useState();
  useEffect(() => {
    window.firebase.database().ref(`${company.val().url}/Trucks/${props.truck}`).once('value').then(
      (snap) => setState(snap.val())
    );
  }, [company, firebase, props.truck]);
  if (!state) return <div/>;
  return <Body state={state}/>
};


const Truck = () => {
  const history = useHistory();
  const key = history.location.search.replace("?=" , '');
  return (
      <main>
        <State truck={key}/>
        <Appbar title="Truck Calibration Status"/>
      </main>
  );
};

export default Truck;