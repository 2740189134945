import moment from "moment";

const initial = { start: moment().utc().toISOString(), end: moment().utc().toISOString() };

const dispatchDateRangeReducer = (state = initial, action) => {
  switch (action.type) {
    case 'DISPATCH_UPDATE_START': return { ...state, start: action.payload };
    case 'DISPATCH_UPDATE_END': return { ...state, end: action.payload };
    default: return state;
  }
};

export default dispatchDateRangeReducer;
