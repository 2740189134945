import React, {createContext, useContext, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useToolbarState} from "../views/ScheduleDay/schedule_toolbar";
import Paper from "@material-ui/core/Paper";
import {DatePicker} from "@material-ui/pickers";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Grow from "@material-ui/core/Grow";

const DateFilterContext = createContext();

export const useDateFilter = () => useContext(DateFilterContext);

export const DateFilterProvider = ({ children }) => {
  const state = useState({});
  return (
    <DateFilterContext.Provider value={state}>
      {children}
    </DateFilterContext.Provider>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      zIndex: 2300,
      position: "fixed",
      top: theme.spacing(2),
      left: theme.spacing(18)
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2299,
    }
  }
});

export const DateFilterSelector = () => {
  const classes = useStyles();
  const [ filter, setFilter ] = useDateFilter();
  const [ { date }, setState ] = useToolbarState();
  const close = () => setState({ date: false });


  return (
    <>
      { !!date && <div onClick={close} className={classes.backdrop}/> }
      <Grow in={!!date} mountOnEnter unmountOnExit>
        <Paper className={classes.root}>
          <ThemeProvider theme={createMuiTheme({ palette: { primary: { main: '#FA9B1E' } }})}>
            <DatePicker
              orientation="landscape"
              color='secondary'
              variant='static'
              value={filter.day}
              onChange={(e) => setFilter({ day: e })}
            />
          </ThemeProvider>
        </Paper>
      </Grow>
    </>
  );
};