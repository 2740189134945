import React from 'react';
import {compose} from 'recompose';
import { connect } from "react-redux";
import {updateDispatchEnd, updateDispatchStart} from "../../actions/dispatch";
import { closeAll } from "../../actions/toolbar";
import { DatePicker } from '@material-ui/pickers'
import { Fade, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {useDateFilter} from "../../providers/date_filter";
import moment from 'moment';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      zIndex: 10000,
      position: 'fixed',
      top: theme.spacing(2),
      left: theme.spacing(18),
      height: 'fit-content',
      width: 'fit-content',
    }
  }
});

const DateRangeView = (props) => {
  const visible = props.tool === 'TIME';
  const classes = useStyles();
  const [ filter, setFilter ] = useDateFilter();
  return (
    <>
      { visible && <div
        onClick={props.close}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9000 }}
      /> }
      <Fade in={visible}>
        <Paper className={classes.paper}>
          <div>
            <DatePicker
              open={true}
              variant="static"
              value={filter.start}
              onChange={(e) => {
                setFilter({
                  start: moment(e).startOf('day'),
                  end: moment(e).endOf('day'),
                })
              }}
            />
          </div>
        </Paper>
      </Fade>
    </>
  );
};

export default compose(
  connect(
    (state) => ({
      tool: state.toolbarWindow,
      range: state.dispatchDateRange
    }),
    (dispatch) => ({
      close: () => dispatch(closeAll()),
      updateStart: (date) => dispatch(updateDispatchStart(date)),
      updateEnd: (date) => dispatch(updateDispatchEnd(date))
    })
  )
)(DateRangeView);
