import 'firebase/database';
import {useEffect, useMemo, useState} from "react";
import {useStore} from "react-redux";
import {db} from "../database/Firebase";

export const useFirebase = () => useStore().getState().firebase;
export const useCompanyUrl = () => useStore().getState().company.val().url;

//TODO add caching
const useList = (query: firebase.database.Reference) => {
  const [ state, setState ] = useState({ });

  useEffect(() => {
    let value = state;

    query.on('child_added', (snap) => {
      value = { ...value, [snap.key]: snap.val() };
      setState(value);
    });

    query.on('child_changed', (snap) => {
      value = { ...value, [snap.key]: snap.val() };
      setState(value);
    });

    query.on('child_removed', (snap) => {
      const change = Object.entries(value).filter(([ key]) => key !== snap.key);
      value = Object.fromEntries(change);
      setState(value);
    });
    return () => query.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ query ]);

  return state;
};

export const useUnAssignedOrdersList = () => {
  const company = useCompanyUrl();
  const query = useMemo(() => db().ref(`${company}/Orders`).orderByChild("assigned").equalTo(null), [ company ]);
  return useList(query);
};

export const useTrucksList = () => {
  const company = useCompanyUrl();
  const query = useMemo(() => db().ref(`${company}/Trucks`), [ company ]);
  return useList(query);
};

export const useMixtureList = () => {
  const company = useCompanyUrl();
  const query = useMemo(() => db().ref(`${company}/Mixtures`), [ company ]);
  return useList(query);
};

export const useCustomerList = () => {
  const company = useCompanyUrl();
  const query = useMemo(() => db().ref(`${company}/Customers`).orderByChild('Deprecated').equalTo(null), [ company ]);
  return useList(query);
};

export const useJobsList = (filter) => {
  const company = useCompanyUrl();
  const query =  useMemo(() => {
    let query = db().ref(`${company}/Jobs`);
    if (filter?.sort) query = query.orderByChild(filter.sort);
    if (filter?.start) query.startAt(filter.start);
    if (filter?.end) query.endAt(filter.end);
    return query;
  }, [ company, filter ]);
  return useList(query);
};



export const useUserPreferences = () => {
  const { user } = useStore().getState();
  const prefs = { ...(user?.val()?.Preferences ?? {}) };
  return {
    metric: prefs.metric ?? prefs.Metric
  };
};

export const useUserPermissions = () => {
  const { user } = useStore().getState();
  return { ...(user?.val()?.Permissions ?? {}) };
};

export const getCustomerByEmail = async (email) => {
  const results = await db().ref(`${window.application.company_url}/Customers`)
    .orderByChild('Email')
    .equalTo(email)
    .once('value');
  return Object.entries(results?.val() ?? {})[0] ?? [];
};

export const getMixtureByName = async (name) => {
  const results = await db().ref(`${window.application.company_url}/Mixtures`)
    .orderByChild('Name')
    .equalTo(name)
    .once('value');
  return Object.entries(results?.val() ?? {})[0] ?? [];
};