import React, {useEffect} from 'react';
import {useDialogs} from "../providers/dialogs";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getCustomerByEmail, getMixtureByName, useUnAssignedOrdersList} from "../providers/Firebase";
import {Typography, ExpansionPanel, ExpansionPanelSummary} from "@material-ui/core";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { useDrag } from 'react-dnd';
import {getEmptyImage} from "react-dnd-html5-backend";
import {useJobDialog} from "./JobEditor";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: '#EEE',
      position: 'fixed',
      left: theme.spacing(18),
      bottom: theme.spacing(2),
      zIndex: 2000,
      width: theme.spacing(100),
      height: theme.spacing(80),
      overflow: 'hidden',
    },
    scrollable: {
      position: 'absolute',
      overflow: 'auto',
      padding: theme.spacing(2),
      paddingTop: 0,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    list: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    container: {
      position: 'relative',
      padding: theme.spacing(2),
      width: '100%',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'lightgray',
      borderRadius: theme.spacing(1),
    },
    bottom_margin: {
      marginBottom: theme.spacing(4)
    },
    container_label: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      background: 'white',
      position: 'absolute',
      top: theme.spacing(-2),
      left: theme.spacing(2)
    },
    inset: {
      position: 'relative',
      left: theme.spacing(1)
    },
    details: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
    notes: {
      minHeight: theme.spacing(10),
    },

    action: {
      color: 'dodgerblue'
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1999
    },
    no_order: {
      margin: 'auto',
      position: 'absolute',
      top: theme.spacing(10),
      bottom: 0,
      right: 0,
      left: 0,
    },
    gap: {
      height: theme.spacing(10),
    }
  }
});

export const useOrdersDialog = () => {
  const [state, setState] = useDialogs();
  return {
    show: () => setState({...state, orders: true}),
    close: () => setState({...state, orders: undefined}),
    toggle: () => setState({...state, orders: state.orders ? undefined : true}),
    isOpen: !!state.orders,
  }
};

const Order = ({ order, index }) => {
  const { isOpen, close  } = useOrdersDialog();
  const job_dialog = useJobDialog();
  const [ key, value ] = order;
  const [ , drag, preview ] = useDrag({
    item: { order: order, type: 'order' },
    collect: (monitor) => { if (monitor.isDragging() && isOpen) close(); },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <ExpansionPanel
      expandIcon={<ExpandMoreIcon />}
      key={index}
      className={classes.order}
      ref={drag}
    >
      <ExpansionPanelSummary
        id={key}
        expandIcon={<ExpandMoreIcon />}
      >
        <div>
          <Typography variant='h6'>
            {value?.Customer?.Name ?? 'No Name Provided'}
          </Typography>
          <Typography>
            <strong>Requested For: </strong>
            { value.Start ? moment(value?.Start).format("LLL") : 'Time was not provided'} <br/>
            <strong>Delivery Location: </strong>{value?.Address?.LongName ?? 'Location was not provided'}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.details}>
          <div className={classes.container + " " + classes.bottom_margin}>
            <div className={classes.container_label}>
              <Typography variant="overline">
                Customer Contact Information
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>
                  <strong className={classes.inset}>Email</strong><br/>
                  <span>{value.Customer.Email ?? 'No email provided'}</span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong className={classes.inset}>Phone</strong><br/>
                  <span>{value.Customer.Phone ?? 'No phone provided'}</span>
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.container  + " " + classes.bottom_margin}>
            <div className={classes.container_label}>
              <Typography variant="overline">
                Requested Product
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>
                  <strong className={classes.inset}>Mixture</strong><br/>
                  <span>{value?.Product?.Name ?? 'No mixture provided'}</span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong className={classes.inset}>Amount</strong><br/>
                  <span>{value?.Product?.Amount ?
                    <span>{Number(Number(value?.Product?.Amount * 1.30795).toFixed(2))} YD
                    <sup>3</sup>
                  </span> : 'No Amount Provided' }</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong className={classes.inset}>Application</strong><br/>
                  <span>{value.Customer.Application ?? 'No application provided'}</span>
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.container + " " + classes.bottom_margin}>
            <div className={classes.container_label}>
              <Typography variant="overline">
                Application
              </Typography>
            </div>
            <div>
              <Typography >
                {value.Application ?? 'No application provided.'}
              </Typography>
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.container_label}>
              <Typography variant="overline">
                Additional Note & Instructions
              </Typography>
            </div>
            <div className={classes.notes}>
              <Typography >
                {value.Notes ?? 'No additional delivery instructions were provided.'}
              </Typography>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          onClick={async () => {
            await job_dialog.show([ undefined, {
              Order: key,
              Start: value?.Start,
              Customer: (await getCustomerByEmail(value?.Customer?.Email))[0],
              Mixture: (await getMixtureByName(value?.Product?.Name))[0],
              Amount: value?.Product?.Amount,
              Address: value.Address,
              Notes: value.Notes
            } ]);
          }}
          size='small'
        >
          <span className={classes.action}>
            Create Job
          </span>
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  )
};

const NoOrders = () => {
  const classes = useStyles();
  return (
    <div className={classes.no_order}>
      <Typography variant='h4' align='center'>
        <strong>You're all caught up!</strong>
      </Typography>
      <Typography variant='body1' align='center'>
        Once an order is submitted it will be visible here
      </Typography>
    </div>
  )
};

const Orders = () => {
  const orders = useUnAssignedOrdersList();
  const mapToOrder = (order, index) => {
    return (<Order order={order} index={index} />);
  };
  const filtered = Object.entries(orders ?? {});

  if (filtered.length === 0) return <NoOrders/>;
  else return filtered.map(mapToOrder);
};

export const OrdersDialog = () => {
  const { isOpen, close } = useOrdersDialog();
  const classes = useStyles();
  return (
    <>
      { isOpen && <div
        className={classes.backdrop}
        onClick={() => close()}
      />}
      <Grow
        in={isOpen}
        mountOnEnter
      >
        <Paper
          elevation={4}
          className={classes.root}
          onMouseOver={(e) => { e.target.focus(); }}
        >
          <Toolbar>
            <Typography variant='h6'>
              Orders
            </Typography>
          </Toolbar>
          <div className={classes.list}>
            <div className={classes.scrollable}>
              <Orders/>
              <div className={classes.gap}/>
            </div>
          </div>
        </Paper>
      </Grow>
    </>
  )
};