import {connect, useDispatch, useStore} from "react-redux";
import {compose} from 'recompose';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import FileCopy from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";

const reversed = (array) => {
  array.reverse();
  return array;
};

const MixtureList = (props) => {
  const dispatch = useDispatch();
  const { company } = useStore().getState();
  const handle = (payload) => () => dispatch({ type: "MIXTURE_POPUP_SET", payload: payload });
  return (
    <List component='div'>
      <ListItem component='div' button onClick={handle([])}>
        <ListItemText primary={<span style={{ color: 'dodgerblue'}}>+ Create New</span>}/>
      </ListItem>
      {reversed(Object.entries(props.mixtures)
      .filter((x) => props.showDeprecated || !x[1]["Deprecated"])).map(
        ([ key, material ], i) => {
          return (
            <div key={i} className='hover-icon'>
              <ListItem component='div' button onClick={handle([ key, material ])}>
                <ListItemText primary={material["Name"]}/>
                <ListItemSecondaryAction id='showOnHover'>
                  <Tooltip title="Duplicate">
                    <IconButton onClick={() => {
                      window.firebase.database().ref(`${company.val().url}/Mixtures`).push().set({
                        ...material,
                        Name: material.Name + " - Copy"
                      }).finally()
                    }} href=''>
                      <FileCopy/>
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          )
        }
      )
    }</List>
  )
};

export default compose(
  connect((state) => ({ mixtures: state.mixtures }))
)(MixtureList)
