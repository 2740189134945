import {FunctionComponent, useContext, createContext} from "react";
import Appbar from "../app_bar";
import { useState } from 'react';
import {ArchiveTable, ArchiveTableBodySection} from "./table";
import {Ticket} from "./ticket";
import { Button } from "@material-ui/core";

const ArchivePageContext = createContext();

export const useArchivePageContext = () => useContext(ArchivePageContext);

const PageList = (): FunctionComponent => {

  return (
    <>
      <div className='ml-2'>
        <main className='ml-16 pt-16 mb-10'>
          <ArchiveTable>
            <ArchiveTableBodySection/>
          </ArchiveTable>
        </main>
      </div>
    </>
  );
}


export const ArchivePage = (): FunctionComponent => {

  const [ focused, setFocused ] = useState();

  return (
    <ArchivePageContext.Provider value={{ setFocused }}>
      <Appbar title="Ticket Archive">
        <Button
          href={'https://us-central1-fd-remote.cloudfunctions.net/ExportTicketArchiveCSV?company=' + window.application.company_url}
        >Download All</Button>
      </Appbar>
      <PageList/>
      <Ticket in={focused} onClose={() => setFocused(null)} />
    </ArchivePageContext.Provider>
  )
}