import {combineReducers} from "redux";
import assignmentsReducer from "./assignmentsReducer";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import toolbarReducer from "./toolbarReducer";
import dispatchDateRangeReducer from "./dispatchDateRangeReducer";
import snapshotListReducer from "./snapshotListReducer";
import callbackReducer from "./callbackReducer";
import mapFocusReducer from "./mapFocusReducer";
import mixtureReducer from "./mixtureReducer";
import materialReducer from "./materialReducer";
import customerReducer from "./customerReducer";
import popUpReducer from "./popUpReducer";
import userSettingsReducer from "./userSettingsReducer";

const reducers = combineReducers({
  mapFocus: mapFocusReducer,
  orders: snapshotListReducer("ORDERS"),
  users: snapshotListReducer("USERS"),
  companies: snapshotListReducer("COMPANIES"),
  jobs: snapshotListReducer("JOBS"),
  customers: snapshotListReducer("CUSTOMERS"),
  trucks: snapshotListReducer("TRUCKS"),
  mixtures: snapshotListReducer("MIXTURES"),
  materials: snapshotListReducer("MATERIALS"),
  archive: snapshotListReducer("ARCHIVES"),
  customerSelectionCallback: callbackReducer("CUSTOMER_SELECTION"),
  truckSelectionCallback: callbackReducer("TRUCK_SELECTION"),
  mixSelectionCallback: callbackReducer("MIX_SELECTION"),
  addressSelectionCallback: callbackReducer("ADDRESS_SELECTION"),
  popupCompany: popUpReducer("COMPANY"),
  account: popUpReducer("ACCOUNT"),
  mixture: mixtureReducer,
  material: materialReducer,
  customer: customerReducer,
  assignments: assignmentsReducer,
  user: userReducer,
  company: companyReducer,
  authentication: authReducer,
  order: orderReducer,
  toolbarWindow: toolbarReducer,
  dispatchDateRange: dispatchDateRangeReducer,
  userSettingPopup: userSettingsReducer,
});

export default reducers;
