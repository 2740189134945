import React, {useState} from 'react';
import compose from "recompose/compose";
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch, useStore} from "react-redux";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {ChangePasswordDialog} from "../Dialogs/ChangePasswordDialog";

const LabeledCheckbox = (props) => {
  return (
    <Tooltip
      title={props.info}
      placement={"right"}
    >
      <FormControlLabel
        style={{ margin: 0 }}
        control={
          <Checkbox {...{ ...props, label: undefined }} />
        }
        label={props.label}
      />
    </Tooltip>
  );
};

const getDefaultState = (x) => {
  if (!x || x.length < 2) return {
    Password: (Math.random() * 10000).toString(36).replace(/([^A-z0-9-])+/g, '')
  };
  if (!x[0]) {
    return {
      ...x[1],
      Password: (Math.random() * 10000).toString(36).replace(/([^A-z0-9-])+/g, '')
    }
  }
  return x[1];
};

const View = (props) => {
  const [ state, setState ] = useState(getDefaultState(props.account));
  const [ saving, setSaving ] = useState(false);
  const [ error, setError ] = useState();
  const [ reset, setReset ] = useState(false);
  const { user } = useStore().getState();
  const dispatch = useDispatch();
  const handle = (e) => setState({ ...state, [e.target.name]: e.target.value });
  const close = () => dispatch({ type: "ACCOUNT_POPUP_CLOSE" });
  const handlePerm = (e) => setState({ ...state, Permissions:  { ...state.Permissions, [e.target.value]: e.target.checked } });
  const perms = state["Permissions"] || {};

  return (
    <Paper style={{ minWidth: '50em', padding: '1em', paddingBottom: '0.5em' }}>
      <Grid container spacing={2} justify='flex-start' alignContent='flex-start'>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="Email"
                label="Email"
                error={!!error}
                helperText={error}
                value={state["Email"] || ''}
                disabled={props.account.length > 1 && !!props.account[1].Email}
                onChange={(e) => {
                  setError(null);
                  handle(e)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Name"
                label="Name"
                value={state["Name"] || ''}
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Phone"
                label="Phone"
                value={state["Phone"] || ''}
                onChange={handle}
                fullWidth
              />
            </Grid>
            { (!props.account || props.account.length < 2 || !props.account[0]) && <Grid item xs={12}>
              <TextField
                name="Password"
                label="Default Password"
                value={state["Password"] || ''}
                onChange={handle}
                fullWidth
              />
            </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12} style={{display: "flex"}}>
              <Typography variant='overline'>
                Permissions
              </Typography>
            </Grid>
            { user && user.Permissions && user.Permissions.Admin && <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.Admin}
                onChange={handlePerm}
                label="Admin"
                value="Admin"
                info="Access to all company accounts"
              />
            </Grid>}
            <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.Dispatch}
                onChange={handlePerm}
                label="Dispatch"
                value="Dispatch"
                info="Access web application"
              />
            </Grid>
            <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.Accounts}
                onChange={handlePerm}
                label="Accounts"
                value="Accounts"
                info="Access user accounts"
              />
            </Grid>
            <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.Operator}
                onChange={handlePerm}
                label="Operator"
                value="Operator"
                info="Access mobile application"
              />
            </Grid>
            <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.DisableMixEditing}
                onChange={handlePerm}
                label="Disable Mix Editing"
                value="DisableMixEditing"
                info="Disable Access to Mix Editing"
              />
            </Grid>
            <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.DisableCalibrationEditing}
                onChange={handlePerm}
                label="Disable Calibration Editing"
                value="DisableCalibrationEditing"
                info="Disable Access to Calibration Editing"
              />
            </Grid>
            <Grid item xs={12} style={{display: "flex"}}>
              <LabeledCheckbox
                checked={perms.ViewUnassignedJobs}
                onChange={handlePerm}
                label="View Unassigned Jobs"
                value="ViewUnassignedJobs"
                info="Allow the user to view view unassigned jobs in app"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify={"space-between"}>
            <Grid item style={{ display: 'flex' }}>
              <Button href='' onClick={close}>
                Cancel
              </Button>
              <Button href='' disabled={saving} onClick={async () => {
                try {
                  setSaving(true);
                  let ref = window.firebase.database().ref(`Users/`);
                  if (props.account.length === 0 || props.account[0] === null) {
                    const create = window.firebase.functions().httpsCallable("createUser");
                    const result = await create({email: state.Email, password: state.Password});
                    ref = ref.child(result.data.uid);
                  } else ref = ref.child(props.account[0]);
                  await ref.update({...state, Password: null}).finally(close);
                } catch (e) {
                  console.log(e);
                  setSaving(false);
                  setError("Email Already taken");
                }
              }}><span style={{ color: 'dodgerblue'}}>Save</span></Button>
              { saving && <CircularProgress
                style={{
                  padding: '0.3em',
                  color: 'dodgerblue'
                }}
                size={24}
              />}
            </Grid>
            { props.account.length === 2 && !!props.account[0] && <Grid item>
              <Button href='' onClick={() => setReset(true)}>
                <span className='error'>Reset Password</span>
              </Button>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
      <ChangePasswordDialog open={reset} user={props.account[0]} onComplete={() => setReset(false)}/>
    </Paper>
  )
};

const AccountEditor = (props) => {
  const visible = props.account !== null;
  if (!visible) return <div/>;
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow in={visible} mountOnEnter unmountOnExit>
        <div className='dialog center'>
          <View type={props.type} account={props.account}/>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect((state) => ({ account: state.account }))
)(AccountEditor);

