import React from 'react';
import './styles/app.css'
import MomentUtils from '@date-io/moment';
import {Provider} from "react-redux";
import { Loading } from './components/Loading';
import UserProvider from "./database/user_provider";
import Theme from "./theme";
import {OrderPageRouting} from "./views/ordering";
import JobsPage from './views/jobs_page';
import Login from './views/login';
import Dispatch from './views/dispatch';
import MixDesign from './views/mix_design';
import CustomersPage from './views/customers_page';
import AccountsPage from './views/accounts_page';
import UserSettingsPopup from './views/UserSettings';
import Truck from "./views/truck";
import reducers from "./reducers";
import {createStore} from 'redux';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { ThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { Divider, Box } from "@material-ui/core";
import {DaySchedule} from "./views/ScheduleDay";
import {Dialogs} from "./providers/dialogs";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd'
import {CustomDragLayer} from "./providers/CustomDragLayer";
import {LayersProvider} from "./views/dispatch/visible_layers";
import { Navigation, PageRoute } from './components/navigation';
import { AppBranding } from './components/app_branding';
import {
  AssignmentLate,
  Assignment,
  Receipt,
  Extension,
  AccountBox,
  SupervisedUserCircle,
  Settings
} from "@material-ui/icons";
import { ArchivePage } from "./views/archive_page";
import CompanySettingsPage from "./views/company_settings";
import {useUserPermissions, useUserPreferences} from "./providers/Firebase";

const useStyles = makeStyles((theme) => {
  return {
    divider: {
      divider: {
        position: 'relative',
        width: '120%',
        right: theme.spacing(1),
      },
    }
  };
});

const Store = createStore(reducers);

const DrawerItems = () => {

  const { Accounts = false, Admin = false } = useUserPermissions();
  const classes = useStyles();


  return (
    <Navigation>
      <AppBranding/>
      <Divider className={classes.divider}/><br/>
      <PageRoute icon={<AssignmentLate/>} title='Map Overview' route="/"/>
      <PageRoute icon={<Assignment/>} title='Daily Assignment' route="/assignments"/>
      <PageRoute icon={<Receipt/>} title='Ticket Archive' route="/archive"/>
      <PageRoute icon={<Extension/>} title='Mixture Design' route="/mixtures"/>
      <PageRoute icon={<AccountBox/>} title='Customers' route="/customers"/>
      <Box height={16} />
      { (Accounts || Admin) && <>
        <Divider className={classes.divider}/><br/>
        <PageRoute icon={<SupervisedUserCircle/>} title='Account Management' route="/account"/>
        <PageRoute icon={<Settings/>} title='Company Settings' route="/settings"/>
      </>}
    </Navigation>
  )
}

export const App = () => {

  return (
    <BrowserRouter>
      <ThemeProvider theme={createMuiTheme(Theme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DndProvider backend={HTML5Backend}>
            <CustomDragLayer/>
            <Provider store={Store}>
              <OrderPageRouting>
                <UserProvider>
                  <Loading>
                    <LayersProvider>
                      <Dialogs>
                        <UserSettingsPopup/>
                        <DrawerItems/>
                        <Switch>
                          <Route exact path='/archive' component={ArchivePage}/>
                          <Route exact path='/jobs' component={JobsPage}/>
                          <Route exact path='/' component={Dispatch}/>
                          <Route exact path='/assignments' component={DaySchedule}/>
                          <Route exact path='/mixtures' component={MixDesign}/>
                          <Route exact path='/customers' component={CustomersPage}/>
                          <Route exact path='/account' component={AccountsPage}/>
                          <Route path={'/truck'} component={Truck}/>
                          <Route path={'/settings'} component={CompanySettingsPage}/>
                          <Route component={Login}/>
                        </Switch>
                      </Dialogs>
                    </LayersProvider>
                  </Loading>
                </UserProvider>
              </OrderPageRouting>
            </Provider>
          </DndProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
};