import React from "react";
import {useSchedule} from "./index";

export const Columns = () => {
  const { trucks } = useSchedule();
  return (
    <div style={{ position: 'absolute' }}>{
      [ null, ...Object.keys(trucks) ].map((_,i) => {
        return (
          <div key={i} style={{
            left: i * 250 + 185,
            top: 65,
            background: 'black',
            opacity: i % 2 === 0 ? 0 : 0.05,
            position: 'absolute',
            width: 250,
            height: 2410,
          }}/>
        )
      })
    }</div>
  )
};