import React, { createContext, useState, useContext } from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import {Badge, Theme,} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import {NoteAdd, CalendarToday, AllInbox} from "@material-ui/icons";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {useDateFilter} from "../../providers/date_filter";
import {useJobDialog} from "../../popups/JobEditor";
import {useUnAssignedOrdersList} from "../../providers/Firebase";
import {useOrdersDialog} from "../../popups/Orders";
import {CustomTooltip} from "../../components/CustomTooltip";

const ToolbarContext = createContext();

export const useToolbarState = () => useContext(ToolbarContext);

export const ToolbarStateProvider = ({ children }) => {
  const [ state, setState ] = useState({});
  const changeState = (change) => setState({ ...state, ...change });
  return (
    <ToolbarContext.Provider value={[ state, changeState ]}>
      {children}
    </ToolbarContext.Provider>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    background: theme.palette.secondary.main,
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: theme.spacing(17),
    zIndex: 1150,
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  icon: {
    color: 'white',
  },
  gap: {
    flexGrow: 1,
  }
}));

export const OrdersButton = () => {
  const classes = useStyles();
  const orders = useUnAssignedOrdersList();
  const dialog = useOrdersDialog();
  const count = Object.entries(orders).length;
  return (
    <IconButton href='' onClick={() => dialog.toggle()}>
      <Badge classes={{ badge: classes.badge }} badgeContent={count} max={20}>
        <AllInbox className={classes.icon}/>
      </Badge>
    </IconButton>
  )
};

const getRoundedTime = () => {
  const now = moment().clone();
  const minutes = now.minutes();
  return now
    .subtract(now.minutes(), "minutes")
    .add(Math.round( minutes / 15) * 15, "minutes");
}
export const ScheduleToolbar = () => {
  const classes = useStyles();

  const [ state, setState ] = useToolbarState();
  const job_dialog = useJobDialog();

  const [ { day } ] = useDateFilter();




  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.content}>
          <Typography variant='overline' className='text-white absolute'>
            {moment(day).format('MMM D')}
          </Typography>
          <div className='h-6'/>
          <CustomTooltip placement='right' title='Change Selected Date'>
            <IconButton onClick={state.date ? undefined : () => setState({ date: true })}>
              <CalendarToday className='text-white'/>
            </IconButton>
          </CustomTooltip>
          <div className='flex-grow'/>
          <CustomTooltip placement='right' title='Create New Job'>
            <IconButton onClick={() => job_dialog.show([ undefined, {
              Start: getRoundedTime().toISOString()
            } ])}>
              <NoteAdd className='text-white'/>
            </IconButton>
          </CustomTooltip>
          <CustomTooltip placement='right' title='Unassigned Orders'>
            <div>
              <OrdersButton />
            </div>
          </CustomTooltip>
        </div>
      </Paper>
    </>
  );
};
