import React, {useState} from 'react';
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import {TextField} from "@material-ui/core";
import {compose} from 'recompose';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";

const renderSuggestions = (getSuggestionItemProps, suggestions) => suggestions.map(
  (suggestion, i) => (
    <ListItem
      key={`maps-autofill-${i}`}
      {...getSuggestionItemProps(suggestion)}
    >
      <ListItemText
        primary={suggestion.description}
      />
    </ListItem>
  )
);


const handleRender = (props) => ({ getInputProps, getSuggestionItemProps, suggestions }) => (
  <div className="autocomplete-root">
    <TextField
      {...getInputProps()}
      {...props}
    />
    <div className='address-suggestions'>
      <List component='div'>{renderSuggestions(getSuggestionItemProps, suggestions)}</List>
    </div>
  </div>
);

const AddressSelect = (props) => {
  const visible = props.address !== null;
  const [ address, setAddress ] = useState('');
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow
        in={visible}
        unmountOnExit
        mountOnEnter
      >
        <div className='cover dialog center'>
          <Paper className='customer-select'>
            <Divider component='hr' className='address-select'/>
            <div className='address-select-field'>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
              >{handleRender({
                InputProps: {
                  disableUnderline: true,
                  placeholder: 'Enter an Address'
                },
                fullWidth: true,
                className: 'customer-select-search',
                type: 'search'
              })}</PlacesAutocomplete>
            </div>
            <CardActions className='customer-select-actions'>
              <Button size="small" href='' onClick={() => {
                setTimeout(() => setAddress(''), 500);
                props.cancel();
              }}>Cancel</Button>
              <div className='flex-grow'/>
              <Button size="small" href='' onClick={() => {
                setTimeout(() => setAddress(''), 500);
                const coder = new window.google.maps.Geocoder();
                coder.geocode({ 'address': address }, (results) => {
                  const result = results[0];
                  props.address({
                    LongName: address,
                    Local: address,
                    Street: address,
                    City: '',
                    Country: '',
                    PostCode: '',
                    Region: '   ',
                    Lat: result.geometry.location.lat(),
                    Lon: result.geometry.location.lng(),
                  });
                });
              }}>Save</Button>
            </CardActions>
          </Paper>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect(
    (state) => ({ address: state.addressSelectionCallback }),
    (dispatch) => ({
      cancel: () => dispatch({ type: "ADDRESS_SELECTION_CLEAR" })
    })
  )
)(AddressSelect);

