import React, {useState} from 'react';
import {compose} from 'recompose';
import Appbar from '../app_bar';
import {withSnapshotList} from "../../database/firebase_hoc";
import {connect} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import '../../styles/archive.css';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import FilterList from '@material-ui/icons/FilterList';
import List from "@material-ui/core/List";
import {CardContentDetails, CardContentSummary} from "./card_content";

const JobList = compose(
  connect((state) => ({ jobs: state.jobs }))
)((props) => Object.entries(props.jobs).filter(
  props.filter == null ? () => true : props.filter
).map(
  ([ key, value ], i) => {
    const [ openCard, setCard ] = props.controller;
    return (
      <ExpansionPanel
        key={`jobs-card-${i}`}
        expanded={openCard === i}
        onChange={() => setCard(i !== openCard ? i : null)}
      >
        <ExpansionPanelSummary href='' expandIcon={<ExpandMore/>}>
          <CardContentSummary job={value} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CardContentDetails job={value} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
));

const JobPage = () => {
  const [ filter ] = useState(null);
  const controller = useState(null);
  return (
    <main>
      <List component='div' className='archive-list'>
        <JobList filter={filter} controller={controller}/>
      </List>
      <Appbar title="All Jobs"/>
      <IconButton href=''>
        <FilterList/>
      </IconButton>
    </main>
  )
};

export default compose(
  withSnapshotList("Jobs"),
  withSnapshotList("Customers"),
  withSnapshotList("Trucks"),
  withSnapshotList("Mixtures"),
)(JobPage);
