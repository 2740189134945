import React, {useState} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import {ForgotPasswordDialog} from "../Dialogs/ForgotPasswordDialog";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import background from "../../images/login_background.jpg";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //background: theme.palette.primary.main,
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    title: {
      color: 'dodgerblue'
    },
    content: {
      padding: theme.spacing(2),
    },
    form: {
      marginTop: theme.spacing(4)
    },
    forgot: {
      marginLeft: theme.spacing(1),
      color: 'dodgerblue',
    },
    access: {
      color: 'dodgerblue',
    },
    button: {
      position: 'absolute',
      right: theme.spacing(2),
    },
    actions: {
      height: theme.spacing(4)
    },
    actionbar: {
      display: 'flex',
      paddingBottom: theme.spacing(1),
    },
    errorText: {
      paddingTop: theme.spacing(1),
      color: 'red'
    },
    img: {
      position: 'fixed',
      top: theme.spacing(1),
      left: theme.spacing(1)
    }
  };
});

export const LoginPage = () => {
  const classes = useStyles();
  const [ state, setState ] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => setState({ ...state, [e.target.type]: e.target.value });

  const changeState = (change) => setState({ ...state, ...change });

  const signInWithEmailAndPassword = async (email: String, password: String): Promise<boolean> => {
    try {
      const result = await window.firebase.auth().signInWithEmailAndPassword(email, password);
      return !!result;
    } catch (e) {
      console.log("Error Authenticating", e);
      return false;
    }
  };

  const onFormSubmission = async (event) => {
    event.preventDefault();
    changeState({ disabled: true, error: undefined });
    const authenticated = await signInWithEmailAndPassword(state.email, state.password);

    changeState({
      error: authenticated ? undefined : "Invalid Login Credentials",
      disabled: false,
      attempting: false
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={true}
        elevation={4}
        fullWidth
        maxWidth='sm'
        fullScreen={fullScreen}
        hideBackdrop
      >
        <div  className={classes.content}>
          <Typography className={classes.title} variant='h3'>
            Welcome!
          </Typography>
          <Typography variant='subtitle1'>
            Please sign in below.
          </Typography>
          <form onSubmit={onFormSubmission} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  type='email'
                  color='secondary'
                  disabled={!!state.disabled}
                  onChange={handleChange}
                  label="Email Address"
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="current-password"
                  color='secondary'
                  type='password'
                  disabled={!!state.disabled}
                  onChange={handleChange}
                  label="Password"
                  variant='filled'
                  fullWidth
                />
              </Grid>
              <Typography variant='body2' onClick={() => changeState({ forgot: true })}>
                <u className={classes.forgot}>
                  <strong>
                    Forgot your password?
                  </strong>
                </u>
              </Typography>
              <Grid item xs={12}>
                <div className={classes.actionbar}>
                  <Typography className={classes.errorText}>
                    {state.error}
                  </Typography>
                  <div className={classes.actions}>
                    <Button
                      className={classes.button}
                      disabled={!!state.disabled}
                      color='secondary'
                      type='submit'
                    >Sign In</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
          <Typography variant='body2' >
            If you don't have an account you can
            request one by emailing <u>
            <strong>
              <a
                className={classes.access}
                href='mailto: support@flodraulic.com'>
                support@flodraulic.com
              </a>
            </strong>
          </u>
            <br/>
          </Typography>
        </div>
      </Dialog>
      <ForgotPasswordDialog
        open={state.forgot}
        initialEmail={state.email}
        onClose={() => changeState({ forgot: false })}
      />
    </div>
  );
};