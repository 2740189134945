import React from 'react';
import Typography from "@material-ui/core/Typography";
import {useStore} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import Address from '../address';
import Paper from "@material-ui/core/Paper";

const Header = (props) => (
  <Typography className='expanded-header'>
    {props.children}
  </Typography>
);


export const CardContentSummary = (props) => {
  const { job } = props;
  const { customers, trucks, mixtures } = useStore().getState();
  const truck = trucks[job["Truck"]] || { Name: "Truck not assigned" };
  const mixture = mixtures[job["Mixture"]] || { Name: "Mixture not selected" };
  const customer = customers[job["Customer"]] || { Name: { First: 'Customer not assigned', Last: '' } };
  return [
    <span>{truck["Name"] || <span className='error'>Truck common name not provided</span>}</span>,
    <span>{customer["Name"]["First"]} {customer["Name"]["Last"]}</span>,
    <span>{mixture["Name"]}</span>,
  ].map((x, i) => <Header key={i}>{x}</Header>);
};

export const CardContentDetails = (props) => {
  const { job } = props;
  const { customers, mixtures } = useStore().getState();
  const mixture = mixtures[job["Mixture"]] || { Name: "Mixture not selected" };
  const customer = customers[job["Customer"]] || { Name: { First: 'Customer not assigned', Last: '' } };
  const start = moment("");
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant='h6'>Requested Time & Location</Typography>
            <Divider component='div'/>
            <Typography>
              { start.isValid() ? start.format("LLLL") : <span className='warn'>No Time Requested</span>}<br/>
              <Address address={job["Address"]}><span className='error'>Address Not provided</span></Address>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>Customer Details</Typography>
            <Divider component='div'/>
            { customer && <Typography variant='body1'>
              {customer["Name"]["First"]} {customer["Name"]["Last"]} <br/>
              {customer["Email"]} <br/>
              {customer["Phone"]} <br/>
            </Typography>}
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>Requested Product</Typography>
            <Divider component='div'/>
            <Typography variant='body1'>
              Amount: {job["Volume"] || 0} M<sup>3</sup><br/>
              Type: {mixture["Name"]}<br/>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Additional Notes & Instructions</Typography>
            <Divider component='div'/>
            <Typography variant='body1'>
              Application: {job["Application"] || "No application provided"}<br/>
              Instructions: {mixture["Notes"] || "No instructions provided"}<br/>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='h6'>Assignments</Typography>
        <Divider component='div'/>
        <div style={{padding: '1em'}}>
          <Grid container>{
            Object.values(job["Allocations"] || []).map(
              (allocation, x) => {
                const mixture = mixtures[allocation["Mixture"]];
                return (
                  <Grid key={`job-allocation-${x}`} item xs={12}>
                    <Paper style={{padding: '0.1em 0.5em', backgroundColor: 'lightgray'}}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography>
                            {moment(allocation["State"]).format("LLL")}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>
                            {allocation["Duration"] || 0} H
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>
                            {mixture ? mixture["Name"] : <span className='error'>No Mix Selected</span>}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              }
            )
          }</Grid>
        </div>
      </Grid>
    </Grid>
  );
};
