import React, {useEffect, useState} from 'react';
import {compose} from "recompose";
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch, useStore} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import {auth, db} from "../database/Firebase";
import {Autocomplete} from "@material-ui/lab";

const View = () => {
  const { user, } = useStore().getState();
  const [ state, setState ] = useState(user.val());
  const [ { data }, setData ] = useState<any>({ data: null });
  const [ password, setPassword ] = useState('');
  const dispatch = useDispatch();
  const handle = (e) => setState({ ...state, [e.target.name]: e.target.value });
  const close = () => dispatch({ type: "HIDE_USER_SETTINGS" });

  const isSuperuser = user?.val()?.Permissions?.Superuser;

  useEffect(() => db().ref("Users").once('value').then((x) => setData({ data: x })), []);

  return (
    <Paper style={{ minWidth: '40em', padding: '1em', paddingBottom: '0.5em' }}>
      <Grid container spacing={2} justify='flex-start' alignContent='flex-start'>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="Email"
                label="Email"
                value={state["Email"] || ''}
                disabled={true}
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Name"
                label="Name"
                value={state["Name"] || ''}
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Phone"
                label="Phone"
                value={state["Phone"] || ''}
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={(e) => e.preventDefault()}>
                <TextField
                  name="Password"
                  label="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type='password'
                  fullWidth
                />
              </form>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="Metric"
                label="Display Units"
                value={state?.Preferences?.Metric ?? true}
                onChange={(e) => {
                  const copy = { ...state };
                  if (!copy.Preferences) copy.Preferences = {};
                  copy.Preferences.Metric = !!e.target.value;
                  setState(copy);
                }}
                select
                fullWidth
              >
                <MenuItem value={true}>Metric</MenuItem>
                <MenuItem value={false}>Imperial</MenuItem>
              </TextField>
            </Grid>
            { isSuperuser && <Grid item xs={4}>
              <Autocomplete
                id="combo-box-demo"
                options={!!data?.val() ? Object.entries(data?.val()) : []}
                getOptionLabel={([ , value ]: any) => value?.Name ?? "Unknown"}
                style={{ width: 300 }}
                onChange={(e, [ key, value ]) => setState({
                  ...state,
                  Company: value?.Company,
                  Truck: value?.Truck,
                  acting_as: key,
                  original_company: state.original_company ?? state.Company,
                  original_truck: state.original_truck ?? state.Truck,
                })}
                renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
              />
            </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify={"space-between"}>
            <Grid item style={{ display: 'flex' }}>
              <Button href='' onClick={close}>
                Cancel
              </Button>
              <Button  href='' onClick={() => {
                let ref = db().ref(`Users/${user.key}`);
                ref.update(state)
                .then(() => {
                  if (password.length > 6) {
                    return auth().currentUser.updatePassword(password);
                  }
                }).then(() => ref.once('value'))
                .then((user) => dispatch({ type: "UPDATE_USER", payload: user})).finally(close);
              }}><span style={{ color: 'dodgerblue'}}>Save</span></Button>
            </Grid>
            <Grid item>
              <Button href='' onClick={() => auth().signOut().finally(
                () => window.location.reload()
              )}>
                <span className='error'>Sign Out</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
};

const UserSettingsPopup = (props) => {
  if (!props.visible) return (<></>);
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${props.visible ? 'visible-fade-show' : ''}`}/>
      <Grow in={props.visible} mountOnEnter unmountOnExit>
        <div className={`dialog centerer`}>
          <View/>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect((state) => ({ visible: state.userSettingPopup }))
)(UserSettingsPopup);

