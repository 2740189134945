const toolbarReducer = (state = 'NONE', action) => {
  switch (action.type) {
    case 'TOOLBAR_OPEN_LAYERS': return state === 'LAYERS' ? 'NONE' : 'LAYERS';
    case 'TOOLBAR_OPEN_TIME': return state === 'TIME' ? 'NONE' : 'TIME';
    case 'TOOLBAR_OPEN_ASSIGNMENTS': return state === 'ASSIGNMENTS' ? 'NONE' : 'ASSIGNMENTS';
    case 'TOOLBAR_CLOSE': return 'NONE';
    default: return state;
  }
};

export default toolbarReducer;
