import {connect, useDispatch} from "react-redux";
import {compose} from 'recompose';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

const variant = (type) => (x) => x[1]["Type"] === type;

const reversed = (array) => {
  array.reverse();
  return array;
};

const MaterialsList = (props) => {
  const dispatch = useDispatch();
  const handle = (payload) => () => dispatch({ type: "MATERIAL_POPUP_SET", payload: payload });
  return (
    <List component='div'>
      <ListItem component='div' button onClick={handle([ undefined, { Type: props.variant } ])}>
        <ListItemText primary={<span style={{ color: 'dodgerblue'}}>+ Create New</span>}/>
      </ListItem>
      {reversed(Object.entries(props.materials))
      .filter((x) => props.showDeprecated || !x[1]["Deprecated"])
      .filter(variant(props.variant)).map(
        ([ key, material ], i) => {
          return (
            <ListItem key={i} component='div' button onClick={handle([ key, material ])}>
              <ListItemText primary={material["Name"]}/>
            </ListItem>
          )
        }
      )
    }</List>
  )
};

export default compose(
  connect((state) => ({ materials: state.materials }))
)(MaterialsList)
