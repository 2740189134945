import {useState} from "react";

function useForm(init) {

  const [ form, setForm ] = useState({ id: -1 });
  const [ state, setState ] = useState(init);

  const updateForm = (value) => setForm({ ...form, ...value });

  const updateState = (target, value) => {
    const copy = { ...state };
    let position = copy;
    const sub = target.split('/');
      for (let i = 0; i < sub.length - 1; i++) {
        let point = sub[i];
        if (`${point}` === `${parseInt(point)}`) point = parseInt(point);
        if (!position[point]) position[point] = { };
        position = position[point];
      }
      position[sub.pop()] = value;
      setState(copy);

  };

  const value = (x) => {
    const sub = x.split('/');
    let value = state;
    for (let i = 0; i < sub.length - 1; i++) {
      let point = sub[i];
      if (`${point}` === `${parseInt(point)}`) point = parseInt(point);
      value = value[point];
      if (value === undefined) value = {};
    }
    return value[sub.pop()];
  };

  return {
    change: (handle, mul, controlled) => (e) => {
      const val = e.target.value;
      const parsed = handle(val);

      if (`${val}` === `${parsed}` || typeof(parsed) === 'number' || controlled) return updateState(
        e.target.name, (!!mul) ? parsed * mul : parsed
      );
      if (value(e.target.name)) return updateState( e.target.name,undefined )
    },
    submit: (fields, callback) => (e) => {
      e.preventDefault();
      updateForm({ id: fields });
      callback();
    },
    select: (i) => i > form.id && updateForm({ id: i }),
    valid: (x, max) => {
      const val = value(x);
      return ![
        val !== undefined,
        !`${x}`.includes("NaN"),
        (!max || `${val}`.length <= max)
      ].includes(false);
    },
    id: form.id,
    value,
    state,
    updateState: updateState,
    setState: setState
  }
}

export default useForm;
