import React, {createContext, useContext, useState} from 'react';

const LayersContext = createContext([ {}, () => {} ]);

export const useLayersContext = () => useContext(LayersContext);

export const LayersProvider = (props) => {
  return (
    <LayersContext.Provider value={useState({
      jobs: true,
      streets: true,
      trucks: true,
      satellite: false,
    })}>
      {props.children}
    </LayersContext.Provider>
  )
};