import React from 'react';
import {useCardView} from "./EditableCardView";
import {DraggableCard} from "./DraggableCard";

export const EditableCards = (props) => {
  const options = useCardView();
  const mapToCard = (e) => (<DraggableCard { ...props } key={e[0]} value={e} />);
  return (
    <>{Object.entries(options.value || {}).map(mapToCard)}</>
  );
};