import firebase from "firebase/app";

export const db = (): firebase.database.Database => firebase.database();
export const store = (): firebase.storage.Storage => firebase.storage();
export const auth = (): firebase.auth.Auth => firebase.auth();

export const company_db = (path: string) => db().ref(path ? `${window.application.company_url}/${path}` : window.application.company_url);

export const runTransactionDeleteJob = async (key) => {
  await company_db('Jobs/' + key).transaction(
    async (job) => {
      if (job?.Order) {
        await company_db(`/Orders/${job.Order}`).transaction(
          (order) => {
            if (order.assigned_to && order.assigned_to[key]) delete order.assigned_to[key];
            if (!order.assigned_to || Object.keys(order?.assigned_to).length === 0) order.assigned = null;
            return order;
          }
        );
      }
      if(job["Parent"] != undefined){
        company_db(`/Jobs/${job["Parent"]}/children/${key}`).remove();
      }

      return null;
    }
  )
};