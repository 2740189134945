import React, {useState} from 'react';
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import Search from "@material-ui/icons/Search"
import InputAdornment from "@material-ui/core/InputAdornment";
import {compose} from 'recompose';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";

const Customers = compose(
  connect((state) => ({
    customers: state.customers,
    callback: state.customerSelectionCallback
  }))
)((props) => Object.entries(props.customers).filter(
  (entry) => {
    const value = JSON.stringify(entry[1]).toLowerCase();
    const results = props.search[0].toLowerCase().split(' ').map((keyword) => value.includes(keyword));
    return !results.includes(false);
  }
).map(([ key, value], i) => {
  return (
    <ListItem
      button
      key={`customer-item-${i}`}
      component='div'
      onClick={() => {
        setTimeout(() => props.search[1](''), 500);
        props.callback([ key, value ]);
      }}
    >
      <ListItemText
        primary={`${value.Name.First} ${value.Name.Last} <${value.Email}>`}
      />
    </ListItem>
  )
}));


const CustomerSelect = (props) => {
  const visible = props.customer !== null;
  const search = useState('');
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow
        in={visible}
        unmountOnExit
        mountOnEnter
      >
        <div className='cover dialog center'>
          <Paper className='customer-select'>
            <Input
              disableUnderline
              margin='none'
              fullWidth
              placeholder="Search"
              type='search'
              className='customer-select-search'
              value={search[0]}
              onChange={(e) => search[1](e.target.value)}
              startAdornment={
                <InputAdornment position='start'>
                  <Search/>
                </InputAdornment>
              }
            />
            <Divider component='hr'/>
            <List className='customer-select-list' component='div'>
              <Customers search={search}/>
            </List>
            <CardActions className='customer-select-actions'>
              <Button size="small" href='' onClick={() => {
                setTimeout(() => search[1](''), 500);
                props.cancel();
              }}>
                Cancel
              </Button>
              <div className='flex-grow'/>
              {/*<Button size="small" href=''>*/}
              {/*  <span className='customer-select-new'>+ Create New</span>*/}
              {/*</Button>*/}
            </CardActions>
          </Paper>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect(
    (state) => ({ customer: state.customerSelectionCallback }),
    (dispatch) => ({
      cancel: () => dispatch({ type: "CUSTOMER_SELECTION_CLEAR" })
    })
  )
)(CustomerSelect);

