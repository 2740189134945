// import React, {useState} from 'react';
// import Paper from "@material-ui/core/Paper";
// import List from "@material-ui/core/List";
// import IconButton from '@material-ui/core/IconButton';
// import AccessTime from '@material-ui/icons/AccessTime';
// import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong';
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
// import ThemeProvider from "@material-ui/styles/ThemeProvider";
// import {Dark} from "../../theme";
// import red from '@material-ui/core/colors/red';
// import {connect} from 'react-redux';
// import {compose} from 'recompose';
// import {openDateRange, openLayers} from "../../actions/toolbar";
// import Tooltip from "@material-ui/core/Tooltip";
// import {Assignment, Layers} from "@material-ui/icons";
// import {OrdersButton} from "../ScheduleDay/schedule_toolbar";
// import {JobsDialog} from "../../popups/Jobs";
//
// const Theme = {
//   ...Dark,
//   palette: {
//     ...Dark.palette,
//     primary: red
//   }
// };
//
// const DispatchToolbar = (props) => {
//   const [ state, setState ] = useState({});
//
//   return (
//     <>
//       <JobsDialog visible={state.jobs} onClose={() => setState({ ...state, jobs: false })} />
//       <ThemeProvider theme={createMuiTheme(Theme)}>
//         <Paper className='dispatch-toolbar'>
//           <div className='dispatch-toolbar-list'>
//             <List component='div'>
//               <Tooltip title='Change Visible Date Range'>
//                 <IconButton href='' onClick={props.range}>
//                   <AccessTime/>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title='Change Visible Layers'>
//                 <IconButton href='' onClick={props.layers}>
//                   <Layers/>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="Refocus View">
//                 <IconButton href='' onClick={() => window.recenterMap && window.recenterMap()}>
//                   <CenterFocusStrong/>
//                 </IconButton>
//               </Tooltip>
//             </List>
//           </div>
//           <div className='dispatch-toolbar-list-bottom'>
//             <List component='div'>
//               <Tooltip title="Show Assignments" placement='top'>
//                 <IconButton href='' onClick={() => setState({ ...state, jobs: !state.jobs })}>
//                   <Assignment/>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="Show Orders">
//                 <div><OrdersButton/></div>
//               </Tooltip>
//             </List>
//           </div>
//         </Paper>
//       </ThemeProvider>
//     </>
//   )
// };
//
// export default compose(
//   connect(
//     (state) => ({orders: state.orders}),
//     (dispatch) => ({
//       layers: () => dispatch(openLayers()),
//       range: () => dispatch(openDateRange()),
//       refocus: () => dispatch({ type: "MAP_FOCUS_ALL" })
//     })
//   )
// )(DispatchToolbar);



import React, { createContext, useState, useContext } from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import {Badge, Theme,} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import {NoteAdd, CalendarToday, AllInbox, AccessTime, Layers, CenterFocusStrong} from "@material-ui/icons";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {useDateFilter} from "../../providers/date_filter";
import {useJobDialog} from "../../popups/JobEditor";
import {useUnAssignedOrdersList} from "../../providers/Firebase";
import {useOrdersDialog} from "../../popups/Orders";
import {CustomTooltip} from "../../components/CustomTooltip";
import {OrdersButton} from "../ScheduleDay/schedule_toolbar";
import {JobsDialog} from "../../popups/Jobs";
import {openDateRange, openLayers} from "../../actions/toolbar";
import {connect} from "react-redux";
import {compose} from 'recompose';

const ToolbarContext = createContext();

export const useToolbarState = () => useContext(ToolbarContext);

export const ToolbarStateProvider = ({ children }) => {
  const [ state, setState ] = useState({});
  const changeState = (change) => setState({ ...state, ...change });
  return (
    <ToolbarContext.Provider value={[ state, changeState ]}>
      {children}
    </ToolbarContext.Provider>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    background: theme.palette.secondary.main,
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: theme.spacing(17),
    zIndex: 1150,
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  icon: {
    color: 'white',
  },
  gap: {
    flexGrow: 1,
  }
}));

export const DispatchToolbar = (props) => {
  const classes = useStyles();

  const [ state, setState ] = useState({});

  return (
    <>
      <JobsDialog visible={state.jobs} onClose={() => setState({ ...state, jobs: false })} />
      <Paper className={classes.root}>
        <div className={classes.content}>
          <CustomTooltip placement='right' title='Change Selected Date'>
            <IconButton onClick={props.range}>
              <AccessTime className='text-white'/>
            </IconButton>
          </CustomTooltip>
          <CustomTooltip placement='right' title='Change Visible Layers'>
            <IconButton onClick={props.layers}>
              <Layers className='text-white'/>
            </IconButton>
          </CustomTooltip>
          <CustomTooltip placement='right' title='Refocus View'>
            <IconButton onClick={() => window.recenterMap && window.recenterMap()}>
              <CenterFocusStrong className='text-white'/>
            </IconButton>
          </CustomTooltip>
          <div className='flex-grow'/>
          <CustomTooltip placement='right' title='Show Assignments'>
            <IconButton onClick={() => setState({ ...state, jobs: !state.jobs })}>
              <NoteAdd className='text-white'/>
            </IconButton>
          </CustomTooltip>
          <CustomTooltip placement='right' title='Show Orders'>
            <div>
              <OrdersButton />
            </div>
          </CustomTooltip>
        </div>
      </Paper>
    </>
  );
};

export default compose(
  connect(
    (state) => ({orders: state.orders}),
    (dispatch) => ({
      layers: () => dispatch(openLayers()),
      range: () => dispatch(openDateRange()),
      refocus: () => dispatch({ type: "MAP_FOCUS_ALL" })
    })
  )
)(DispatchToolbar);
