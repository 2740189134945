import React, {useState} from 'react';
import {compose} from "recompose";
import connect from "react-redux/es/connect/connect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch, useStore} from "react-redux";
import AddressAutoComplete from "../address_auto_complete";

const getStateFromCustomer = (customer) => {
  if (!customer || customer.length < 2) return { };
  return customer[1];
};

const MaterialDesignerView = (props) => {
  const [ state, setState ] = useState(getStateFromCustomer(props.customer, props.type));
  const { company } = useStore().getState();
  const dispatch = useDispatch();
  const handle = (e) => setState({ ...state, [e.target.name]: e.target.value });
  const handleName = (e) => setState({ ...state, Name: { ...state.Name, [e.target.name]: e.target.value }});
  const handleAddress = (value) => setState({ ...state, Address: { Local: value }});
  const close = () => dispatch({ type: "CUSTOMER_POPUP_CLOSE" });

  const canSave = state.Name
    && state.Name.First
    && state.Name.Last
    && state.Address
    && state.Address.Local
    && state.Name.First.length > 0
    && state.Name.Last.length > 0
    && state.Address.Local.length > 0;

  return (
    <Paper style={{ minWidth: '30em', padding: '1em', paddingBottom: '0.5em' }}>
      <Grid container spacing={2} justify='flex-start' alignContent='flex-start'>
        <Grid item xs={6}>
          <TextField
            name="First"
            label="First Name"
            value={(state["Name"] || {})["First"] || ''}
            onChange={handleName}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="Last"
            label="Last Name"
            value={(state["Name"] || {})["Last"] || ''}
            onChange={handleName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Email"
            label="Email Address"
            value={state["Email"] || ''}
            onChange={handle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Phone"
            label="Phone Number"
            value={state["Phone"] || ''}
            onChange={handle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Company"
            label="Company"
            value={state["Company"] || ''}
            onChange={handle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <AddressAutoComplete
            onChange={handleAddress}
            value={(state["Address"] || {})["Local"] || ''}
            TextFieldProps={{
              name: 'Local',
              value: (state["Address"] || {})["Local"] || '',
              label: 'Billing Address',
              fullWidth: true
            }}
          />
        </Grid>
        <Grid item xs={10} style={{ display: 'flex' }}>
          <Button href='' onClick={close}>
            Cancel
          </Button>
          <Button
            href=''
            disabled={!canSave}
            onClick={() => {
              let ref = window.firebase.database().ref(`${company.val().url}/Customers/`);
              if (props.customer.length < 2) ref = ref.push();
              else ref = ref.child(props.customer[0]);
              const coder = new window.google.maps.Geocoder();
              coder.geocode({ 'address': state.Address.Local }, (results, status) => {
                const result = results[0];
                ref.update({
                  ...state,
                  Address: {
                    ...state.Address,
                    Lat: result.geometry.location.lat(),
                    Lon: result.geometry.location.lng(),
                  }
                }).finally(close);
              });
            }}
          ><span style={canSave ? { color: 'dodgerblue'} : {}}>Save</span></Button>
        </Grid>
        { props.customer.length === 2 && <Grid item xs={2}>
          <Button href='' onClick={() => {
            window.firebase.database()
            .ref(`${company.val().url}/Customers/${props.customer[0]}/Deprecated`)
            .set(true)
            .finally(close);
          }}>
            <span className='error'>Archive</span>
          </Button>
        </Grid>}
      </Grid>
    </Paper>
  )
};

const CustomerDesigner = (props) => {
  const visible = props.customer !== null;
  if (!visible) return <div/>;
  return (
    <div>
      <div className={`cover dialog-background visibility-fade ${visible ? 'visible-fade-show' : ''}`}/>
      <Grow in={visible} mountOnEnter unmountOnExit>
        <div className='dialog center'>
          <MaterialDesignerView type={props.type} customer={props.customer}/>
        </div>
      </Grow>
    </div>
  )
};

export default compose(
  connect((state) => ({ customer: state.customer }))
)(CustomerDesigner);

