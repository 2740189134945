import React, {useState} from "react";
import {compose, defaultProps} from 'recompose';
import List from "@material-ui/core/List";
import connect from "react-redux/es/connect/connect";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {formatAddress} from "./index";
import Button from "@material-ui/core/Button";
import {useStore} from "react-redux";
import {handleAssign, handleDelete} from "./handle_order";


const OrderListTiles = compose(
  connect((state) => ({ orders: state.orders }))
)((props) => Object.entries(props.orders).map(
  ([ key, value ], i) => {
    const { firebase, company } = useStore().getState();
    const [ open, setOpen ] = props.controller;
    const expanded = open === i;
    return (
      <ExpansionPanel
        key={`order-expansion-${i}`}
        className='tool-window-assignment-assign'
        expanded={expanded}
        onChange={() => setOpen(expanded ? null : i)}
      >
        <ExpansionPanelSummary href=''>
          <div className='flex-grow'>
            <Typography variant='h6'>
              { value.Customer.Name.toString() }
            </Typography>
            <Typography variant='subtitle2'>
              Requested for: { moment(value.Time).format("LLL") }<br/>
              Delivery location: { formatAddress(value.Address) }<br/>
            </Typography>
          </div>
          <Tooltip className='tool-window-assignment-assign-button' title='Move to assignments'>
            <IconButton onClick={handleAssign(firebase, company, key, value)} href=''>
              <KeyboardArrowRight/>
            </IconButton>
          </Tooltip>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ display: 'block'}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  Contact Information
                  <Divider component='hr'/>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>
                  <strong>Email:</strong> { value.Customer.Email }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>
                  <strong>Phone:</strong> { value.Customer.Phone }
                </Typography>
              </Grid>
              <Grid item xs={12}/>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  Requested Product
                  <Divider component='hr'/>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>
                  <strong>Name:</strong> { value.Product.Name }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>
                  <strong>Amount:</strong> { value.Product.Amount } M<sup>3</sup>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'>
                  <strong>Application:</strong> { value.Application || "No Application was provided." }
                </Typography>
              </Grid>
              <Grid item xs={12}/>
              <Grid item xs={12}/>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  multiline={true}
                  rows={4}
                  label="Additional Notes"
                  value={ value.Notes || "No additional delivery instructions were provided."}
                />
              </Grid>
              <Grid item xs={12}/>
            </Grid>
            <Button style={{ opacity: '1 !important' }} onClick={handleDelete(firebase, company, key)}>
              <span className='error'>
                Ignore
              </span>
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
));


const Orders = (props) => {
  const control = useState(null);
  return (
    <div>
      <ListItem className='tool-window-assignment-header' component='div'>
        <Typography variant='h6'>
          <span>Orders</span>
        </Typography>
      </ListItem>
      <List component='div' className='tool-window-assignment-list'>
        <OrderListTiles controller={control} {...props}/>
      </List>
    </div>
  )
};

export default compose(
  defaultProps({ setTab: () => {} }),
)(Orders)
