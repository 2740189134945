const defaultUser = {
  Company: '',
  Email: '',
  Name: '',
  Phone: '',
  Truck: '',
  Preferences: {
    Metric: true
  },
  Permissions: {
    Accounts: false,
    Admin: false,
    Operator: false,
    Superuser: false,
    Dispatch: false,
  },
};

const User = (snapshot) =>  {
  const value = snapshot.val();
  return {
    ...Object.assign({ ...defaultUser }, value),
    Permissions: Object.assign( { ...defaultUser.Permissions }, value.Permissions),
    Preferences: Object.assign( { ...defaultUser.Preferences }, value.Preferences)
  };
};

export default User;
