import React from "react";

const Address = (props) => {
  const { address } = props;
  if (!address) return (<span>{props.children}</span>);
  if ('local' in address) return (<span>{address.local}</span>);
  if ('LongName' in address) return (<span>{address.LongName}</span>);
  if ('Local' in address) return (<span>{address.Local}</span>);
  return [
    <span>{address.Street} </span>,
    <span>{address.City}, </span>,
    <span>{address.PostCode} </span>
  ];
}

export default Address;