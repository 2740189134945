import React, {useEffect} from 'react';
import {useDispatch, useStore} from "react-redux";
import { db } from './Firebase';

export const withSnapshotList = (type) => (Component) => {
  return (props) => {
    const { company } = useStore().getState();
    const dispatch = useDispatch();
    const orders = company && company.val().url + `/${type}`;
    const root = type.startsWith("/");
    const ref = db().ref(root ? type : orders);
    const effect = root 
      ? (change) => (snap) => dispatch({ type: `${type.split('/').pop().toUpperCase()}_${change}`, payload: snap })
      : (change) => (snap) => dispatch({ type: `${type.toUpperCase()}_${change}`, payload: snap });
    useEffect(() => {
      ref.on('child_added', effect('ADD'));
      ref.on('child_changed', effect('CHANGE'));
      ref.on('child_removed', effect('REMOVE'));
      return () => ref.off();
    });
    return <Component {...props}/>
  };
};

