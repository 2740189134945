import React, {createContext, useContext} from 'react';

const EditableCardViewContext = createContext(null);

export const useCardView = () => useContext(EditableCardViewContext);

export const EditableCardView = ({
  column,
  spacing,
  width,
  height,
  onChange,
  value,
  children,
  left,
  top,
}) => {
  column = column ?? 240;
  spacing = spacing ?? 5;
  width = width ?? 1000;
  height = height ?? '100vh';
  left = left ?? 0;
  top = top ?? 0;

  return (
    <EditableCardViewContext.Provider value={{
      width: column,
      spacing: spacing,
      onChange: onChange,
      value: value,
      left: left,
      top: top,
      max: width,
    }}><div style={{
      paddingTop: top,
      paddingLeft: left,
      height: height + top,
      width: width + left,
      overflowY: 'hidden',
    }}>{children}</div></EditableCardViewContext.Provider>
  )
};