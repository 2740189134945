import React, { useEffect, useRef, useState } from 'react';
import Appbar from '../app_bar';
import '../../styles/archive.css';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Box, Divider, Link, MenuItem, Paper, TextField, Typography} from "@material-ui/core";
import { company_db, db, store } from "../../database/Firebase";
import ticket_template from '../../images/ticket_template.svg';
import export_settings from '../../images/export_settings.png';
import export_template from '../../images/default_ticket_template.xd';
import Button from "@material-ui/core/Button";
import { DropzoneArea } from 'material-ui-dropzone';
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";

const PDFSettings = () => {

  const [ template, setTemplate ] = useState();

  useEffect(() => {
    company_db().child("pdf_template").once('value')
      .then(x => x.val())
      .then(x => x && store().ref().child(x).getDownloadURL())
      .then(x => setTemplate(x ?? null));
  }, [])

  return (
    <Box display='flex'>
      <div>
        <Paper>
          <img
            width='700em'
            src={template === null ? ticket_template : template}
            alt=''
          />
        </Paper>
      </div>
      <Box width='1em'/>
      <Box>
        <Typography variant='h6'>
          Instructions for modifing export
        </Typography>
        <Box height='1em'/>
        <Typography>
          1. Download the template
        </Typography>
        <Box paddingLeft='1em'>
          <Button
            href={export_template}
            color='secondary'
            variant='contained'
          >Ticket Template.xd</Button>
        </Box>
        <Box height='1em'/>
        <Typography>
          2. Open the file with Adobe XD
        </Typography>
        <Box paddingLeft='1em'>
          <Button
            href='https://helpx.adobe.com/ca/xd/get-started.html'
            color='secondary'
            variant='contained'
          >Download Adobe XD</Button>
        </Box>
        <Box height='1em'/>
        <Typography>
          3. Customize the file how you'd like
        </Typography>
        <Box height='1em'/>
        <Typography>
          4. Export the XD file in the format below
        </Typography>
        <Box paddingLeft='1em' paddingTop='4px'>
          <img src={export_settings} alt=''/>
        </Box>
        <Box height='1em'/>
        <Typography>
          5. Upload the export to update the template
        </Typography>
        <Box height='1em'/>
        <Box paddingLeft='1em' paddingRight='2em'>
          <DropzoneArea
            filesLimit={1}
            showPreviews={false}
            showPreviewsInDropzone={false}
            acceptedFiles={[ "image/svg+xml" ]}
            onDrop={(e) => {
              store().ref()
                .child('ticket_template/' + db().ref().push().key + ".svg")
                .put(e[0])
                .then(e => company_db().child("pdf_template").set(e?.ref?.fullPath),e)
                .then(e => e?.ref?.getDownloadURL())
                .then(e => setTemplate(e),e)

            }}
          />
        </Box>

      </Box>
    </Box>
  )
}

const SMTPForm = () => {

  const { register, handleSubmit, watch, errors, formState } = useForm();
  const [ updated, setUpdated ] = useState();

  const onSubmit = ({ provider, user, password }) => {
    setUpdated(false);
    const company = 'Companies/' + window.application.company_key;
    db().ref(company + "/smtp").set(`smtp://${user}:${password}@${provider ?? 'smtp.office365.com:587'}`)
      .then(() => setUpdated(true))
  }

  const provider = watch('provider')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Typography>
            SMTP Account Settings
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            {...register('provider')}
            label='Provider'
            select
            fullWidth
            defaultValue='smtp.office365.com:587'
          >
            <MenuItem value='smtp.office365.com:587'>Outlook</MenuItem>
            <MenuItem value='smtp.gmail.com:587'>Gmail</MenuItem>
          </TextField>
          <br/>
        </Grid>
        <Grid item xs={7}/>
        <Grid item xs={5}>
          <TextField
            {...register('user')}
            color='secondary'
            label='User'
            fullWidth
          />
        </Grid>
        <Grid item xs={7}/>
        <Grid item xs={5}>
          <TextField
            {...register('password')}
            type='password'
            color='secondary'
            label='Password'
            fullWidth
          />
        </Grid>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={12}>
          { provider === 'smtp.gmail.com:587' && <a>An app password is required for Gmail accounts, click <Link color='secondary' href='https://support.google.com/accounts/answer/185833?hl=en#:~:text=Create%20%26%20use%20App%20Passwords'>here</Link> for more details</a>}
        </Grid>
        <Grid item xs={2}>
          <Button type='submit'>Update</Button>
        </Grid>
        <Grid item>
          { updated === true && <Typography>Your account has been updated</Typography> }
        </Grid>
      </Grid>
    </form>
  )
}


const SendTestForm = () => {

  const { register, handleSubmit, } = useForm();
  const [ ok, setOK ] = useState();

  const onSubmit = ({ to, subject, body }) => {
    setOK(null);


    fetch('https://us-central1-fd-remote.cloudfunctions.net/SendEmail', {
      method: "POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        to, subject,
        company: window.application.company_key,
        text: body,
      })
    })
      .then(x => x.json())
      .then(x => x.status)
      .then(x => setOK(x === 'ok'))
      .catch((_) => setOK(false));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <Grid container spacing={2} alignContent='center' alignItems='center'>
        <Grid item xs={12}>
          <Typography>
            Send a test email
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('to', { required: true })}
            fullWidth
            color='secondary'
            label='To'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('subject', { required: true })}
            fullWidth
            color='secondary'
            label='Subject'
            defaultValue={"A message from BatchPro"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('body', { required: true })}
            fullWidth
            color='secondary'
            label='Body'
            multiline
            variant='outlined'
            rows={8}
            defaultValue={"Mic check, 1, 2, 3. "}
          />
        </Grid>
        <Grid item xs={2}>
          <Button type='submit'>Send</Button>
        </Grid>
        <Grid item>
          { ok === true && <Typography>You're all set</Typography> }
          { ok === false && <Typography>Something's not working check your stmp setting</Typography> }
        </Grid>
      </Grid>
    </form>
  )
}

const EmailSettings = () => {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <SMTPForm />
      </Grid>
      <Grid item xs={12}>
        <SendTestForm />
      </Grid>
    </Grid>
  )
}



const CompanySettingsPage = () => {

  const [ tab, setTab ] = useState(0);
  const handleTabChange = (e, tab) => setTab(tab);

  return (
    <main>
      <Appbar title="Company Settings"/>
      <Box paddingLeft='5em' paddingTop='4.5em' paddingRight='1em'>
        <Divider style={{ height: 2, position: 'relative', top: '3em' }}/>
        <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="PDF Exports" />
          <Tab label="Email Settings" />
        </Tabs>
        <Box padding='1em'>
          { tab === 0 && <PDFSettings /> }
          { tab === 1 && <EmailSettings /> }
        </Box>
      </Box>

    </main>
  )
};

export default CompanySettingsPage;
