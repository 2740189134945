import React, {useState} from 'react';
import Grow from '@material-ui/core/Grow';
import {compose} from 'recompose';
import connect from "react-redux/es/connect/connect";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Theme from "../../../theme";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import SwipeableViews from "react-swipeable-views";
import Assignments from "./assignments";
import Orders from "./orders";
import {openAssignments} from "../../../actions/toolbar";

export const formatAddress = (a) => {
  if (!a) return '';
  if ('LongName' in a) return a.LongName;
  else return `${a.Street} ${a.City}, ${a.PostCode}`
};

const tabs = {
  ...Theme,
  palette: {
    ...Theme.palette,
    secondary: {
      main: '#FFF',
      contrastText: '#000'
    },
  }
};


const AssignmentView = (props) => {
  const [ tab, setTab ] = useState(0);
  const visible = props.tool === 'ASSIGNMENTS';
  const handleView = (e, value) => setTab(value);
  return (
    <>
      { visible && <div
        onClick={props.close}
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 100
          }}
      /> }
      <Grow in={visible} mountOnEnter unmountOnExit>
        <Paper className='tool-window-assignment-body'>
          <div className='tool-window-assignment-view'>
            <SwipeableViews
              axis='x'
              index={tab}
            >
              <Orders setTab={setTab}/>
              <Assignments />
            </SwipeableViews>
          </div>
          <ThemeProvider theme={createMuiTheme(tabs)}>
            <Tabs
              className='tool-window-assignment-footer'
              component='div'
              value={tab}
              onChange={handleView}
              variant='fullWidth'
            >
              <Tab label="Orders" href=''/>
              <Tab label="Jobs" href=''/>
            </Tabs>
          </ThemeProvider>
        </Paper>
      </Grow>
    </>
  )
};

export default compose(
  connect(
    (state) => ({ tool: state.toolbarWindow }),
    (dispatch) => ({
      close: () => dispatch(openAssignments()),
    })
  ),
)(AssignmentView);
