import React from "react";
import {compose} from 'recompose';
import {connect} from "react-redux";
import {clearOrder, updateOrder} from "../../actions/order";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from './styles';
import TextField from "@material-ui/core/TextField";
import {DateTimePicker} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddressAutoComplete from "../address_auto_complete";

const Delivery = (props) => {
  const classes = useStyles();
  const handle = (e) => props.update({ [e.target.name]: e.target.value });
  const handleMix = (e) => props.update({ Product: {
    ...props.order.Product,
    Name: props.mixtures[e.target.value].Name,
    Reference: e.target.value
  }});
  const handleAmount = (e) => props.update({ Product: {
      ...props.order.Product,
      Amount: e.target.value
  }});
  const handleTime = (e) => props.update({ Start: e });
  const submit = (e) => { e.preventDefault(); props.onSubmit(); };
  const mixtures = Object.fromEntries(Object.entries(props.mixtures || {}).filter(([ key, value ]) => !value.Deprecated));
  return (
    <div className={classes.padding}>
      <Paper className={classes.paper}>
        <form onSubmit={submit} className={classes.form}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <AddressAutoComplete
                onChange={e => props.update({ Address: e })}
                value={props.order.Address || ''}
                TextFieldProps={{
                  label: 'Delivery Address',
                  fullWidth: true,
                  placeholder: 'Use Billing Address',
                  InputLabelProps: {
                    shrink: true
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                name='Start'
                value={props.order.Start || null}
                onChange={handleTime}
                minutesStep={15}
                label="Delivery Date & Time"
                allowKeyboardControl={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                name="Mixture"
                label="Mixture"
                value={((props.order || {}).Product || {}).Reference || ''}
                select
                fullWidth
                onChange={handleMix}
              >
                <MenuItem>None</MenuItem>{
                Object.entries(mixtures || {}).map(
                  ([ key, value ], i) => <MenuItem key={i} value={key}>{value.Name}</MenuItem>
                )
              }</TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="Amount"
                label="Volume"
                value={(props.order.Product || {}).Amount ?? ''}
                onChange={handleAmount}
                type='number'
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position='end'>YD<sup>3</sup></InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Application"
                label="Concrete Application"
                value={props.order.Application || ''}
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Notes"
                margin="none"
                variant="outlined"
                label="Delivery Instructions or Special Notes"
                value={props.order.Notes || ''}
                onChange={handle}
                fullWidth
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid style={{
                paddingRight: '1em',
              }}container justify="space-between">
                <Grid item sm={1}>
                  <Button
                    onClick={props.onBack}
                  >Back</Button>
                </Grid>
                <Grid item sm={1}>
                  <Button
                    type="submit"
                  >Request</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
};

export default compose(
  connect(
    (state) => ({ order: state.order, firebase: state.firebase, mixtures: state.mixtures }),
    (dispatch) => ({
      update: (change) => dispatch(updateOrder(change)),
      clear: () => dispatch(clearOrder()),
    })
  )
)(Delivery);
