import {useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, Grid} from "@material-ui/core";
import {useDialogs} from "../providers/dialogs";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';

import {useList, useObject} from "react-firebase-hooks/database";
import {company_db as db, db as root} from "../database/Firebase";

var stoneCount = [0,0,0,0];
var sandCount = [0,0,0,0];
var hasBatchSheet = false;

export const useTruckInfoPopUp = () => {
  const [state, setState] = useDialogs();
    return {
      show: async (truckInfo) => {
        await getArhciveRange(truckInfo[0],truckInfo[2]);

        setState({...state,truck: truckInfo});
      },
      close: () => setState({...state, truck: undefined}),
      truckID: state.truck,
    };
    /* 
         
    */
  };


  async function  getArhciveRange(truckKey,companyOfTruck) {
    hasBatchSheet = false;
    const batchSheetQuery = await db('BatchSheetInfo').get();
    await batchSheetQuery.forEach((child)=>{
      if(child.key == truckKey){
        hasBatchSheet = true;
      }
    });

   
      var yearStart = moment().startOf('year')
      var monthStart = moment().startOf('month')
      var weekStart = moment().startOf('week')
      var dayStart = moment().startOf('day')
      var last7DaysStart = moment().startOf('day').subtract(7,'days');
      var end = moment().endOf('day');

      const query = await db('Tickets').get();
      stoneCount = [0,0,0,0,0];
      sandCount = [0,0,0,0,0];
      
      await query.forEach((child)=>{
        var data = child.toJSON();
        var ticketDate = moment(data["ARCHIVE_STOP_YEAR"]+"/"+data["ARCHIVE_STOP_MONTH"]+"/"+data["ARCHIVE_STOP_DAY"]);
        
        if(data["TRUCK_KEY"] == truckKey){
          if(ticketDate > yearStart && ticketDate < end){
            stoneCount[0]+=data["ARCHIVE_STONE_AMOUNT"]
            sandCount[0]+=data["ARCHIVE_SAND_AMOUNT"]
          }
          if(ticketDate > monthStart && ticketDate < end){
            stoneCount[1]+=data["ARCHIVE_STONE_AMOUNT"]
            sandCount[1]+=data["ARCHIVE_SAND_AMOUNT"]
          }
          if(ticketDate > weekStart && ticketDate < end){
            stoneCount[2]+=data["ARCHIVE_STONE_AMOUNT"]
            sandCount[2]+=data["ARCHIVE_SAND_AMOUNT"]
          }
          if(ticketDate > last7DaysStart && ticketDate < end){
            stoneCount[3]+=data["ARCHIVE_STONE_AMOUNT"]
            sandCount[3]+=data["ARCHIVE_SAND_AMOUNT"]
          }
          if(ticketDate > dayStart && ticketDate < end){
            stoneCount[4]+=data["ARCHIVE_STONE_AMOUNT"]
            sandCount[4]+=data["ARCHIVE_SAND_AMOUNT"]
          }
        }
      });
  }

  const Content = (props) => {
    const [state, setState] = useDialogs();
    const { truckID, close} = useTruckInfoPopUp();

    if(!!truckID){
      let truckKey = truckID[0];
      let truckData = truckID[1];
      let companyOfTruck = truckID[2];


    return (
      <>
          <DialogContent>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography noWrap variant="h6" align="center">
                { truckData?.Name ?? "No Name Provided" }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography noWrap variant="h6" align="center">
                Aggregate Totals (KG)
                </Typography>
              </Grid>

              <Grid item xs={2}>
                  <Typography noWrap variant="h6" align="center">
                 
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography noWrap variant="h6" align="center">
                  Daily totals
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap variant="h6" align="center">
                  last 7 days
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap variant="h6" align="center">
                  week to date
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap variant="h6" align="center">
                  month to date
                  </Typography>
              </Grid> 
              <Grid item xs={2}>
                <Typography noWrap variant="h6" align="center">
                  year to date
                  </Typography>
              </Grid>
              

              <Grid item xs={2}>
                  <Typography noWrap variant="h6" align="center">
                  Stone
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap align="center">
                  { (stoneCount[4])? stoneCount[4]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap align="center">
                { (stoneCount[3])? stoneCount[3]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap align="center">
                { (stoneCount[2])? stoneCount[2]+ " KG" :"N/A" }
                  </Typography>
              </Grid> 
              <Grid item xs={2}>
                <Typography noWrap align="center">
                { (stoneCount[1])? stoneCount[1]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap align="center">
                { (stoneCount[0])? stoneCount[0]+ " KG" :"N/A" }
                  </Typography>
              </Grid>




              
              <Grid item xs={2}>
                  <Typography noWrap  variant="h6" align="center">
                  Sand
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap  align="center">
                { (sandCount[4])? sandCount[4]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap  align="center">
                { (sandCount[3])? sandCount[3]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap  align="center">
                { (sandCount[2])? sandCount[2]+ " KG" :"N/A" }
                  </Typography>
              </Grid> 
              <Grid item xs={2}>
                <Typography noWrap  align="center">
                { (sandCount[1])? sandCount[1]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap  align="center">
                { (sandCount[0])? sandCount[0]+ " KG" :"N/A" }
                  </Typography>
              </Grid>
              <Grid item xs={12}/>
                
            </Grid>
          </DialogContent>

          <DialogActions>
          {(hasBatchSheet)?
            <Button style={{ color: 'orange'}}  onClick={()=>{
              const url = 'https://us-central1-fd-remote.cloudfunctions.net/generateBatchSheet';
              const dataUrl = url + '?' + ['truck=' + truckKey,'company=' + companyOfTruck,].join('&')
              window.open(dataUrl,"_self");
            }} >
              Get Batch Sheet
            </Button>:
            <Button style={{ color: 'gray'}} disabled>
              Get Batch Sheet
            </Button>
          }

            <Button onClick={()=>setState({...state, truck: undefined})}>
              Close
            </Button>
          </DialogActions>
      </>
    );
    }else{
      return ( <></>);
    }
}
  export const TruckInfoPopUp = () => {
    const { truckID } = useTruckInfoPopUp();
    return (
    <Dialog open={!!truckID} fullWidth maxWidth='md' keepMounted={false} >
      <Content/> 
    </Dialog>
    )
  }