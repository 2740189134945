import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import '../../styles/dispatch.css';
import DispatchToolbar from "./dispatch_toolbar";
import {withSnapshotList} from "../../database/firebase_hoc";
import {compose} from 'recompose';
import DateRangeView from "./date_range_view";
import LayersView from "./layers_view";
import AssignmentView from './assignment'
import CustomerSelect from "./customer_select";
import TruckSelect from "./truck_select";
import MixSelect from './mix_select';
import AddressSelect from './address_select';
import {DateFilterProvider, useDateFilter} from "../../providers/date_filter";
import DeckGL from "@deck.gl/react";
import { IconLayer } from "@deck.gl/layers";
import {StaticMap} from "react-map-gl";
import TruckIcon from '../../images/truck.png';
import JobIcon from '../../images/job.png';
import {useJobsList, useTrucksList, useUserPreferences} from "../../providers/Firebase";
import {getColor} from "../../providers/colors";
import {LonLat} from "../../providers/LonLat";
import moment from "moment";
import {TruckTooltip} from "./TruckTooltip";
import {JobTooltip} from "./JobTooltip";
import {Theme} from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import {useLayersContext} from "./visible_layers";
import {company_db as db, db as root} from "../../database/Firebase";



import Paper from "@material-ui/core/Paper";
import {MenuItem} from "@material-ui/core";
import {ContextMenu, showMenu,hideMenu} from "react-contextmenu";

const map_token = 'pk.eyJ1IjoibWxldmVzcXVlIiwiYSI6ImNrNjZ1dWo1bjAyZGQzZnF4OGx5dW53cHgifQ.ipOatS6PdqyI1dX3gG4g3w';

const location = [ 43.652713, -79.894495 ];

const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
};

const getTruckIcon = () => ({
  url: TruckIcon.toString(),
  width: 96,
  height: 96,
  mask: true,
});

const getJobIcon = () => ({
  url: JobIcon.toString(),
  width: 96,
  height: 96,
  mask: true,
});

var selectedTruckID="";
var selectedIcon;

const Map = ({ initialViewState }) => {
  
  const [ layers ] = useLayersContext();

  const all_jobs = useJobsList();

  const trucks = useTrucksList();

  const { Metric } = useUserPreferences();

  const [ { start, end } ] = useDateFilter();

  const jobs = Object.fromEntries(
    Object.entries(all_jobs).filter(
      ([ , x ]) => moment(x.Start).isBetween(
        moment(start).startOf('day'),
        moment(end).endOf('day'),
        null,
        '[]'
      )
    )
  );

  useEffect(() => {
    document.getElementById('no-context-menu')
      .addEventListener('contextmenu', evt => evt.preventDefault());
  }, []);

  const handleTooltip = (e, render) => {
    const element = document.getElementById('tooltip');
    element.style.position = 'fixed';
    element.style.top = e.y + 8 + 'px';
    element.style.left = e.x + 120 + 'px';
    element.style.zIndex = 9999999;
    element.style.display = e.object ? 'block' : 'none';
    ReactDOM.render(e.object ? render() : <div/>, element);
  };

  const handleTruckTooltip = (e) => handleTooltip(e, () => <TruckTooltip truck={trucks[e.object]}/>);

  const handleJobTooltip = (e) => handleTooltip(e, () => <JobTooltip
    job={jobs[e.object]}
    metric={Metric}
  />);

  const [ viewState, setViewState ] = useState(initialViewState);


  window.recenterMap = () => setViewState({ moved: false, ...viewState, ...initialViewState });

  useEffect(() => {
    if (!viewState.moved) setViewState({ ...viewState, ...initialViewState })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ initialViewState ]);

 

  return (
    <DeckGL
      controller={true}
      viewState={viewState}
      onViewStateChange={(e) => {
        hideMenu();
        setViewState({ moved: true, ...e.viewState });
      }
      }
      initialViewState={initialViewState}
      onMap
    >
      <StaticMap
        reuseMaps
        preventStyleDiffing={true}
        mapStyle={ layers.streets
          ? 'mapbox://styles/mlevesque/ck6johqql03se1ipd1dcatb7d'
          : 'mapbox://styles/mlevesque/ck6jmlme30e8v1io2zz9la2pu'
        }
        mapboxApiAccessToken={map_token}
      />
      { layers.trucks && <IconLayer
        id="location-layer"
        data={Object.keys(trucks).filter((x) => !!trucks[x]?.Location)}
        getPosition={(x) => LonLat(trucks[x])}
        getIcon={getTruckIcon}
        getColor={(x, i) => hexToRGB(trucks[x].Color??getColor(i.index))}
        getSize={40}
        pickable={true}
        onHover={handleTruckTooltip}
        onClick = {(objectData,mouseData)=>{
          //console.log(objectData);
          //Note Add color selecter
          var truckData = trucks[objectData.object];
          if(mouseData.leftButton){
            const x = mouseData.center.x+10;
            const y = mouseData.center.y-25;
            selectedTruckID = objectData.object;
            selectedIcon = objectData;
            showMenu({
              position: {x , y},
              id :"ChangeColorContext"}
              )
              //this.getColor = "red";
          }
        }}
      />
      }
      { layers.jobs &&  <IconLayer
        id="job-layer"
        data={Object.keys(jobs).filter((x) => !!jobs[x]?.Address)}
        getPosition={(x) => LonLat(jobs[x]?.Address ?? { })}
        getIcon={getJobIcon}
        getColor={(x) => hexToRGB(getColor(Object.keys(trucks).indexOf(jobs[x].Truck)))}
        getSize={40}
        pickable={true}
        onHover={handleJobTooltip}
      />}


    </DeckGL>
    
  )
};


const ChangeTruckColor= ()=>{
  db(`Trucks/${selectedTruckID}/Color`).set(Math.floor(Math.random()*16777215).toString(16))
//check archii code for color selector 
}


const InitialViewState = () => {

  const trucks = useTrucksList();

  const locations = Object.values(trucks)
      .map((x) => x?.Location)
      .filter((x) => x && x.Lat && x.Lon);

  const lats = locations.map(x => x.Lat);
  const lons = locations.map(x => x.Lon);

  const lat = lats.reduce((a, b) => a + b, 0) / lats.length;
  const lng = lons.reduce((a, b) => a + b, 0) / lons.length;

  const initialViewState = {
    latitude: isNaN(lat) ? location[0] : lat,
    longitude: isNaN(lng) ? location[1] : lng,
    zoom: 12,
    pitch: 0,
    bearing: 0
  };


  return <Map initialViewState={initialViewState}/>
};

const useStyles = makeStyles((theme: Theme) => ({
  map: {
    position: 'fixed',
    left: theme.spacing(16),
    top: 0,
    right: 0,
    bottom: 0,
  }
}));

const Dispatch = () => {

  const classes = useStyles();
  return (
    <DateFilterProvider>
      <main>
        <div id='no-context-menu' className={classes.map}><InitialViewState/></div>
        <ContextMenu id={"ChangeColorContext"}>
          <Paper>
            <MenuItem onClick={ChangeTruckColor} >Change Color</MenuItem>
          </Paper>
        </ContextMenu>
        <CustomerSelect/>
        <TruckSelect/>
        <MixSelect/>
        <AddressSelect/>
        <DateRangeView />
        <LayersView />
        <AssignmentView />
        <DispatchToolbar/>
      </main>
    </DateFilterProvider>
  )
};


export default compose(
  withSnapshotList("Orders"),
  withSnapshotList("Jobs"),
  withSnapshotList("Customers"),
  withSnapshotList("Trucks"),
  withSnapshotList("Mixtures"),
)(Dispatch);
