import React, {useState} from 'react';
import {compose} from "recompose";
import {withSnapshotList} from "../../database/firebase_hoc";
import {connect} from "react-redux";
import Appbar from "../app_bar";
import {ColumnBar, ColumnBody, ColumnList, ColumnPaper, ColumnTitle} from "./column";
import MaterialsList from "./material_list";
import MixtureList from "./mixture_list";
import MixDesigner from "./mix_designer";
import MaterialDesigner from "./material_editor";
import '../../styles/materials.css';
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Tooltip} from "@material-ui/core";

const MixDesign = () => {
  const [ showDeprecated, setShowDeprecated ] = useState(false);
  return (
    <main>
      <ColumnPaper>
        <ColumnBar>
          <ColumnTitle>Mixtures</ColumnTitle>
          <ColumnTitle>Cements</ColumnTitle>
          <ColumnTitle>Stones</ColumnTitle>
          <ColumnTitle>Sands</ColumnTitle>
          <ColumnTitle>Admixtures</ColumnTitle>
        </ColumnBar>
        <ColumnBody>
          <ColumnList><MixtureList showDeprecated={showDeprecated}/></ColumnList>
          <ColumnList><MaterialsList showDeprecated={showDeprecated} variant={"Cement"}/></ColumnList>
          <ColumnList><MaterialsList showDeprecated={showDeprecated} variant={"Stone"}/></ColumnList>
          <ColumnList><MaterialsList showDeprecated={showDeprecated} variant={"Sand"}/></ColumnList>
          <ColumnList><MaterialsList showDeprecated={showDeprecated} variant={"Admix"}/></ColumnList>
        </ColumnBody>
      </ColumnPaper>
      <Appbar title="Mix Design Editor">
        <Tooltip title={showDeprecated ? "Hide Archived" : "Show Archived" }>
          <IconButton
            href=''
            onClick={() => setShowDeprecated(!showDeprecated)}
          >
            { showDeprecated || <Visibility/> }
            { showDeprecated && <VisibilityOff/> }
          </IconButton>
        </Tooltip>
      </Appbar>
      <MixDesigner/>
      <MaterialDesigner/>
    </main>
  );
};



export default compose(
  withSnapshotList("Mixtures"),
  withSnapshotList("Materials"),
  connect((state) => ({ mixtures: state.mixtures, materials: state.materials }))
)(MixDesign);
