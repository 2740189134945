import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {withRouter} from 'react-router-dom';
import logo from "../images/batch_pro.png";
import background from "../images/login_background.jpg";
import compose from 'recompose/compose';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Routes from '../routes';

import '../styles/login.css';
import {connect} from "react-redux";
import {auth} from "../database/Firebase";

class Login extends Component {

  state = {
    ordering: false,
    'email-error': ' ',
    'password-error': ' ',
    processing: false
  };


  login = async e => {
    this.setState({ processing: true });
    e.preventDefault();
    auth().signInWithEmailAndPassword(this.state.email, this.state.password)
    .then(() => {}).catch((e) => {
      this.setState({
        processing: false,
        'email-error': e.code === 'auth/user-not-found' ? 'Account with email doesn\'t exist' : ' ',
        'password-error': e.code === 'auth/wrong-password' ? 'Password is incorrect' : ' ',
      });
    });
  };

  handle = (e) => {
    let error = ' ';
    if (e.target.name === 'email') {
      if (!(
        e.target.value.split('@').length > 1
        && e.target.value.split('.').length > 1
      )) error = 'Enter a valid email address';
    } else if (e.target.value.length < 6)
      error = 'Enter a valid password';

    this.setState({
      [e.target.name] : e.target.value,
      [`${e.target.name}-error`] : error
    });
  };


  render() {
    const { user, history } = this.props;
    
    if (user) history.push(Routes.DISPATCH);

    return (
      <div style={{
        color: "#ffffff",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: 'fixed',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="center">
          <div className="login-body">
            <div className="login-info">
              <div className="login-logo">
                <img
                  alt=""
                  src={logo}
                  width='200px'
                 />
              </div>
              <div className="login-description">

              </div>
            </div>
            <div className="login-divider"/>
            <form onSubmit={this.login} className="login-form">
              <div className="login-form-spacer"/>
              <div className="login-field-container">
                <TextField
                  name="email"
                  fullWidth
                  onChange={this.handle}
                  label="Email Address"
                  margin="dense"
                  variant="outlined"
                  type="email"
                  autoComplete="current-email"
                  helperText={this.state["email-error"]}
                  error={this.state["email-error"] !== ' '}
                />
              </div>
              <div className="login-field-container">
                <TextField
                  name="password"
                  fullWidth
                  onChange={this.handle}
                  label="Password"
                  margin="dense"
                  variant="outlined"
                  type="password"
                  autoComplete="current-password"
                  helperText={this.state["password-error"]}
                  error={this.state["password-error"] !== ' '}
                />
              </div>
              <div className="login-button-container">
                {this.state.processing && <div className='login-progress-container'>
                  <CircularProgress className='login-progress' color="secondary" />
                </div>}
                <Button type="submit" href=''>Sign In</Button>
              </div>
            </form>
            <div className='login-footer'>
              <Typography>
                Don't have an account? <br/> Contact your administrator.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({ user: state.user })
  ),
  withRouter,
)(Login)
