import React, {createContext, useContext, useState} from 'react';
import {JobEditor} from "../popups/JobEditor";
import {OrdersDialog} from "../popups/Orders";
import {TruckInfoPopUp} from "../popups/TruckInfoPopUp";
import {Ticket,useTicketDialog} from "../views/archive_page/ticket";

const DialogContext = createContext({});

export const useDialogs = () => useContext(DialogContext);


export const Dialogs = (props) => {
  const [state, setState] = useState({});
  return (
    <DialogContext.Provider value={[state, setState]}>
      <JobEditor/>
      <OrdersDialog/>
      <TruckInfoPopUp/>
      <Ticket in={state.ticket} onClose={()=>setState((x)=>({...x, ticket:null}))}/>
      {props.children}
    </DialogContext.Provider>
  )
}