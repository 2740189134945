export const handleDelete = (firebase, company, key) => () => {
  window.firebase.database()
    .ref(`${company.val().url}/Orders/${key}`)
    .remove()
    .finally();
};

export const handleAssign = (firebase, company, key, value) => () => {
  const base = firebase.database().ref(`${company.val().url}`);


  const handle = async (snapshot) => {

    let entry;

    if (snapshot.val()) entry = Object.entries(snapshot.val())[0];

    const customer = entry ? entry[0] : base.child("Customers").push().key;

    if (!entry) await base.child(`Customers/${customer}`).update(value.Customer);

    const job = {
      ...value,
      Amount: value.Product.Amount,
      Customer: customer,
      Requested: value.Product.Name,
      Mixture: value.Product.Reference || '',
    };

    base.child("Jobs")
      .push()
      .update(job)
      .finally();

    handleDelete(firebase, company, key)();
  };

  base.child("Customers")
    .orderByChild("Email")
    .equalTo(value.Customer.Email)
    .limitToFirst(1)
    .once('value')
    .then(handle);
};
