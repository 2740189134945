import Paper from "@material-ui/core/Paper";
import useStyles from './styles';
import React from "react";
import Typography from "@material-ui/core/Typography";

export const ColumnPaper = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.columnPaper}>
      <div className={classes.columnLine}/>
      <div className={classes.columnLine}/>
      <div className={classes.columnLine}/>
      <div className={classes.columnLine}/>
      {props.children}
    </Paper>
  )
};

export const ColumnBar = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.columnHeader}>{props.children}</Paper>
  )
};

export const ColumnTitle = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.columnTitle}>
      <Typography variant='h6' align='center'><strong>{props.children}</strong></Typography>
    </div>
  )
};

export const ColumnBody = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.columnBody}>{props.children}</div>
  )
};

export const ColumnList = (props) => {
  const classes = useStyles();
  return (
    <div id='scrollable' className={classes.column}>{props.children}</div>
  )
};
