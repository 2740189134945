import React, {createContext, useContext, useEffect,useState} from 'react'
import { ToolbarStateProvider, ScheduleToolbar } from './schedule_toolbar';
import {DateFilterProvider, DateFilterSelector, useDateFilter,} from "../../providers/date_filter";
import moment from "moment";
import {useCompanyUrl, useJobsList, useTrucksList} from "../../providers/Firebase";
import {EditableCardView} from "../../components/EditableCardView";
import {SelectableArea} from "../../components/SelectableArea";
import {EditableCards} from "../../components/EditableCards";
import {TruckLabelBar} from "./TruckLabelBar";
import {Job} from "./job";
import CustomerSelect from "../dispatch/customer_select";
import TruckSelect from "../dispatch/truck_select";
import MixSelect from "../dispatch/mix_select";
import AddressSelect from "../dispatch/address_select";
import {TimeList} from "./time";
import {Columns} from "./columns";
import {useJobDialog} from "../../popups/JobEditor";
import {db, runTransactionDeleteJob} from "../../database/Firebase";
import cloneDeep from 'lodash/cloneDeep';

const ScheduleContext = createContext();

export const useSchedule = () => useContext(ScheduleContext);


//Add a parrent and child to to jobs in the data base 


const Schedule = () => {

  const company = useCompanyUrl();
  const trucks = useTrucksList();
  const [ { day } ] = useDateFilter();
  const editJobs = useJobDialog();
  const [state,setState] = useState();

  const mapToCard = ({ Start, Duration, Truck, }) =>{
    let index = Object.keys(trucks).findIndex((x) => x === Truck) + 1;
    return {
      x: (index * 250) + 5,
      height: Duration * 100,
      y: Math.abs(moment(Start).startOf('day').diff(moment(Start), 'minutes') * 100 / 60)
    }
  };

  const isSameDay = ([ , value ]) => moment(day).isSame(moment(value.Start), 'day');
  const jobsToCards = ([ key, value ]) => [ key, mapToCard(value) ];

  const jobs = useJobsList();
  const cards = Object.fromEntries(Object.entries(jobs).filter(isSameDay).map(jobsToCards));

  const path = `${company}/Jobs/`;

  const mapToJob = ({ x, y, height }) => {
    const scale = (value) => Math.round((value / 100) * 4) / 4;
    return {
      Duration: scale(height),
      Start: moment(day)
        .startOf('day')
        .add(scale(y) * 60, 'minutes')
        .toISOString(),
      Truck: Object.keys(trucks)[Math.floor(x / 250) - 1] ?? null,
    };
  };

  const change = async ([ key, value ]) => {
    var jobUpdatedInfo = mapToJob(value)

    db().ref(path + key).update(
      jobUpdatedInfo
    ).catch(() => {});

    var dataBaseJobInfo = (await db().ref(path + key).get()).val();
    (dataBaseJobInfo["children"] &&updateChildrenAfterDrag(jobUpdatedInfo,dataBaseJobInfo["children"]));
    
  };

  const updateChildrenAfterDrag = (updatedInfo, childrenList) =>{
    Object.keys(childrenList).forEach((key) =>{
      updatedInfo.Truck = childrenList[key];
      db().ref(path + key).update(updatedInfo);
    });
  }



  const create = (value) => {
    editJobs.show([ undefined, mapToJob(value) ]).catch(console.error);

    // db().ref(path
    // db().ref(path).push().update(
    //   mapToJob(value)f
    // ).catch(() => {});
  };

  const remove = (key) => {
    runTransactionDeleteJob(key).finally();
  };

  const DuplicateJob = (orginalJobKey) =>{
    let newJob = cloneDeep(jobs[orginalJobKey]);
    newJob["Truck"] =undefined;
    newJob["Status"] = undefined;
    newJob["children"] =undefined;
    editJobs.show([ undefined, newJob])
  }

  const AddTruckToJob = (orginalJobKey) =>{
    let newJob = cloneDeep(jobs[orginalJobKey]);
    newJob["Truck"] =undefined;
    newJob["Status"] = undefined;
    newJob["children"] =undefined;
    editJobs.show([ undefined, newJob ],[true, orginalJobKey])
  }



  return (
    <ScheduleContext.Provider value={{ trucks, cards, setState}}>
      <TruckLabelBar />
      <TimeList/>
      <Columns/>
      <EditableCardView
        value={cards}
        onChange={change}
        width={(Object.keys(trucks).length + 1) * 250}
        height={2400}
        top={74}
        left={185}
      >
        <SelectableArea onCreate={create}>
          <EditableCards
            onEdit={(job) => editJobs.show([ job, jobs[job] ])}
            onDuplicate={(job)=>DuplicateJob(job)}
            onAddTruck={(job)=>AddTruckToJob(job)}
            onDelete={remove}
            body={Job}
          />
        </SelectableArea>
      </EditableCardView>
    </ScheduleContext.Provider>
  );
};

export const DaySchedule = () => {
  useEffect(() => window.scrollTo(0,  moment().diff(moment().startOf('day'), 'seconds') / 50));
  return (
    <ToolbarStateProvider>
      <DateFilterProvider>
        <ScheduleToolbar/>
        <CustomerSelect/>
        <TruckSelect/>
        <MixSelect/>
        <AddressSelect/>
        <Schedule/>
        <DateFilterSelector/>
      </DateFilterProvider>
    </ToolbarStateProvider>
  )
};