import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {useCompanyUrl, useFirebase} from "../../providers/Firebase";
import {makeStyles} from "@material-ui/styles";
import {useJobDialog} from "../../popups/JobEditor";
import {db} from "../../database/Firebase";
import {useDrop} from "react-dnd";
import { deepOrange, lightGreen, yellow, lightBlue, purple } from "@material-ui/core/colors";



const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(0.5),
      overflow: 'hidden',
    },
    status: {
      position: 'absolute',
      bottom: theme.spacing(0.5),
      right: theme.spacing(1),
    }
  }
});

export const Job = ({ id }) => {

  const [ state, setState ] = useState({});
  const firebase = useFirebase();
  const company = useCompanyUrl();
  const classes = useStyles();
  const dialog = useJobDialog();

  const [{ isDragOver }, drop] = useDrop({
    accept: 'order',
    collect: (monitor) => ({
      isDragOver: monitor.isOver()
    }),
    drop: (item) => dialog.show([ id, job, item.order ]),
  });

  useEffect(() => {
    const ref = db().ref(`${company}/Jobs/${id}`);
    ref.on('value', (snap) => {
      const job = snap.val();
      if (job === null) return;
      db().ref(`${company}/Customers/${job.Customer}`).once('value').then((snap) => {
        const customer = snap.val();
        db().ref(`${company}/Mixtures/${job.Mixture}/Name`).once('value').then((snap) => {
          const mixture = snap.val();
          setState({
            job,
            customer,
            mixture,
          })
        })
      });
    });

    return () => ref.off()
  }, [ firebase, company, id ]);

  const { customer, mixture, job } = state;
  const [ focused, setFocused ] = useState();

  const hasCustomer = customer?.Name || customer?.Company;
  if (!job) return (<></>);
  return (
    <div
      className={classes.root}
      onDoubleClick={() => {
              dialog.show([ id, job ])
        }
      }
      ref={drop}
      style={{
        height: '100%',
        opacity: 0.65,
        transition: 'background-color .1s ease-in-out',
        background: isDragOver ? 'lightgray' : [
          yellow["100"], // New
          yellow["400"], // Accepted
          purple["A100"], // OnRoute
          lightBlue["800"], // In Progress
          deepOrange['A200'], // Delayed
          lightGreen["A700"], // Completed
        ][job.Status || 0]
      }}
    >
      { hasCustomer && <Typography variant="caption">

        { (!(customer.Company == undefined)&& customer) ? (customer.Company || customer) + " - " : "" }
        { customer?.Name ? (customer?.Name?.First ?? customer?.Name) + " " : "No Customer Provided" }
        { customer?.Name && typeof(customer?.Name) !== 'string' && customer?.Name?.Last }
      </Typography> }
      { hasCustomer && !!customer?.Phone && <Typography variant="caption"><br/>Phone: {customer.Phone}</Typography> }
      { !hasCustomer && <Typography variant="caption">No Customer Provided</Typography> } <br/>
      <Typography variant="caption">
        {(mixture || "No Mixture Specified")}<br/>
      </Typography>
      <Typography className={classes.status} align='right' variant="caption">{
        [
          "NEW", // New
          "ACCEPTED", // Accepted
          "DRIVER ON ROUTE", // OnRoute
          "POUR IN PROGRESS", // In Progress
          "DRIVER WAITING TO POUR", // Delayed
          "COMPLETED", // Complete
        ][job.Status || 0]
      }
      </Typography>
    </div>
  );
};

