const snapshotListReducer = (type) => (state = { }, action) => {
  switch (action.type) {
    case `${type}_ADD`: return { ...state, [action.payload.key]: action.payload.val() };
    case `${type}_CHANGE`: return { ...state, [action.payload.key]: action.payload.val() };
    case `${type}_REMOVE`:
      const newState = { ...state };
      delete newState[action.payload.key];
      return newState;
    default: return state;
  }
};

export default snapshotListReducer;
