import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';

import './styles/app.css'
import './database/FirebaseConfig';

const root = document.getElementById('root');

ReactDOM.render(<App/>, root);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
