const authReducer = (state = 'CHECKING', action) => {
  switch (action.type) {
    case "UPDATE_USER": return action.payload ? 'AUTHENTICATED' : 'NO_USER_SPECIFIED';
    case 'SIGN_IN': return 'AUTHENTICATED';
    case 'SIGN_OUT': return 'NO_USER_SPECIFIED';
    default: return state;
  }
};

export default authReducer;
