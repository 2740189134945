import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    root: {

    },
    sign_out: {
      color: 'red',
    },
    save: {
      color: 'dodgerblue'
    },
    gap: {
      flexGrow: 1,
    }
  };
});


export const ChangePasswordDialog = ({ user, open, onComplete }) => {

  const classes = useStyles();

  const [ fetching, setFetching ] = useState(false);
  const [ newPassword, setNewPassword ] = useState({});
  const [ confirmPassword, setConfirmPassword ] = useState({});

  const tryPasswordUpdate = async () => {

    try {
      setFetching(true);

      if (confirmPassword?.value !== newPassword?.value) return setConfirmPassword({
        ...confirmPassword,
        error: "Password's do not match"
      });

      const adminChangeAccountPassword = window.firebase.functions().httpsCallable('adminChangeAccountPassword');

      const handleResponse = (e) => {

        if (e instanceof Error) {
          return setConfirmPassword({
            ...confirmPassword,
            error: "An unexpected error occurred"
          })
        }

        if (e.data.status !== 'ok') return setConfirmPassword({
          ...confirmPassword,
          error: e.data.message
        });

        onComplete();
      }

      await adminChangeAccountPassword({ user, password: newPassword.value }).then(handleResponse).catch(handleResponse)

    } finally {
      setFetching(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Change Password
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type='password'
              label="New Password"
              value={newPassword?.value ?? ''}
              error={!!newPassword?.error}
              helperText={newPassword?.error}
              fullWidth
              onChange={(e) => setNewPassword({ value: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='password'
              label="Verify Password"
              value={confirmPassword?.value ?? ''}
              error={!!confirmPassword?.error}
              helperText={confirmPassword?.error}
              fullWidth
              onChange={(e) => setConfirmPassword({ value: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { fetching && <CircularProgress color='secondary' size={20} thickness={6} /> }
        <Button disabled={fetching} onClick={tryPasswordUpdate} className={classes.save}>Update</Button>
        <Button onClick={onComplete} >Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}