import app from 'firebase/app';

import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/performance";

const config = {
  apiKey: "AIzaSyDsNK1NdOoA1XiIbrfxuPfR8CH14m3LS_g",
  authDomain: "fd-remote.firebaseapp.com",
  databaseURL: "https://fd-remote.firebaseio.com",
  projectId: "fd-remote",
  storageBucket: "fd-remote.appspot.com",
  messagingSenderId: "1074045383056"
};

window.firebase = app.initializeApp(config);
