import {FunctionComponent} from "react";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useList, useObject} from "react-firebase-hooks/database";
import {company_db as db, db as root} from "../../database/Firebase";
import moment from "moment";
import {useArchivePageContext} from "./index";



const ArchivePageRowItem = (props) => {

  const ticket = props.snapshot.val();




  const started = new Date(
    ticket.ARCHIVE_START_YEAR,
    ticket.ARCHIVE_START_MONTH - 1, //FOR SOME FUCKING REASON JS MONTHS START AT 0 WTF
    ticket.ARCHIVE_START_DAY,
    ticket.ARCHIVE_START_HOUR,
    ticket.ARCHIVE_START_MIN,
  );

  const completed = new Date(
    ticket.ARCHIVE_STOP_YEAR,
    ticket.ARCHIVE_STOP_MONTH - 1, //FOR SOME FUCKING REASON JS MONTHS START AT 0 WTF
    ticket.ARCHIVE_STOP_DAY,
    ticket.ARCHIVE_STOP_HOUR,
    ticket.ARCHIVE_STOP_MIN,
  );


  const page = useArchivePageContext();
  return (
    <TableRow key={props.snapshot.key} onClick={() => page.setFocused(props.snapshot)}>
      <TableCell>{ticket.TRUCK_NAME}</TableCell>
      <TableCell>{ticket.ARCHIVE_TICKET_NUMBER}</TableCell>
      <TableCell>{ticket.OPERATOR_NAME}</TableCell>
      <TableCell>{ticket.ARCHIVE_MIX_NAME}</TableCell>
      <TableCell>{ticket.ARCHIVE_VOLUME / 100} {ticket.ARCHIVE_UNITS ? "yrd³" : "m³"}</TableCell>
      <TableCell>{moment(started).format("LLL")}</TableCell>
      <TableCell>{moment(completed).format("LLL")}</TableCell>
    </TableRow>
  )
}

export const ArchiveTableBodySection = ({ start, count, onLoadMore }) => {

  const query = db('Tickets').orderByChild("SORT_DATE_DESCENDING");

  const [ data, loading ] = useList(start ? query.startAt(start) : query);

  if (loading) return (
    <>
      <TableRow>
        <TableCell><Button disabled>Fetching ... </Button></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  )

  return (
    <>
      {data.map((snap, i) => {
        if (!!start && i === 0) return <></>
        return (
          <ArchivePageRowItem key={i} snapshot={snap} />
        )
      })}
      { onLoadMore && data.length === count && <div className='ml-4'>
        <Button onClick={() => onLoadMore(data[data.length - 1].key)}>Load More</Button>
      </div> }
    </>
  )
}

export const ArchiveTable = ({ children }): FunctionComponent => {
  return (
    <TableContainer>
      <Table size='small' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Truck</TableCell>
            <TableCell>Ticket #</TableCell>
            <TableCell>Uploaded By</TableCell>
            <TableCell>Mix Name</TableCell>
            <TableCell>Dispensed</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Completion Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}