import React from "react";
import {compose} from 'recompose';
import {connect} from "react-redux";
import {clearOrder, updateOrder} from "../../actions/order";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from './styles';
import TextField from "@material-ui/core/TextField";
import AddressAutoComplete from "../address_auto_complete";

const Customer = (props) => {
  const classes = useStyles();
  const handle = (e) => props.update({ Customer: {
      ...props.order,
      [e.target.name]: e.target.value
  }});
  const handleAddress = (e) => props.update({ Customer: {
      ...props.order,
      Address: e
    }});


  const handleName = (e) => props.update({ Customer: {
      ...props.order,
      Name: {
        ...props.order.Name,
        [e.target.name]: e.target.value
      }
  }});


  const submit = (e) => { e.preventDefault(); props.onSubmit(); };
  return (
    <div>
      <Paper className={classes.paper}>
        <form onSubmit={submit} className={classes.form}>
          <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={12}>
              <TextField
                name="Company"
                margin="none"
                label="Company Name"
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="First"
                margin="none"
                label="Contact First Name"
                onChange={handleName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="Last"
                margin="none"
                label="Contact Last Name"
                onChange={handleName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Email"
                margin="none"
                label="Contact Email"
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Phone"
                margin="none"
                label="Contact Phone Number"
                onChange={handle}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <AddressAutoComplete
                onChange={handleAddress}
                value={(props.order ||{}).Address || ''}
                TextFieldProps={{
                  label: 'Billing Address',
                  fullWidth: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between" className={classes.buttons}>
            <Grid item sm={1}>
            </Grid>
            <Grid item sm={1}>
              <Button
                href=''
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
};

export default compose(
  connect(
    (state) => ({ order: state.order.Customer, firebase: state.firebase }),
    (dispatch) => ({
      update: (change) => dispatch(updateOrder(change)),
      clear: () => dispatch(clearOrder()),
    })
  )
)(Customer);
