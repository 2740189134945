import React, {useMemo, useState} from 'react';
import Appbar from '../app_bar';
import {useDispatch} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import '../../styles/archive.css';
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import FilterList from "@material-ui/icons/FilterList";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import CustomerEditor from "./customer_editor";
import Fab from "@material-ui/core/Fab";
import { useList } from "react-firebase-hooks/database";
import { company_db as db } from "../../database/Firebase";
import {FunctionComponent} from "react";

const Header = (props): FunctionComponent => (
  <Typography className='expanded-header'>
    {props.children}
  </Typography>
);

export const CustomerListItem = (props): FunctionComponent => {
  const { customer } = props;
  return [
    <span>{customer.Company}</span>,
    <span>{customer["Name"]["First"]} {customer["Name"]["Last"]}</span>,
    <span>{customer["Phone"]}</span>,
  ].map((x, i) => <Header key={i}>{x}</Header>);
};

const CustomerList = (): FunctionComponent => {
  const dispatch = useDispatch();
  const edit = (payload) => dispatch({ type: "CUSTOMER_POPUP_SET", payload });

  const [ customers ] = useList(db('Customers'));

  return customers.map(
    (doc, i) => {

      if (doc.val().Deprecated) return (<></>);

      return (
        <ExpansionPanel key={`customer-card-${i}`} expanded={false} onChange={() => edit([ doc.key, doc.val() ])}>
          <ExpansionPanelSummary href=''>
            <CustomerListItem customer={doc.val()} />
          </ExpansionPanelSummary>
        </ExpansionPanel>
      );
    }
  )
};

const CustomerPage = (): FunctionComponent => {
  const dispatch = useDispatch();
  const [ filter ] = useState(null);
  const create = () => dispatch({ type: "CUSTOMER_POPUP_SET", payload: [ ] });
  return (
    <main>
      <List component='div' style={{ padding: "5em 1em 1em 5.5em" }}>
        <CustomerList filter={filter}/>
      </List>
      <Appbar title="Customers"/>
      <IconButton href=''>
        <FilterList/>
      </IconButton>
      <CustomerEditor/>
      <Fab
        color="secondary"
        aria-label="Add"
        style={{
          position: 'fixed',
          bottom: "2em",
          right: "2em"
        }}
        onClick={create}
        href=''
      >
        <Add/>
      </Fab>
    </main>
  )
};

export default CustomerPage;
