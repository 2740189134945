import Paper from "@material-ui/core/Paper";
import React from 'react';
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import moment from "moment";

const useStyles = makeStyles((theme) =>{
  return {
    root: {
      width: theme.spacing(92),
      padding: theme.spacing(2),
    },
  }
});

export const OrderPreview = ({ order, style }) => {
  const [ key, value ] = order;
  const classes = useStyles();
  return (
    <div
      key={key}
      style={style}
    >
      <Paper
        className={classes.root}
      >
        <Typography variant='h6'>
          {value?.Customer?.Name ?? 'No Name Provided'}
        </Typography>
        <Typography>
          <strong>Requested For: </strong>
          { value.Start ? moment(value?.Start).format("LLL") : 'Time was not provided'} <br/>
          <strong>Delivery Location: </strong>{value?.Address?.LongName ?? 'Location was not provided'}
        </Typography>
      </Paper>
    </div>
  )
};