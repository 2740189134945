import makeStyles from "@material-ui/core/styles/makeStyles";
import watermark from "../../images/watermark.jpeg";

const useStyles = makeStyles(() => ({
  margin: {
    margin: '10pt',
  },
  withoutLabel: {
    marginTop: '30pt',
  },
  textField: {
    flexBasis: 200,
  },
  background:{
    color: "#ffffff",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    position: 'fixed',
    backgroundImage: `url(${watermark})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

  },
  logo: {
    borderColor: '#fff',
    color: '#fff',
    margin: "10pt 10pt 10pt 10pt",
    display: 'block',
    borderWidth: "0.3vw",
    padding: "1vw 0 1vw 0",
    width: "16vw",
    fontSize: "1.3vw",
    fontFamily: "Roboto",
    textAlign: 'center',
    borderStyle: "dashed",
  },
  title: {
    textAlign: 'center',
    color: "#fff",
  },
  content: {
    paddingTop: '20pt',
    height: '100%'
  },
  paper: {
    borderRadius: "15pt",
    backgroundColor: "#ffffffbb",
    margin: 'auto',
    maxWidth: "600pt",
  },
  order: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '150pt',
    height: '40pt',
    margin: 'auto',
  },
  grid: {
    width: '100% !important',
    padding: '1em',
    paddingBottom: 0,
    paddingRight: 0,
    margin: "auto",
  },
  buttons: {
    left: 0,
    right: 0,
    width: "80%",
    margin: "auto",
    padding: '10pt'
  },
  form : {
  },
  formControl: {
    width: '100%',
    minHeight: '40pt'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '4pt',
  },
}));

export default useStyles;
