import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {compose, defaultProps} from 'recompose';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  "title-default": {
    marginLeft: '4em',
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    }
  },
  "title-sidebar": {
    marginLeft: '17em',
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    }
  }
}));


const Appbar = (props) => {
  const classes = useStyles();
  return (
    <AppBar>
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes["title-" + props.variant]}>
          {props.title}
        </Typography>
        {props.children}
      </Toolbar>
    </AppBar>
  );
};


export default compose(
  defaultProps({ variant: 'default' }),
)(Appbar)
